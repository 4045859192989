<template>
  <div>
    <v-app class="transparent">
      <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        :color="snackbarSuccess ? 'success' : 'error'"
      >
        <span>{{ snackbarText }}</span>
        <v-btn text color="white" @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-container fluid fill-height class="transparent mb-4">
        <v-row align="center" justify="center">
          <v-flex xs12 sm9 md6 lg4>
            <v-card class="elevation-5">
              <v-toolbar class="elevation-0" dark color="primary">
                <v-toolbar-title v-if="login">Sign in</v-toolbar-title>
                <v-toolbar-title v-if="!login">Sign up</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form
                  @submit.prevent="login ? signin() : signup()"
                  id="login-form"
                  lazy-validation
                  ref="form"
                >
                  <v-row v-if="!login">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        prepend-icon="mdi-account"
                        name="firstName"
                        v-model="firstName"
                        label="First Name"
                        type="text"
                        clearable
                        :rules="[nameRules.required, nameRules.min]"
                        v-if="!login"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        name="lastName"
                        v-model="lastName"
                        label="Last Name"
                        type="text"
                        clearable
                        :rules="[nameRules.required, nameRules.min]"
                        v-if="!login"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        prefix="+964 (0)"
                        name="phoneNumber"
                        v-model="phoneNumber"
                        label="Mobile number"
                        clearable
                        type="text"
                        hint="Mobile number must be 10 digits 712 345 67 89"
                        persistent-hint
                        :rules="[rules.required, rules.min, rules.number]"
                        required
                        max="10"
                        counter
                        @click:clear="clearPhone"
                        v-if="!login"
                      ></v-text-field>
                    </v-col>
                    <!--
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-menu
                        ref="birthdatePicker"
                        v-model="birthdatePicker"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        v-if="!login"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormatted"
                            label="Birthday date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="birthdate"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1920-01-01"
                          @change="birthdateSave"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    -->
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        name="addess"
                        v-model="address"
                        label="Address"
                        type="text"
                        clearable
                        prepend-icon="mdi-map-marker"
                        v-if="!login"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    v-model="email"
                    autocomplete
                    clearable
                    label="Email"
                    type="text"
                    :rules="emailRules"
                  ></v-text-field>

                  <v-text-field
                    prepend-icon="mdi-lock"
                    name="password"
                    autocomplete
                    v-model="password"
                    clearable
                    label="Password"
                    id="password"
                    counter
                    hint="At least 6 characters"
                    :type="passwordShow ? 'text' : 'password'"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    @click:append="passwordShow = !passwordShow"
                  ></v-text-field>
                </v-form>

                <v-btn
                  outlined
                  rounded
                  class="text-xs-center caption primary--text"
                  @click="login = true"
                  v-if="!login"
                  ><v-icon left dark> mdi-login </v-icon>
                  Already have an account? Sign in
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  class="text-xs-center caption primary--text"
                  @click="redirectUser"
                  v-if="login"
                >
                  <v-icon left dark> mdi-clipboard-account </v-icon>
                  Don't have an account? Sign up
                </v-btn>
              </v-card-text>
              <v-card-actions>
                <ResetPassword
                  @resetPasswordPopup="snackbarTextResetPassword($event)"
                />
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="primary"
                  v-if="login"
                  type="submit"
                  form="login-form"
                  :loading="loading"
                  >Login <v-icon right dark> mdi-login </v-icon></v-btn
                >
                <v-btn
                  small
                  color="primary"
                  v-if="!login"
                  type="submit"
                  form="login-form"
                  :loading="loading"
                  >Sign up
                  <v-icon right dark> mdi-clipboard-account </v-icon>
                </v-btn>
              </v-card-actions>
              <!--
                <v-row class="justify-center">
                <div class="text-center">
                  <section
                    class="py-5 px-4"
                    id="firebaseui-auth-container"
                  ></section>
                </div>
              </v-row>
              -->
            </v-card>
          </v-flex>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import ResetPassword from "@/components/ResetPassword";
import moment from "moment";
import { auth, userRef, setUserProperties } from "@/firebase";
// import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  components: {
    ResetPassword,
  },
  name: "userauth",
  data() {
    return {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      codeReceived: "",
      birthdate: "",
      address: "",
      email: "",
      password: "",
      verified: false,
      passwordShow: false,
      loading: false,
      login: true,
      birthdatePicker: false,
      information: {},
      rules: {
        number: (value) => Number.isInteger(Number(value)) || "Must be number",
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length == 10) || "Mobile number is 10 digits",
        emailMatch: () => `The email and password you entered don't match`,
      },
      nameRules: {
        required: (value) => !!value || "Required.",
        min: (v) =>
          (v && v.length >= 3) ||
          "I think your name or lastname is too short, it's not like a real name",
      },
      emailRules: [
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid email.";
        },
      ],
      passwordRules: [
        (value) => !!value || "Please enter a password.",
        (value) =>
          (value && value.length >= 6) ||
          "Password should be at least 6 characters",
      ],
      emailAddressReset: "",
      snackbar: false,
      snackbarSuccess: false,
      snackbarText: "",
    };
  },

  methods: {
  redirectUser() {
    window.location.href = 'https://app.zubene.co/';
    // Additional logic can be added here later if needed
    },
    snackbarTextResetPassword(message) {
      this.snackbarText = message.snackbarText;
      this.snackbarSuccess = message.snackbarSuccess;
      this.snackbar = true;
    },
    clearPhone() {
      this.phoneNumber = "";
    },
    async signup() {
      if (this.$refs.form.validate()) {
        if (this.firstName.length >= 3 && this.lastName.length >= 3) {
          const date = new Date(Date.now());
          this.information = {
            phoneNumber: this.phoneNumber,
            address: this.address,
            birthdate: this.birthdate,
            displayName: this.firstName + " " + this.lastName,
            registerDate: date,
          };
          this.loading = true;
          const userAuth = auth;
          userAuth
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(async (user) => {
              user = auth.currentUser;
              const userRefCollection = userRef;
              await userRefCollection
                .doc(user.uid)
                .set(this.information, { merge: true });
              this.snackbarText =
                "Congratulation! Your account has been activated successfully. You can now login.";
              this.snackbar = true;
              this.snackbarSuccess = true;
              if (user) {
                const analyticsRef = setUserProperties;
                analyticsRef;
                user
                  .updateProfile({
                    displayName: this.firstName + " " + this.lastName,
                    phoneNumber: this.phoneNumber,
                  })
                  .then(() => {
                    user
                      .sendEmailVerification()
                      .then(function () {
                        // Email sent.
                        console.log("Success! Email Verification Sent");
                      })
                      .catch(function (error) {
                        // An error happened.
                        console.log(error.message);
                      });
                    this.loading = false;
                    const userAuth = auth;
                    userAuth.signOut();
                    this.$router.replace({ name: "dashboard" });
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }
            })
            .catch((error) => {
              // Handle Errors here.
              var errorMessage = error.message;
              this.loading = false;
              if (errorMessage.length > 0) {
                this.snackbarText = errorMessage;
                this.snackbar = true;
              }
            });
        } else {
          this.snackbarText = "Firstname or lastname too short";
          this.snackbar = true;
        }
      }
    },

    signin() {
      this.loading = true;
      const userAuth = auth;
      userAuth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.loading = false;
          this.$router.replace("/dashboard");
        })
        .catch(function (error) {
          return error.message;
        })
        .then((error) => {
          this.loading = false;
          this.snackbarText = error;
          this.snackbar = true;
        });
    },
    birthdateSave(date) {
      this.$refs.birthdatePicker.save(date);
    },
  },
  watch: {
    birthdatePicker(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    computedDateFormatted() {
      return this.birthdate ? moment(this.birthdate).format("LL") : "";
    },
  },
  mounted() {
    /* var uiConfig = {
      signInSuccessUrl: "/dashboard",
      signInFlow: "popup",
      signInOptions: [
        authUI.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        authUI.EmailAuthProvider.PROVIDER_ID,
        // authUI.GoogleAuthProvider.PROVIDER_ID,
        {
          provider: authUI.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            type: "image",
            size: "invisible",
            badge: "bottomleft",
          },
          defaultCountry: "+964",
          whitelistedCountries: ["IQ", "+964"],
        },
        // {
        //  provider: authUI.FacebookAuthProvider.PROVIDER_ID,
        //  scopes: ["public_profile", "email", "user_likes", "user_friends"],
        // },
      ],
    };
    if (firebaseui.auth.AuthUI.getInstance()) {
      const ui = firebaseui.auth.AuthUI.getInstance();
      ui.start("#firebaseui-auth-container", uiConfig);
    } else {
      const userAuth = auth;
      const ui = new firebaseui.auth.AuthUI(userAuth);
      ui.start("#firebaseui-auth-container", uiConfig);
    }*/
  },
};
</script>


<style>
@media only screen and (max-width: 768px) {
  .v-main {
    margin: 0;
  }
}
.transparent {
  background-color: rgba(255, 0, 0, 0);
}
</style>