import Vue from 'vue'
import VueRouter from 'vue-router'

import { auth } from '@/firebase'

import UserAuth from '@/views/UserAuth'

import Profile from '@/views/Profile.vue'

import Dashboard from '@/views/Dashboard.vue'
import ShippingCalculator from '@/views/ShippingCalculator.vue'


import ColleagueDashboard from '@/views/ColleagueDashboard.vue'

import AdminDashboard from '@/views/AdminDashboard.vue'
import AdminRoles from '@/views/AdminRoles.vue'

import UserOrders from '@/views/UserOrders.vue'
import OrderCheck from '@/views/OrderCheck.vue'
import PrintListOrders from '@/views/ThePrintList.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: { name: 'userauth' },
  },
  {
    path: '/',
    redirect: { name: 'userauth' },
  },
  {
    path: '/userauth',
    name: 'userauth',
    component: UserAuth,
    meta: {
      guest: true
    }
  },
  {
    path: '/dashboard/:query/:page',
    name: 'dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/print/:uid/:page',
    name: 'print',
    component: PrintListOrders,
    props: true,
    meta: {
      requiresAuth: true
    },
     // window.open(`/print/${orderId}`)
  },
  {
    path: '/print/:uid/:page',
    name: 'reportUserOrder',
    component: PrintListOrders,
    props: true,
    meta: {
      requiresAuth: true
    },
     // window.open(`/print/${orderId}`)
  },
  {
    path: '/print/:uid',
    name: 'printList',
    component: PrintListOrders,
    props: true,
    meta: {
      requiresAuth: true
    },
     // window.open(`/print/${orderId}`)
  },
  {
    path: '/colleaguedashboard/:query/:page',
    name: 'colleaguedashboard',
    component: ColleagueDashboard,
    props: true,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/admindashboard/:query/:page',
    name: 'admindashboard',
    component: AdminDashboard,
    props: true,
    meta: {
      requiresAuth: true
    }
  }, {
    path: '/userorders/:id/:query/:page',
    name: 'userorders',
    component: UserOrders,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ordercheck/:uid/:id',
    name: 'ordercheck',
    component: OrderCheck,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/adminroles',
    name: 'adminroles',
    component: AdminRoles,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/shippingcalculator',
    name: 'shippingcalculator',
    component: ShippingCalculator,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const userPaths = ['dashboard', 'history', 'profile', 'shippingcalculator'];
const colleaguePaths = ['colleaguedashboard', 'history', 'profile', 'shippingcalculator', 'userorders', 'ordercheck'];
const adminPaths = ['admindashboard', 'history', 'profile', 'adminroles', 'shippingcalculator', 'userorders', 'ordercheck', 'deletedorder','reportUserOrder','print'];

router.beforeEach((to, from, next) => {
  const userAuth = auth;
  userAuth.onAuthStateChanged(user => {
    if (user) {
      userAuth.currentUser.getIdTokenResult()
        .then(function ({
          claims
        }) {
          if (claims.customer) {
            if (!userPaths.includes(to.name))
              return next({
                name: userPaths[0]
              })
          } else if (claims.admin) {
            if (!adminPaths.includes(to.name))
              return next({
                name: adminPaths[0]
              })
          } else if (claims.usa || claims.turkey) {
            if (!colleaguePaths.includes(to.name))
              return next({
                name: colleaguePaths[0]
              })
          } else {
            return next({
              name: 'userauth',
            })
          }

        })
    } else {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        next({
          name: 'userauth',
          query: {
            redirect: to.fullPath
          }
        })
      } else {
        next()
      }
    }

  })

  next()

})

export default router
