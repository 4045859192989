<template>
  <div class="adminRoles">
    <v-banner
      icon="mdi-account-lock"
      single-line
      class="subtitle-1 grey--text"
      >User Roles and Details</v-banner
    >
    <v-container>
      <v-snackbar v-model="snackbar" :timeout="3000" :color="color">
        <span>{{ snackbarText }}</span>
        <v-btn text color="white" @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-row align="center" justify="center">
        <v-card max-width="600" class="elevation-5">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>User Roles</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              @submit.prevent="userProfileUpdate"
              id="user-edit-form"
              lazy-validation
              ref="form"
            >
              <v-row class="px-2">
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    prepend-icon="mdi-email"
                    :items="users"
                    :item-value="name"
                    return-object
                    name="user"
                    item-text="email"
                    v-model="userSelected"
                    label="Users"
                    hint="Select the user whose profile you want to edit"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="fullName"
                    v-model="userSelected.displayName"
                    label="Full Name"
                    type="text"
                    readonly
                    background-color="disabled"
                    rounded
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    prepend-icon="mdi-cellphone"
                    prefix="+964"
                    name="phoneNumber"
                    v-model="userSelected.phoneNumber"
                    label="Mobile number"
                    type="text"
                    readonly
                    background-color="disabled"
                    rounded
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    name="credit"
                    v-model="userSelected.credit"
                    label="Credit"
                    type="text"
                    :readonly="userSelected ? false : true"
                    :rules="[rules.number]"
                    prepend-icon="mdi-currency-usd"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :readonly="userSelected ? false : true"
                    prepend-icon="mdi-account-group"
                    :items="rolesItems"
                    v-model="roles"
                    label="Role Managment"
                    hint="You can choose the role of the user here"
                  ></v-select>
                </v-col>
                <!--
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      prepend-icon="mdi-account-convert"
                      :items="statusItems"
                      v-model="userSelected.disabled"
                      label="Status"
                      hint="If you want to disable the user, select the Disabled"
                    ></v-select>
                  </v-col>
                 <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-lock"
                      name="password"
                      v-model="password"
                      label="Password"
                      id="password"
                      counter
                      hint="At least 6 characters"
                      :type="passwordShow ? 'text' : 'password'"
                      :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="passwordRules"
                      @click:append="passwordShow = !passwordShow"
                    ></v-text-field>
                  </v-col>
                  -->
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-row align="center" justify="center">
              <v-col>
                <v-btn
                  color="primary"
                  type="submit"
                  form="user-edit-form"
                  :loading="loading"
                  >Save edit
                  <v-icon right dark> mdi-account-edit </v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import { auth, userRef, setUserRole } from "@/firebase";
export default {
  data() {
    return {
      name: "",
      users: [],
      user: "",
      userSelected: "",
      fullName: "",
      phoneNumber: "",
      birthdate: "",
      address: "",
      email: "",
      password: "",
      verified: false,
      passwordShow: false,
      loading: false,
      credit: 0,
      status: "",
      role: "",
      color: "",
      statusItems: ["Enable", "Disable"],
      rolesItems: ["admin", "usa", "turkey", "customer"],
      birthdatePicker: false,
      rules: {
        number: (value) => Number.isInteger(Number(value)) || "Must be number",
        min: (v) =>
          (v && !v) ||
          (v.length >= 10 && v.length <= 11) ||
          "Mobile number is between 10 - 11 digits",
      },
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    roles: {
      get: function () {
        if (this.userSelected) {
          if (this.userSelected.role.customer) {
            return "customer";
          } else if (this.userSelected.role.usa) {
            return "usa";
          } else if (this.userSelected.role.turkey) {
            return "turkey";
          }
        }
        return "";
      },
      set: function (role) {
        this.role = role;
      },
    },
  },
  methods: {
    async userProfileUpdate() {
      this.loading = true;
      const setUserRoleFunction = setUserRole;
      var profile = {
        uid: this.userSelected.id,
        role: {
            [this.role !="" ? this.role :this.roles ]: true,//[this.role]: true,
        },
        credit: this.userSelected.credit,
      };

      await setUserRoleFunction(profile)
        .then(() => {
          this.snackbarText = "The information was successfully updated";
          this.color = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          this.snackbarText = error;
          this.color = "error";
          this.snackbar = true;
        });
      this.loading = false;
    },
  },
  created() {
    var self = this;
    const userAuth = auth;
    userAuth.onAuthStateChanged(function (user) {
      self.user = user;
    });
    this.users = [];
    const userRefCollection = userRef;
    userRefCollection.get().then((snap) => {
      snap.forEach((doc) => {
        var user = doc.data();
        user.id = doc.id;
        if (user.role && !user.role.admin) {
          this.users.push(user);
        }
      });
    });
  },
};
</script>