<template>
  <div class="adminDashboard">
    <v-banner
      icon="mdi-account-group"
      single-line
      class="subtitle-1 text-capitalize grey--text"
      >Colleague Dashboard | {{ query }}</v-banner
    >

    <div class="text-center ma-2">
      <v-snackbar v-model="snackbarInfo" :timeout="4000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-container fluid class="px-0">
      <v-row dense
        ><v-col cols="6" xs="6" sm="6" md="6" lg="6">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon :large="$vuetify.breakpoint.smAndUp" left>
                mdi-cart
              </v-icon>
              <span
                class="text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5 text-h5 font-weight-normal"
                >All orders</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4 text-h6 font-weight-bold"
            >
              {{ numberOfAllOfTheOrders }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="6" xs="6" sm="6" md="6" lg="6">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon :large="$vuetify.breakpoint.smAndUp" left>
                mdi-tag-multiple
              </v-icon>
              <span
                class="text-xl-h5 text-lg-h5 text-md-h5 text-sm-h5 text-h5 font-weight-normal"
                >Total Price</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4 text-h6 font-weight-bold"
            >
              $ {{ allOfPrice }}
            </v-card-text>
          </v-card></v-col
        ></v-row
      ></v-container
    >

    <v-container fluid class="my-2 px-0">
      <!-- Search user by phone number or Name-->
      <v-row align="center" justify="center" no-gutters>
        <div max-width="800px">
          <v-row>
            <v-col cols="8">
              <v-autocomplete
                prepend-icon="mdi-account-search"
                :items="users"
                return-object
                solo
                rounded
                clearable
                @click:clear="filteredUsers = []"
                :loading="userDataLoading"
                :disabled="!userFiledSearchSelected"
                :item-text="userFiledSearchSelected"
                v-model="userSelected"
                @change="
                  userSelected
                    ? productUserSearch(userSelected, userFiledSearchSelected)
                    : ''
                "
                @click="!users.length ? userData() : null"
                label="Users"
                hint="Select the user whose profile you want to see"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :items="userFiledSearch"
                solo
                rounded
                select
                v-model="userFiledSearchSelected"
                label="Search by"
                hint="Select the field you want to search with"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <v-expansion-panels
          popout
          v-for="filteredUser in filteredUsers"
          :key="filteredUser.id"
        >
          <v-expansion-panel text hide-actions>
            <v-expansion-panel-header class="mx-0">
              <v-row justify="start" align="center" class="spacer" no-gutters>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                  <v-avatar size="50px">
                    <img
                      alt="ZUBENE"
                      :src="
                        filteredUser.photoURL
                          ? filteredUser.photoURL
                          : 'https://imgur.com/dLB4u3s.png'
                      "
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                  <v-card tile flat>
                    <router-link
                      class="routerLink"
                      :to="{
                        name: 'userorders',
                        params: {
                          id: filteredUser.id,
                          query: 'all',
                          page: '1',
                        },
                      }"
                    >
                      <v-btn
                        text
                        small
                        rounded
                        class="green px-2 py-0 white--text"
                      >
                        {{ filteredUser.displayName }}
                      </v-btn>
                    </router-link>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                  <v-card tile flat>
                    <a
                      class="routerLink"
                      :href="`mailto:0${filteredUser.email}`"
                      target="_blank"
                    >
                      <v-btn text small rounded class="green white--text">
                        {{ filteredUser.email }}
                      </v-btn>
                    </a>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-card tile flat>
                    <a
                      class="routerLink"
                      :href="`tel:0${filteredUser.phoneNumber}`"
                      target="_blank"
                    >
                      <v-btn text small rounded class="green white--text">
                        0{{ filteredUser.phoneNumber }}
                      </v-btn>
                    </a>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-divider></v-divider>
              <v-row align="center">
                <v-col
                  v-if="filteredUser.address"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                >
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Credit</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ filteredUser.credit }}
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  v-if="filteredUser.birthdate"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                >
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Birthdate</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ filteredUser.birthdate }}
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  v-if="filteredUser.address"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                >
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Address</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ filteredUser.address }}
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-divider class="mb-3"></v-divider>
      <!-- End Search user by phone number -->
      <v-row class="mb-2 px-4" justify="center" v-if="all">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                orders = ordersCopy;
                products = true;
              "
            >
              <v-icon color="primary">mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>Show all orders</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('processing');
                products = false;
              "
            >
              <v-icon color="process">mdi-autorenew</v-icon>
            </v-btn>
          </template>
          <span>Processing</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('bought');
                products = false;
              "
            >
              <v-icon color="buy">mdi-credit-card-check</v-icon>
            </v-btn>
          </template>
          <span>Bought</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OnTheWay');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>On The Way</span>
        </v-tooltip>
        <v-tooltip top v-if="permission === 'Turkey'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('TurkeyOffice');
                products = false;
              "
            >
              <v-icon color="turkey">mdi-city-variant</v-icon>
            </v-btn>
          </template>
          <span>Turkey Office</span>
        </v-tooltip>
        <v-tooltip top v-if="permission === 'USA'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('USAOffice');
                products = false;
              "
            >
              <v-icon color="usa">mdi-star-circle</v-icon>
            </v-btn>
          </template>
          <span>USA Office</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('DeliveredToErbil');
                products = false;
              "
            >
              <v-icon color="erbil">mdi-office-building-marker</v-icon>
            </v-btn>
          </template>
          <span>Delivered to Erbil</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('onhold');
                products = false;
              "
            >
              <v-icon color="hold">mdi-pause-circle</v-icon>
            </v-btn>
          </template>
          <span>Out Of Stock</span>
        </v-tooltip>
      </v-row>
      <!-- End Filter list -->

      <!-- Search by product code -->
      <v-row justify="center" align="center">
        <div max-width="800px">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                prepend-icon="mdi-barcode"
                :items="allOrders"
                clearable
                solo
                rounded
                @click:clear="orderSearch ? pageCheck() : ''"
                item-text="productCode"
                v-model="orderSearch"
                @change="orderSearch ? productCodeSearch(orderSearch) : ''"
                label="Product Code Filter"
                hint="You can filter by Product code"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-row>
      <!-- End Search by product code -->

      <!-- Progress circular -->
      <div
        align="center"
        justify="center"
        v-if="this.orders.length === 0 && this.progress"
      >
        <v-progress-circular
          :size="100"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else-if="this.orders.length === 0 && this.progress === false"
        align="center"
        justify="center"
      >
        <div v-if="all" class="display-1 grey--text font-weight-light">
          No orders in '{{ filterStatus }}'
        </div>
        <div v-else class="display-1 grey--text font-weight-light">
          No orders in '{{ query }}'
        </div>
      </div>

      <!-- End Progress circular -->

      <!-- Order list -->
      <v-expansion-panels>
        <v-expansion-panel v-for="(order, index) in orders" :key="order.id">
          <v-expansion-panel-header :class="` mx-0 order ${order.status}`">
            <v-row justify="center" align="center" class="spacer" no-gutters>
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.productImageURL
                        ? order.productImageURL
                        : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUploa-PlaceHolder.png?alt=media&token=fb193be8-59f6-47cc-b546-cfc951ba64a5'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption grey--text">Product Name</div>
                  <div
                    class="mt-0 mb-0"
                    v-if="!editMode || index != indexToEdit"
                  >
                    {{ order.product_name }}
                  </div>

                  <v-form
                    @submit.prevent="updateProductName(index, order.userId)"
                  >
                    <v-text-field
                      autofocus
                      :color="`${order.status}s`"
                      v-model="newProductName"
                      v-if="editMode && indexToEdit == index"
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </v-form>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="4" lg="2">
                <v-card tile flat>
                  <div class="caption px-2 grey--text">Customer Name</div>
                  <v-btn
                    text
                    small
                    rounded
                    :class="`${order.status} px-2 py-0 white--text`"
                  >
                    {{ order.displayName }}
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="1">
                <v-card tile flat>
                  <div class="caption grey--text">Submit Date</div>
                  <div class="mt-0 mx-0">
                    {{ order.submit_date.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                <v-card tile flat>
                  <div class="pt-3">
                    <v-menu rounded="b-xl" offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          small
                          rounded
                          :class="`${order.status} white--text`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ order.status }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="orderStatusItem in orderStatus"
                          :key="orderStatusItem"
                          link
                          @click="
                            changeStatus(index, order.userId, orderStatusItem)
                          "
                        >
                          <v-list-item-title
                            v-text="orderStatusItem"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Email</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.email }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="3"
                lg="2"
                align="center"
                justify="center"
              >
                <v-card tile flat>
                  <div class="mt-0 pt-0">
                    <v-btn
                      icon
                      :class="`${order.status}`"
                      @click="
                        editMode = !editMode;
                        indexToEdit = index;
                        newProductName = order.product_name;
                      "
                      class="pa-0 ma-0"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <router-link
                      class="routerLink"
                      :to="{
                        name: 'ordercheck',
                        params: { id: order.id, uid: order.userId },
                      }"
                    >
                      <v-btn icon :class="`${order.status}`" class="pa-0 ma-0"
                        ><v-icon>mdi-arrow-right-bold-circle</v-icon></v-btn
                      >
                    </router-link>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <!-- Orders more details -->
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-row align="center">
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.photoUrl
                        ? order.photoUrl
                        : 'https://via.placeholder.com/50/?text=zubene'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-card tile flat>
                  <a class="routerLink" :href="order.url" target="_blank">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      Link
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <a
                    class="routerLink"
                    :href="`tel:0${order.phoneNumber}`"
                    target="_blank"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      0{{ order.phoneNumber }}
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Price</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.price }} {{ order.currency }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Color</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productColor }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Size</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productSize }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Quntity</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.quantity }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Country</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.country }}
                  </div>
                </v-card>
              </v-col>

              <v-col
                v-if="order.description"
                cols="12"
                xs="12"
                sm="12"
                md="8"
                lg="8"
              >
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Description</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.description }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-center" v-show="pages">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="pageNumber"
                  class="my-4"
                  :length="pages"
                  @input="onPageChange"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import { auth, allOrders, userRef } from "@/firebase";
import { EventBus } from "@/event-bus.js";

export default {
  components: {},

  data() {
    return {
      allOfPrice: 0,
      numberOfAllOfTheOrders: 0,
      unsubUsers: null,
      unsubOrders: null,
      total: 0,
      pages: 0,
      perPage: 10,
      pageNumber: 1,
      orders: [],
      allOrders: [],
      userDataLoading: false,
      userSelected: "",
      snackbarInfo: false,
      snackbarText: "",
      snackbarColor: "success",
      unorderedList: [],
      userOrders: [],
      ordersCopy: [],
      products: true,
      userId: "",
      btnColor: "",
      snackbar: false,
      editMode: false,
      newProductName: "",
      indexToEdit: 0,
      users: [],
      filteredUsers: [],
      permission: "",
      colleagueDisplayName: "",
      filterStatus: "",
      progress: true,
      orderSearch: "",
      userIdConfirmed: "",
      all: false,
      allOrdersCollection: "",
      userDataLoding: false,
      userFiledSearch: [
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Name", value: "displayName" },
        { text: "Email", value: "email" },
      ],
      userFiledSearchSelected: "displayName",
    };
  },
  props: {
    query: {
      type: String,
      default: "processing",
    },
    page: {
      default: 1,
    },
  },
  mounted() {
    EventBus.$on("order-added", () => {
      this.saveOrder();
    });
  },

  computed: {},
  methods: {
    async userData() {
      // GET USER DATA
      this.userDataLoding = true;
      var userOrderRef = userRef.orderBy("displayName", "desc");
      this.unsubUsers = userOrderRef.onSnapshot(async (res) => {
        const userChanges = res.docChanges();
        userChanges.forEach((userChange) => {
          if (userChange.type === "added") {
            this.users.unshift({
              ...userChange.doc.data(),
              id: userChange.doc.id,
            });
          }
        });
      });
      this.userDataLoding = false;
    },
    async readData() {
      // Set function base of quers variable
      const status = this.orderStatus;
      if (this.query === "all") {
        this.allOrdersCollection = allOrders
          .where("status", "in", status)
          .where("country", "==", this.permission);
        this.all = true;
      } else {
        this.allOrdersCollection = allOrders
          .where("status", "==", this.query)
          .where("country", "==", this.permission);
        this.all = false;
      }
      this.unsubOrders = this.allOrdersCollection.onSnapshot((docs) => {
        const docsChanges = docs.docChanges();
        this.orders = [];
        this.ordersCopy = [];
        this.total = 0;
        docsChanges.forEach((res) => {
          const pureData = res.doc.data();
          const resType = res.type;
          let deleteItems = null;
          const accountingData = this.accountingModel(pureData);
          if (resType === "added") {
            this.accountingFunction(accountingData, deleteItems);
            this.allOrders.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
            this.ordersCopy.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
          } else if (resType === "modified") {
            let index = null;
            let modifiedItemPreviousData = null;
            const list = this.allOrders;
            const modifiedItem = { ...pureData, id: res.doc.id };
            this.allOrders = this.allOrders.filter(function (item) {
              if (item.id === res.doc.id) {
                index = list.indexOf(item);
                modifiedItemPreviousData = item;
              }
              return item.id !== res.doc.id;
            });
            this.ordersCopy = this.ordersCopy.filter(function (item) {
              return item.id !== res.doc.id;
            });
            this.allOrders.splice(index, 0, modifiedItem);
            this.ordersCopy.splice(index, 0, modifiedItem);
            deleteItems = this.accountingModel(modifiedItemPreviousData);
            this.accountingFunction(accountingData, deleteItems);
          } else if (resType === "removed") {
            this.accountingFunction(deleteItems, accountingData);
            this.allOrders = this.allOrders.filter(function (item) {
              return item.id !== res.doc.id;
            });
            this.ordersCopy = this.ordersCopy.filter(function (item) {
              return item.id !== res.doc.id;
            });
          } else {
            console.log("Unknown");
          }
        });
        // Get size of query
        this.pageNumber = Number(this.page);
        this.total = this.allOrders.length;
        // Round up result
        this.pages = Math.ceil(this.total / this.perPage);
        // Get size of query
        this.pageCheck();
        this.progress = false;
      });
    },
    onPageChange() {
      if (String(this.page) !== String(this.pageNumber)) {
        this.$router.push({
          name: "colleaguedashboard",
          params: { query: this.query, page: this.pageNumber },
        });
      }
    },
    pageCheck() {
      const start = this.perPage * (this.pageNumber - 1);
      const end = start + this.perPage;
      this.orders = this.allOrders.slice(start, end);
    },

    accountingModel(data) {
      const dataModel = {
        price: data.price,
        qty: data.quantity,
        currency: data.currency,
        rate: data.tryRate,
      };
      return dataModel;
    },

    accountingFunction(data, deleted) {
      if (data) {
        this.numberOfAllOfTheOrders += 1;
        if (data.price && data.currency === "Turkish lira") {
          const calc = (parseFloat(data.price) / parseFloat(data.rate)) * 100;
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(calc.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        } else if (data.price && data.currency) {
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(data.price.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        }
      }
      if (deleted) {
        {
          this.numberOfAllOfTheOrders -= 1;
          if (deleted.price && deleted.currency === "Turkish lira") {
            const calc =
              (parseFloat(deleted.price) / parseFloat(deleted.rate)) * 100;
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(calc.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          } else if (deleted.price && deleted.currency) {
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(deleted.price.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          }
        }
      }
    },

    openDialog() {
      EventBus.$emit("dialog", true);
    },

    filterOrders(status) {
      this.progress = true;
      this.orders = this.ordersCopy;
      this.filterStatus = status;
      if (status === "processing") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "processing";
        });
      } else if (status === "bought") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "bought";
        });
      } else if (status === "onhold") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "onhold";
        });
      } else if (status === "OnTheWay") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "OnTheWay";
        });
      } else if (status === "TurkeyOffice") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "TurkeyOffice";
        });
      } else if (status === "USAOffice") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "USAOffice";
        });
      } else if (status === "DeliveredToErbil") {
        this.orders = this.orders.filter(function (item) {
          return item.status === "DeliveredToErbil";
        });
      }
      this.progress = false;
    },

    // Filter orders item by parameter (that used for filter products code)
    productCodeSearch(SearchItem) {
      this.orders = this.allOrders.filter((item) => {
        return item.productCode === SearchItem;
      });
    },

    // Filter orders item by users (that used for filter user by field)
    productUserSearch(SearchItem, byField) {
      this.filteredUsers = this.users.filter((item) => {
        return item[byField] === SearchItem[byField];
      });
    },

    changeStatus(index, userId, status) {
      if (this.query !== status) {
        const newStatus = status;
        const currentOrder = this.orders[index];
        const changeOrderStatusDocRef = userRef
          .doc(userId)
          .collection("orders")
          .doc(currentOrder.id);

        // LOGIC FOR STATUS UPDATE
        switch (newStatus) {
          case "processing":
            var btnColor = "process";
            break;

          case "bought":
            btnColor = "buy";
            break;

          case "OnTheWay":
            btnColor = "way";
            break;

          case "TurkeyOffice":
            btnColor = "turkey";
            break;

          case "USAOffice":
            btnColor = "usa";
            break;

          case "DeliveredToErbil":
            btnColor = "erbil";
            break;

          case "onhold":
            btnColor = "hold";
            break;

          default:
            break;
        }

        const date = new Date(Date.now());
        // UPDATE DATABASE
        changeOrderStatusDocRef.set(
          {
            status: newStatus,
            userUpdated: this.colleagueDisplayName,
            statusUpdated: date,
          },
          { merge: true }
        );
        if (index > -1) {
          this.allOrders.splice(index, 1);
          this.ordersCopy.splice(index, 1);
          this.orders.splice(index, 1);
        }
        // UPDATE LOCAL DATA
        currentOrder.status = newStatus;
        this.btnColor = btnColor;
      }
    },

    updateProductName(index, userId) {
      const currentOrder = this.orders[index];
      const updateOrderRefDoc = userRef
        .doc(userId)
        .collection("orders")
        .doc(currentOrder.id);

      const date = new Date(Date.now());
      updateOrderRefDoc.set(
        {
          product_name: this.newProductName,
          userUpdated: this.colleagueDisplayName,
          statusUpdated: date,
        },
        { merge: true }
      );
      // UPDATE LOCAL DATA
      currentOrder.product_name = this.newProductName;

      this.editMode = false;
    },
  },

  async created() {
    // Get user Information
    const userAuth = await auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.userId = user.uid;
        this.colleagueDisplayName = user.displayName ? user.displayName : "";
        const userClaims = await auth.currentUser
          .getIdTokenResult()
          .then(({ claims }) => {
            if (claims.usa) {
              this.permission = "USA";
              this.orderStatus = [
                "processing",
                "bought",
                "OnTheWay",
                "USAOffice",
                "DeliveredToErbil",
                "onhold",
              ];
            } else if (claims.turkey) {
              this.permission = "Turkey";
              this.orderStatus = [
                "processing",
                "bought",
                "OnTheWay",
                "TurkeyOffice",
                "DeliveredToErbil",
                "onhold",
              ];
            }
          });
        userClaims;
      }
    });
    await userAuth;
    this.readData();
    this.progress = false;
  },
  beforeDestroy() {
    if (this.unsubUsers) {
      this.unsubUsers();
      this.unsubUsers = null;
    }
    if (this.unsubOrders) {
      this.unsubOrders();
      this.unsubOrders = null;
    }
  },
  watch: {
    async $route(to, from) {
      // react to route changes...
      if (
        to.params.query !== from.params.query ||
        to.params.page !== from.params.page
      ) {
        if (to.params.query === from.params.query) {
          this.pageCheck();
        } else {
          if (this.unsubUsers) {
            this.unsubUsers();
          }
          if (this.unsubOrders) {
            this.unsubOrders();
          }
          this.allOrders = [];
          this.ordersCopy = [];
          this.orders = [];
          this.allOfPrice = 0;
          this.numberOfAllOfTheOrders = 0;
          this.readData();
        }
      }
    },
  },
};
</script>

<style>
.order.completed {
  border-left: 4px solid #3cd1c2;
}
.order.failed {
  border-left: 4px solid #f83e70;
}
.order.processing {
  border-left: 4px solid #ab47bc;
}
.order.bought {
  border-left: 4px solid #1e88e5;
}
.order.pending {
  border-left: 4px solid #ffa726;
}
.order.shipped {
  border-left: 4px solid #6be4ff;
}
.order.InDispatch {
  border-left: 4px solid #54618c;
}
.order.canceled {
  border-left: 4px solid #8d6e63;
}
.order.OnTheWay {
  border-left: 4px solid #006064;
}
.order.refunded {
  border-left: 4px solid #cddc39;
}
.order.onhold {
  border-left: 4px solid #bdbdbd;
}

.theme--light.v-btn:not(.v-btn--icon).completed {
  background-color: #3cd1c2;
}

.theme--light.v-btn:not(.v-btn--icon).failed {
  background-color: #f83e70;
}

.theme--light.v-btn:not(.v-btn--icon).processing {
  background-color: #ab47bc;
}
.theme--light.v-btn:not(.v-btn--icon).bought {
  background-color: #1e88e5;
}

.theme--light.v-btn:not(.v-btn--icon).pending {
  background-color: #ffaa2c;
}

.theme--light.v-btn:not(.v-btn--icon).shipped {
  background-color: #6be4ff;
}
.theme--light.v-btn:not(.v-btn--icon).InDispatch {
  background-color: #54618c;
}

.theme--light.v-btn:not(.v-btn--icon).canceled {
  background-color: #8d6e63;
}

.theme--light.v-btn:not(.v-btn--icon).OnTheWay {
  background-color: #006064;
}

.theme--light.v-btn:not(.v-btn--icon).refunded {
  background-color: #cddc39;
}

.theme--light.v-btn:not(.v-btn--icon).onhold {
  background-color: #bdbdbd;
}

.theme--light.v-btn.completed {
  color: #3cd1c2;
}

.theme--light.v-btn.failed {
  color: #f83e70;
}

.theme--light.v-btn.processing {
  color: #ab47bc;
}

.theme--light.v-btn.bought {
  color: #1e88e5;
}

.theme--light.v-btn.OnTheWay {
  color: #006064;
}

.theme--light.v-btn.pending {
  color: #ffaa2c;
}
.theme--light.v-btn.shipped {
  color: #6be4ff;
}
.theme--light.v-btn.InDispatch {
  color: #54618c;
}

.theme--light.v-btn.canceled {
  color: #8d6e63;
}
.theme--light.v-btn.refunded {
  color: #cddc39;
}

.theme--light.v-btn.onhold {
  color: #bdbdbd;
}

.handle {
  cursor: move;
}

.routerLink {
  text-decoration: none;
}
</style>