<template v-slot:default="{ hover }">
  <v-app>
    <v-card tile color="background" class="pb-10" height="100%">
      <v-card tile outlined color="transparent" class="pb-10">
        <DashboardNavbar v-if="show" />
        <v-main class="mx-2">
          <router-view></router-view>
        </v-main>
        <FloatingActionButton v-if="show" />
        <Footer />
      </v-card>
    </v-card>
  </v-app>
</template>

<script>
import { auth } from "@/firebase";
import DashboardNavbar from "@/components/DashboardNavbar";
import Footer from "@/components/Footer";
import FloatingActionButton from "@/components/FloatingActionButton"

export default {
  name: "App",
  components: {
    Footer,
    DashboardNavbar,
    FloatingActionButton,

  },
  data: () => ({
    user: "",
  }),
  created() {
    const userAuth = auth;

    userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      }
    });
  },
  computed: {
    show() {
      if (this.user) {
        return this.$route.name != "userauth";
      }
      return false;
    },
  },
};
</script>

<style>
</style>