<template>
  <div class="userOrders">
    <v-banner
      icon="mdi-table-account"
      single-line
      class="subtitle-1 text-capitalize grey--text"
      >User orders | {{ displayName }}</v-banner
    >
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbarInfo" :timeout="4000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-snackbar v-model="snackbar" top color="warning" flat>
      <span>Are you sure?</span>
      <v-btn
        text
        color="white"
        @click="
          snackbar = false;
          deleteOrder();
        "
        >Yes</v-btn
      >
      <v-btn text color="white" @click="snackbar = false">No</v-btn>
    </v-snackbar>
    <v-container fluid class="px-0">
      <v-row dense
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-cart
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >All orders</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              {{ numberOfAllOfTheOrders }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-tag-multiple
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Price</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $ {{ allOfPrice }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-truck-fast
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Cargo</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $
              {{
                (
                  Number(allOfAmountPaid) +
                  Number(allOfRemainingPaid) -
                  Number(allOfPrice)
                ).toFixed(2)
              }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-wallet
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Cost</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $
              {{
                (Number(allOfAmountPaid) + Number(allOfRemainingPaid)).toFixed(
                  2
                )
              }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-cash-register
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Paid</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $ {{ allOfAmountPaid }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-chart-arc
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Unpaid</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $ {{ allOfRemainingPaid }}
            </v-card-text>
          </v-card></v-col
        ></v-row
      ></v-container
    >
    <v-container class="my-2">
      <v-row class="mb-2 px-4" justify="center">
        <v-col v-if="userData"
          ><Popup
            @orderAdded="snackbarTextMessage($event)"
            v-bind:userdata="userData"/></v-col
      ></v-row>
      <!-- Filter list -->
      <v-row class="mb-2 px-4" justify="center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="filterOrders('all')">
              <v-icon color="primary">mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>Show all orders</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('pending');
                products = false;
              "
            >
              <v-icon color="warning">mdi-timer-sand</v-icon>
            </v-btn>
          </template>
          <span>Pending</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('processing');
                products = false;
              "
            >
              <v-icon color="process">mdi-autorenew</v-icon>
            </v-btn>
          </template>
          <span>Processing</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('bought');
                products = false;
              "
            >
              <v-icon color="buy">mdi-credit-card-check</v-icon>
            </v-btn>
          </template>
          <span>Bought</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OnTheWay');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>On The Way</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('TurkeyOffice');
                products = false;
              "
            >
              <v-icon color="turkey">mdi-city-variant</v-icon>
            </v-btn>
          </template>
          <span>Turkey Office</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InDispatch');
                products = false;
              "
            >
              <v-icon color="InDispatch">mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span>In Dispatch</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('shipped');
                products = false;
              "
            >
              <v-icon color="shipped">mdi-cube-send</v-icon>
            </v-btn>
          </template>
          <span>Shipped</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('USAOffice');
                products = false;
              "
            >
              <v-icon color="usa">mdi-star-circle</v-icon>
            </v-btn>
          </template>
          <span>USA Office</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('DeliveredToErbil');
                products = false;
              "
            >
              <v-icon color="erbil">mdi-office-building-marker</v-icon>
            </v-btn>
          </template>
          <span>Delivered to Erbil</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('completed');
                products = false;
              "
            >
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-btn>
          </template>
          <span>Completed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('failed');
                products = false;
              "
            >
              <v-icon color="error">mdi-information</v-icon>
            </v-btn>
          </template>
          <span>Failed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('canceled');
                products = false;
              "
            >
              <v-icon color="cancel">mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Canceled</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InBorder');
                products = false;
              "
            >
              <v-icon color="hold">mdi-passport-biometric</v-icon>
            </v-btn>
          </template>
          <span>In Border</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OutForDelivery');
                products = false;
              "
            >
              <v-icon color="hold"> mdi-truck-delivery</v-icon>
            </v-btn>
          </template>
          <span>Out For Delivery</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('onhold');
                products = false;
              "
            >
              <v-icon color="hold">mdi-pause-circle</v-icon>
            </v-btn>
          </template>
          <span>Out Of Stock</span>
        </v-tooltip>
      </v-row>
      <!-- End Filter list -->

      <!-- Empty Orders -->
      <div align="center" justify="center" v-if="this.orders.length == 0">
        <p class="display-1 font-weight-light grey--text">Nothing here!</p>
        <p class="Caption font-weight-thin text--secondary grey--text">
          You can add a new order for this customer with the above button
        </p>
      </div>
      <!-- End Empty Orders -->

      <!-- Order list -->
      <v-expansion-panels>
        <v-expansion-panel
          text
          v-for="(order, index) in orders"
          :key="order.id"
          hide-actions
        >
          <v-expansion-panel-header :class="` mx-0 order ${order.status}`">
            <v-row justify="center" align="center" class="spacer" no-gutters>
              <v-col cols="12" xs="12" sm="1" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.productImageURL
                        ? order.productImageURL
                        : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUploa-PlaceHolder.png?alt=media&token=fb193be8-59f6-47cc-b546-cfc951ba64a5'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="12" sm="7" md="5" lg="5">
                <v-card tile flat color="transparent">
                  <div class="caption grey--text">Product Name</div>
                  <div
                    class="my-1 text-truncate"
                    style="max-width: 100px"
                    v-if="!editMode || index != indexToEdit"
                  >
                    {{ order.product_name }}
                  </div>

                  <v-form
                    @submit.prevent="updateProductName(index, order.userId)"
                  >
                    <v-text-field
                      autofocus
                      :color="`${order.status}s`"
                      v-model="newProductName"
                      v-if="editMode && indexToEdit == index"
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </v-form>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="2" lg="2">
                <v-card tile flat align="center" color="transparent">
                  <div class="caption px-1 grey--text">Confirmation</div>
                  <div v-if="order.isConfirmed === true" class="mt-0 mx-0 px-1">
                    <v-icon color="green"> mdi-check-circle </v-icon>
                  </div>
                  <div
                    v-else-if="order.isConfirmed === false"
                    class="mt-0 mx-0 px-1"
                  >
                    <v-icon color="red"> mdi-close-circle </v-icon>
                  </div>
                  <div v-else class="mt-0 mx-0 px-1">
                    <v-icon color="blue"> mdi-help-circle</v-icon>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="4" md="4" lg="4">
                <v-card tile flat>
                  <div class="caption px-2 grey--text">Customer Name</div>
                  <router-link
                    class="routerLink"
                    :to="{ name: 'userorders', params: { id: order.userId } }"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} px-2 py-0 white--text`"
                    >
                      {{ order.displayName }}
                    </v-btn>
                  </router-link>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Price</div>
                  <div
                    v-if="order.currency === 'Turkish lira'"
                    class="mt-0 mx-0 px-1"
                  >
                    ₺ {{ order.price }}
                  </div>
                  <div
                    v-else-if="order.currency === 'US Dollar'"
                    class="mt-0 mx-0 px-1"
                  >
                    $ {{ order.price }}
                  </div>
                  <div v-else class="mt-0 mx-0 px-1 font-weight-light">
                    Not Set
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Paid</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.amountPaid }}</div>
                </v-card>
              </v-col>

              <v-col cols="12" xs="12" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Shipping</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.shippingCost }}</div>
                </v-card>
              </v-col>

              <v-col cols="12" xs="12" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Total Shipping</div>
                  <div class="mt-0 mx-0 px-1">
                    $ {{ order.totalShippingCost }}
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" xs="12" sm="2" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Remaining</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.remainingPaid }}</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="4" md="2" lg="2">
                <v-card tile flat>
                  <div class="pt-3">
                    <v-menu rounded="b-xl" offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          small
                          rounded
                          :class="`${order.status} white--text`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ order.status }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="orderStatusItem in orderStatus"
                          :key="orderStatusItem"
                          link
                          @click="
                            changeStatus(index, order.userId, orderStatusItem)
                          "
                        >
                          <v-list-item-title
                            v-text="orderStatusItem"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="4"
                md="3"
                lg="3"
                align="center"
                justify="center"
              >
                <v-card tile flat color="transparent">
                  <div class="mt-0 pt-0">
                    <v-btn
                      icon
                      :class="`${order.status}`"
                      @click="
                        editMode = !editMode;
                        indexToEdit = index;
                        newProductName = order.product_name;
                      "
                      class="pa-0 ma-0"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <!-- <v-btn
                      icon
                      :class="`${order.status}`"
                      @click="
                        snackbar = true;
                        indexToEdit = index;
                        userIdDelete = order.userId;
                      "
                      class="pa-0 ma-0"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    > -->
                    <router-link
                      class="routerLink"
                      :to="{
                        name: 'ordercheck',
                        params: { id: order.id, uid: order.userId },
                      }"
                    >
                      <v-btn icon :class="`${order.status}`" class="pa-0 ma-0"
                        ><v-icon>mdi-arrow-right-bold-circle</v-icon></v-btn
                      >
                    </router-link>
                    <v-btn
                      :href="`https://wa.me/964${order.phoneNumber}`"
                      target="_blank"
                      icon
                      :class="`${order.status}`"
                      class="pa-0 ma-0"
                      ><v-icon>mdi-whatsapp</v-icon></v-btn
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <!-- Orders more details -->
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-row align="center">
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.photoUrl
                        ? order.photoUrl
                        : 'https://via.placeholder.com/50/?text=zubene'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-card tile flat>
                  <a class="routerLink" :href="order.url" target="_blank">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      Link
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <a
                    class="routerLink"
                    :href="`tel:0${order.phoneNumber}`"
                    target="_blank"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      0{{ order.phoneNumber }}
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Color</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productColor }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Size</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productSize }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Quntity</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.quantity }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Country</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.country }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="5" lg="5">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Email</div>
                  <div class="mt-0 mx-0 px-1">
                    <a
                      class="routerLink black--text"
                      :href="`mailto:0${order.email}`"
                      target="_blank"
                    >
                      {{ order.email }}
                    </a>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">User Updated</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.userUpdated }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="2"
                lg="2"
                v-if="order.statusUpdated"
              >
                <v-card tile flat>
                  <div class="caption grey--text">Status Updated</div>
                  <div class="mt-0 mx-0">
                    {{ order.statusUpdated.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption grey--text">Submit Date</div>
                  <div class="mt-0 mx-0">
                    {{ order.submit_date.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                v-if="order.description"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Description</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.description }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-center" v-show="pages">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="pageNumber"
                  class="my-4"
                  :length="pages"
                  @input="onPageChange"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import { auth, userRef } from "@/firebase";
import { EventBus } from "@/event-bus.js";
import Popup from "@/components/Popup";
export default {
  components: {
    Popup,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      default: "all",
    },
    page: {
      default: "1",
    },
  },
  data() {
    return {
      allOfPrice: 0,
      allOfShippingCost: 0,
      allOfAmountPaid: 0,
      allOfRemainingPaid: 0,
      numberOfAllOfTheOrders: 0,
      snackbarInfo: false,
      snackbarText: "",
      snackbarColor: "success",
      total: 0,
      pages: 0,
      perPage: 5,
      pageNumber: 1,
      flag: false,
      filterOrdersStatus: "all",
      orders: [],
      allOrders: [],
      userOrders: [],
      ordersCopy: [],
      userOrdersCopy: [],
      orderStatus: [
        "pending",
        "processing",
        "bought",
        "OnTheWay",
        "TurkeyOffice",
        "InDispatch",
        "shipped",
        "USAOffice",
        "DeliveredToErbil",
        "completed",
        "InBorder",
        "OutForDelivery",
        "onhold",
        "failed",
        "canceled",
      ],
      products: true,
      userId: "",
      btnColor: "",
      snackbar: false,
      editMode: false,
      newProductName: "",
      indexToEdit: 0,
      userIdDelete: "",
      drag: true,
      adminDisplayName: "",
      userData: null,
      displayName: "",
      ordersUnsub: null,
    };
  },
  mounted() {
    EventBus.$on("order-added", () => {
      this.saveOrder();
    });
    if (this.query) {
      this.filterOrdersStatus = this.query;
    }
    if (this.page) {
      this.pageNumber = Number(this.page);
    }
  },
  methods: {
    async readData() {
      const userRefCollection = await userRef.doc(this.id).get();
      this.userData = userRefCollection.data();
      this.userData = {
        ...userRefCollection.data(),
        uid: userRefCollection.id,
      };
      this.displayName = this.userData.displayName;

      let ordersRef = userRef.doc(this.id).collection("orders");
      ordersRef = ordersRef.orderBy("submit_date", "asc");
      // GET DATA


      this.ordersUnsub = ordersRef.onSnapshot((docs) => {
       
        const docsChanges = docs.docChanges();
        this.total = 0;
        docsChanges.forEach((res) => {
  
          const pureData = res.doc.data();

          const resType = res.type;
          let deleteItems = null;
          const accountingData = this.accountingModel(pureData);
          if (resType === "added") {
            this.accountingFunction(accountingData, deleteItems);
            this.allOrders.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
            this.ordersCopy.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
          } else if (resType === "modified") {
            let index = null;
            let modifiedItemPreviousData = null;
            const list = this.allOrders;
            const modifiedItem = { ...pureData, id: res.doc.id };
            this.allOrders = this.allOrders.filter(function(item) {
              if (item.id === res.doc.id) {
                index = list.indexOf(item);
                modifiedItemPreviousData = item;
              }
              return item.id !== res.doc.id;
            });
            this.ordersCopy = this.ordersCopy.filter(function(item) {
              return item.id !== res.doc.id;
            });
            this.allOrders.splice(index, 0, modifiedItem);
            this.ordersCopy.splice(index, 0, modifiedItem);
            deleteItems = this.accountingModel(modifiedItemPreviousData);
            this.accountingFunction(accountingData, deleteItems);
          } else if (resType === "removed") {
            this.accountingFunction(deleteItems, accountingData);
            this.allOrders = this.allOrders.filter(function(item) {
              return item.id !== res.doc.id;
            });
            this.ordersCopy = this.ordersCopy.filter(function(item) {
              return item.id !== res.doc.id;
            });
          } else {
            console.log("Unknown");
          }
        });
        if (this.filterOrdersStatus !== "all") {
          this.flag = true;
          this.filterOrders(this.filterOrdersStatus);
        }
        this.pageCheck();
        this.progress = false;
      });
    },

    onPageChange() {
      if (
        String(this.page) !== String(this.pageNumber) ||
        this.query !== this.filterOrdersStatus
      ) {
        this.$router.push({
          name: "userorders",
          params: {
            id: this.id,
            query: this.filterOrdersStatus,
            page: String(this.pageNumber),
          },
        });
      }
    },
    pageCheck() {
      // Get size of query
      this.pageNumber = Number(this.page);
      this.total = this.allOrders.length;
      // Round up result
      this.pages = Math.ceil(this.total / this.perPage);
      // Get size of query
      const start = this.perPage * (this.pageNumber - 1);
      const end = start + this.perPage;
      this.orders = this.allOrders.slice(start, end);
    },

    accountingModel(data) {
      // console.log('data.pice')
      // console.log(data)
      // console.log(`type:${typeof data.price}- value:${data.price} - Status: ${data.status} id: ${data.id}`);
      if(typeof data.price ==='string'){
        console.debug(data)
      }
      const dataModel = {
        price: data.price,
        qty: data.quantity,
        shipping: data.shippingCost,
        totalShippingCost: data.totalShippingCost,
        paid: data.amountPaid,
        remaining: data.remainingPaid,
        currency: data.currency,
        rate: data.tryRate,
      };
      return dataModel;
    },

    accountingFunction(data, deleted) {
      if (data) {
        this.numberOfAllOfTheOrders += 1;
        if (data.price && data.currency === "Turkish lira") {
          const calc = (parseFloat(data.price) / parseFloat(data.rate)) * 100;
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(calc.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        } else if (data.currency) {
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(data.price.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        }
        if (data.shipping) {
          this.allOfShippingCost = Number(
            (
              Number(this.allOfShippingCost.toFixed(2)) +
              Number(data.shipping.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.paid) {
          this.allOfAmountPaid = Number(
            (
              Number(this.allOfAmountPaid.toFixed(2)) +
              Number(data.paid.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.remaining) {
          this.allOfRemainingPaid = Number(
            (
              Number(this.allOfRemainingPaid.toFixed(2)) +
              Number(data.remaining.toFixed(2))
            ).toFixed(2)
          );
        }
      }
      if (deleted) {
        {
          this.numberOfAllOfTheOrders -= 1;
          if (deleted.price && deleted.currency === "Turkish lira") {
            const calc =
              (parseFloat(deleted.price) / parseFloat(deleted.rate)) * 100;
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(calc.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          } else if (deleted.currency) {
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(deleted.price.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          }
          if (deleted.shipping) {
            this.allOfShippingCost = Number(
              (
                Number(this.allOfShippingCost.toFixed(2)) -
                Number(deleted.shipping.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.paid) {
            this.allOfAmountPaid = Number(
              (
                Number(this.allOfAmountPaid.toFixed(2)) -
                Number(deleted.paid.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.remaining) {
            this.allOfRemainingPaid = Number(
              (
                Number(this.allOfRemainingPaid.toFixed(2)) -
                Number(deleted.remaining.toFixed(2))
              ).toFixed(2)
            );
          }
        }
      }
    },

    snackbarTextMessage: function(message) {
      this.text = message.snackbarText;
      this.snackbarColor = message.snackbarColor;
      this.snackbar = message.snackbarInfo;
    },

    openDialog() {
      EventBus.$emit("dialog", true);
    },
    async filterOrders(status) {
      this.allOrders = this.ordersCopy;
      this.allOfPrice = 0;
      this.allOfShippingCost = 0;
      this.allOfAmountPaid = 0;
      this.allOfRemainingPaid = 0;
      this.numberOfAllOfTheOrders = 0;
      const deleted = 0;
      if (status === "completed") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "completed";
        });
      } else if (status === "pending") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "pending";
        });
      } else if (status === "failed") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "failed";
        });
      } else if (status === "processing") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "processing";
        });
      } else if (status === "bought") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "bought";
        });
      } else if (status === "canceled") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "canceled";
        });
      } else if (status === "onhold") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "onhold";
        });
      } else if (status === "OnTheWay") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "OnTheWay";
        });
      } else if (status === "InBorder") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "InBorder";
        });
      } else if (status === "OutForDelivery") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "OutForDelivery";
        });
      } else if (status === "TurkeyOffice") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "TurkeyOffice";
        });
      } else if (status === "InDispatch") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "InDispatch";
        });
      } else if (status === "shipped") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "shipped";
        });
      } else if (status === "USAOffice") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "USAOffice";
        });
      } else if (status === "DeliveredToErbil") {
        this.allOrders = this.allOrders.filter((item) => {
          if (item.status === status) {
            const data = this.accountingModel(item);
            this.accountingFunction(data, deleted);
          }
          return item.status === "DeliveredToErbil";
        });
      } else if (status === "all") {
        this.allOrders.forEach((item) => {
          const data = this.accountingModel(item);
          this.accountingFunction(data, deleted);
        });
        this.products = true;
      }
      this.filterOrdersStatus = status;
      if (!this.flag) {
        this.pageNumber = 1;
      } else {
        this.flag = false;
      }
      await this.onPageChange();
      await this.pageCheck();
    },
    async changeStatus(index, userId, status) {
      if (this.query !== status) {
        const newStatus = status;
        const currentOrder = this.orders[index];
        const changeOrderStatusDocRef = userRef
          .doc(userId)
          .collection("orders")
          .doc(currentOrder.id);

        // LOGIC FOR STATUS UPDATE
        switch (newStatus) {
          case "pending":
            var btnColor = "info";
            break;

          case "processing":
            btnColor = "process";
            break;

          case "bought":
            btnColor = "buy";
            break;

          case "OnTheWay":
            btnColor = "way";
            break;

          case "TurkeyOffice":
            btnColor = "turkey";
            break;

          case "InDispatch":
            btnColor = "InDispatch";
            break;

          case "shipped":
            btnColor = "shipped";
            break;

          case "USAOffice":
            btnColor = "usa";
            break;

          case "DeliveredToErbil":
            btnColor = "erbil";
            break;

          case "InBorder":
            btnColor = "erbil";
            break;

          case "OutForDelivery":
            btnColor = "erbil";
            break;

          case "completed":
            btnColor = "success";
            break;

          case "failed":
            btnColor = "error";
            break;

          case "canceled":
            btnColor = "cancel";
            break;

          case "onhold":
            btnColor = "hold";
            break;

          default:
            break;
        }

        const date = new Date(Date.now());
        // UPDATE DATABASE
        await changeOrderStatusDocRef.set(
          {
            status: newStatus,
            userUpdated: this.adminDisplayName,
            statusUpdated: date,
          },
          { merge: true }
        );
        // UPDATE LOCAL DATA
        currentOrder.status = newStatus;
        this.btnColor = btnColor;
      }
    },

    deleteOrder() {
      var index = this.indexToEdit;
      const currentOrder = this.orders[index];
      const deleteOrderRefDoc = userRef
        .doc(this.userIdDelete)
        .collection("orders")
        .doc(currentOrder.id);

      // DELETE ON DATABASE
      deleteOrderRefDoc
        .delete()
        .then(() => {
          this.snackbarText = `${currentOrder.product_name} has been successfully deleted`;
          this.snackbarColor = "success";
          this.snackbarInfo = true;
        })
        .catch((error) => {
          this.snackbarText = error.mesage;
          this.snackbarColor = "error";
          this.snackbarInfo = true;
        });

      // UPDATE ORDER ON LOCAL LIST
      if (index > -1) {
        this.orders.splice(index, 1);
      }
    },

    updateProductName(index, userId) {
      const currentOrder = this.orders[index];
      const updateOrderRefDoc = userRef
        .doc(userId)
        .collection("orders")
        .doc(currentOrder.id);

      const date = new Date(Date.now());

      updateOrderRefDoc.set(
        {
          product_name: this.newProductName,
          userUpdated: this.adminDisplayName,
          statusUpdated: date,
        },
        { merge: true }
      );

      // UPDATE LOCAL DATA
      currentOrder.product_name = this.newProductName;

      this.editMode = false;
    },
  },

  async created() {
    const userAuth = auth;
    await userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userId = user.uid;
        this.adminDisplayName = user.displayName;
      }
    });
    this.readData();
  },
  watch: {
    async $route(to, from) {
      // react to route changes...
      if (
        to.params.query !== from.params.query ||
        to.params.id !== from.params.id ||
        to.params.page !== from.params.page
      ) {
        if (to.params.query === from.params.query) {
          this.pageCheck();
        }
      }
    },
  },
  beforeDestroy() {
    if (this.ordersUnsub) {
      this.ordersUnsub();
      this.ordersUnsub = null;
    }
  },
};
</script>

<style>
.order.completed {
  border-left: 4px solid #3cd1c2;
}
.order.failed {
  border-left: 4px solid #f83e70;
}
.order.processing {
  border-left: 4px solid #ab47bc;
}
.order.pending {
  border-left: 4px solid #ffa726;
}
.order.InDispatch {
  border-left: 4px solid #54618c;
}
.order.shipped {
  border-left: 4px solid #6be4ff;
}
.order.canceled {
  border-left: 4px solid #8d6e63;
}
.order.OnTheWay {
  border-left: 4px solid #006064;
}
.order.refunded {
  border-left: 4px solid #cddc39;
}
.order.onhold {
  border-left: 4px solid #bdbdbd;
}

.theme--light.v-btn:not(.v-btn--icon).completed {
  background-color: #3cd1c2;
}

.theme--light.v-btn:not(.v-btn--icon).failed {
  background-color: #f83e70;
}

.theme--light.v-btn:not(.v-btn--icon).processing {
  background-color: #ab47bc;
}

.theme--light.v-btn:not(.v-btn--icon).pending {
  background-color: #ffaa2c;
}

.theme--light.v-btn:not(.v-btn--icon).InDispatch {
  background-color: #54618c;
}
.theme--light.v-btn:not(.v-btn--icon).shipped {
  background-color: #6be4ff;
}

.theme--light.v-btn:not(.v-btn--icon).canceled {
  background-color: #8d6e63;
}

.theme--light.v-btn:not(.v-btn--icon).OnTheWay {
  background-color: #006064;
}

.theme--light.v-btn:not(.v-btn--icon).refunded {
  background-color: #cddc39;
}

.theme--light.v-btn:not(.v-btn--icon).onhold {
  background-color: #bdbdbd;
}

.theme--light.v-btn.completed {
  color: #3cd1c2;
}

.theme--light.v-btn.failed {
  color: #f83e70;
}

.theme--light.v-btn.processing {
  color: #ab47bc;
}

.theme--light.v-btn.pending {
  color: #ffaa2c;
}
.theme--light.v-btn.InDispatch {
  color: #54618c;
}
.theme--light.v-btn.shipped {
  color: #6be4ff;
}

.theme--light.v-btn.canceled {
  color: #8d6e63;
}

.theme--light.v-btn.OnTheWay {
  color: #006064;
}

.theme--light.v-btn.refunded {
  color: #cddc39;
}

.theme--light.v-btn.onhold {
  color: #bdbdbd;
}

.handle {
  cursor: move;
}

.routerLink {
  text-decoration: none;
}
</style>
