<template>
  <v-card id="create">
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-message-text </v-icon>
        </v-btn>
      </template>
      <v-btn
        :href="`https://wa.me/9647502224122`"
        target="_blank"
        fab
        dark
        small
        color="green accent-3"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        :href="`http://m.me/zubenee`"
        target="_blank"
        fab
        dark
        small
        color="light-blue accent-4"
      >
        <v-icon>mdi-facebook-messenger</v-icon>
      </v-btn>
      <v-btn
        :href="`tg://msg?to=9647502224122`"
        target="_blank"
        fab
        dark
        small
        color="light-blue accent-3"
      >
        <v-icon>mdi-telegram</v-icon>
      </v-btn>
      <v-btn
        :href="`tel:+9647502224122`"
        target="_blank"
        fab
        dark
        small
        color="green accent-4"
      >
        <v-icon>mdi-phone</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-card>
</template>


<script>
export default {
  data: () => ({
    direction: "top",
    fab: false,
    fling: false,
    hover: true,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "scale-transition",
    from: "Zubene",
  }),

  computed: {
    activeFab() {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
  },
};
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: fixed;
  z-index: 999;
}

#create .v-btn--floating {
  position: relative;
}
</style>