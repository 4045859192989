<template>
  <div class="Dashboard">
    <v-banner
      icon="mdi-view-dashboard"
      single-line
      class="subtitle-1 text-capitalize grey--text"
      >Dashboard | {{ query }}</v-banner
    >
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbarInfo" :timeout="4000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-snackbar
      top
      light
      :timeout="8000"
      v-model="snackbar"
      color="warning"
      flat
    >
      <span>Do you confirm this order?<br /></span
      ><span
        v-if="orders[indexToConfirim]"
        justify="center"
        align="center"
        class="my-2 font-italic"
      >
        Price: {{ orders[indexToConfirim].price }}
        {{ orders[indexToConfirim].currency }} - Shipping:
        {{ orders[indexToConfirim].totalShippingCost }} $
      </span>
      <v-row justify="center" align="center" class="my-2">
        <v-btn
          class="mx-10"
          dark
          color="green"
          @click="
            snackbar = false;
            confirimOrder(true);
          "
          ><v-icon left>mdi-check-circle</v-icon>Yes</v-btn
        >
        <v-btn
          class="mx-10"
          dark
          color="red"
          @click="
            snackbar = false;
            confirimOrder(false);
          "
          ><v-icon left>mdi-close-circle</v-icon>No</v-btn
        >
      </v-row>
    </v-snackbar>
    <v-alert v-if="query === 'all'" dense color="blue" type="info">
      Includes only fulfilling orders
      <strong
        >[ Pending, Processing, On The Way, Bought, Turkey Office, USA office
        and Delivered to Erbil ]</strong
      >
    </v-alert>
    <v-container fluid class="px-0">
      <v-row dense
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-cart
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >All orders</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              {{ numberOfAllOfTheOrders }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-tag-multiple
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Price</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $ {{ allOfPrice }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-truck-fast
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Cargo</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $
              {{
                (
                  Number(allOfAmountPaid) +
                  Number(allOfRemainingPaid) -
                  Number(allOfPrice)
                ).toFixed(2)
              }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-wallet
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Cost</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $
              {{
                (Number(allOfAmountPaid) + Number(allOfRemainingPaid)).toFixed(
                  2
                )
              }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-cash-register
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Paid</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $ {{ allOfAmountPaid }}
            </v-card-text>
          </v-card></v-col
        ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
              >
                mdi-chart-arc
              </v-icon>
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Unpaid</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $ {{ allOfRemainingPaid }}
            </v-card-text>
          </v-card></v-col
        ></v-row
      ></v-container
    >
    <v-container fluid class="pt-0">
      <!-- Search user by phone number -->
      <v-row align="center" justify="center">
        <v-card outlined tile color="transparent" max-width="800px">
          <v-col cols="12" sm="12" md="12" class="pt-0 pb-4">
            <v-autocomplete
              :dense="$vuetify.breakpoint.smAndDown"
              prepend-icon="mdi-link"
              :items="orders"
              return-object
              solo
              rounded
              item-text="url"
              v-model="linksSelected"
              label="Your website links"
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-card>
        <v-expansion-panels popout v-if="linksSelected">
          <v-expansion-panel text hide-actions>
            <v-expansion-panel-header class="mx-0">
              <v-row justify="start" align="center" class="spacer" no-gutters>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-avatar size="50px">
                    <img
                      alt="ZUBENE"
                      :src="
                        linksSelected.productImageURL
                          ? linksSelected.productImageURL
                          : 'https://via.placeholder.com/50/?text=zubene'
                      "
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                  <v-card tile flat>
                    <v-btn text small rounded class="green white--text">
                      {{ linksSelected.price }} {{ linksSelected.currency }}
                    </v-btn>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-card tile flat>
                    <v-btn
                      text
                      small
                      rounded
                      class="green px-2 py-0 white--text"
                    >
                      {{ linksSelected.country }}
                    </v-btn>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                  <v-card tile flat>
                    <v-btn text small rounded class="green white--text">
                      quantity : {{ linksSelected.quantity }}
                    </v-btn>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-card tile flat>
                    <v-btn text small rounded class="green white--text">
                      {{ linksSelected.status }}
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-divider></v-divider>
              <v-row align="center">
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Product Size</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ linksSelected.productSize }}
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Product Color</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ linksSelected.productColor }}
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Message</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ linksSelected.message }}
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-divider class="mb-2"></v-divider>
      <!-- Filter list -->
      <v-row class="mb-2 px-4" justify="center" v-if="false">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                orders = ordersCopy;
                products = true;
                filterStatus = '';
              "
            >
              <v-icon color="primary">mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>Show all orders</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('pending');
                products = false;
              "
            >
              <v-icon color="warning">mdi-timer-sand</v-icon>
            </v-btn>
          </template>
          <span>Pending</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('processing');
                products = false;
              "
            >
              <v-icon color="process">mdi-autorenew</v-icon>
            </v-btn>
          </template>
          <span>Processing</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('bought');
                products = false;
              "
            >
              <v-icon color="buy">mdi-credit-card-check</v-icon>
            </v-btn>
          </template>
          <span>Bought</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OnTheWay');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>On The Way</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('TurkeyOffice');
                products = false;
              "
            >
              <v-icon color="turkey">mdi-city-variant</v-icon>
            </v-btn>
          </template>
          <span>Turkey Office</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InDispatch');
                products = false;
              "
            >
              <v-icon color="InDispatch">mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span>In Dispatch</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('shipped');
                products = false;
              "
            >
              <v-icon color="shipped">mdi-cube-send</v-icon>
            </v-btn>
          </template>
          <span>Shipped</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('USAOffice');
                products = false;
              "
            >
              <v-icon color="usa">mdi-star-circle</v-icon>
            </v-btn>
          </template>
          <span>USA Office</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('DeliveredToErbil');
                products = false;
              "
            >
              <v-icon color="erbil">mdi-office-building-marker</v-icon>
            </v-btn>
          </template>
          <span>Delivered to Erbil</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InBorder');
                products = false;
              "
            >
              <v-icon color="erbil">mdi-office-building-marker</v-icon>
            </v-btn>
          </template>
          <span>In Border</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OutForDelivery');
                products = false;
              "
            >
              <v-icon color="erbil">mdi-office-building-marker</v-icon>
            </v-btn>
          </template>
          <span>OutForDelivery</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('completed');
                products = false;
              "
            >
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-btn>
          </template>
          <span>Completed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('failed');
                products = false;
              "
            >
              <v-icon color="error">mdi-information</v-icon>
            </v-btn>
          </template>
          <span>Failed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('canceled');
                products = false;
              "
            >
              <v-icon color="cancel">mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Canceled</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('onhold');
                products = false;
              "
            >
              <v-icon color="hold">mdi-pause-circle</v-icon>
            </v-btn>
          </template>
          <span>Out Of Stock</span>
        </v-tooltip>
      </v-row>

      <!-- Progress circular -->
      <div
        align="center"
        justify="center"
        v-if="this.orders.length === 0 && this.progress"
      >
        <v-progress-circular
          :size="100"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else-if="this.orders.length === 0 && this.progress === false"
        align="center"
        justify="center"
      >
        <div class="display-1 grey--text font-weight-light">
          No orders <span v-if="filterStatus"> in '{{ filterStatus }}'</span>
        </div>
        <div class="subtitle-2 grey--text font-weight-light">
          Click 'New Order' above to add a new order
        </div>
      </div>

      <!-- End Progress circular -->

      <!-- Order list -->
      <v-expansion-panels class="my-3">
        <v-expansion-panel
          text
          v-for="(order, index) in orders"
          :key="order.id"
          hide-actions
        >
          <v-expansion-panel-header :class="` mx-0 order ${order.status}`">
            <v-row justify="center" align="center" class="spacer" no-gutters>
              <v-col
                v-if="order.price && order.isConfirmed === 'NotSet'"
                align="center"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <v-btn
                  @click="
                    snackbar = true;
                    indexToConfirim = index;
                    userIdConfirmed = order.userId;
                  "
                  rounded
                  dark
                  class="deep-orange"
                >
                  <v-icon left> mdi-information </v-icon>
                  <span class="subtitle-2 font-weight-black text-capitalize">
                    Price Confirmation
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.productImageURL
                        ? order.productImageURL
                        : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUploa-PlaceHolder.png?alt=media&token=fb193be8-59f6-47cc-b546-cfc951ba64a5'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="4" lg="4">
                <v-card tile flat>
                  <div class="caption grey--text">Product Name</div>
                  <div
                    class="mt-0 mb-0"
                    v-if="!editMode || index != indexToEdit"
                  >
                    {{ order.product_name }}
                  </div>

                  <v-form
                    @submit.prevent="updateProductName(index, order.userId)"
                  >
                    <v-text-field
                      autofocus
                      :color="`${order.status}s`"
                      v-model="newProductName"
                      v-if="editMode && indexToEdit == index"
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </v-form>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-2 grey--text">Country</div>
                  <v-btn
                    text
                    small
                    rounded
                    :class="`${order.status} px-2 py-0 white--text`"
                  >
                    {{ order.country }}
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="1" lg="1">
                <v-card tile flat align="center">
                  <div class="caption px-1 grey--text">Confirmation</div>
                  <div v-if="order.isConfirmed === true" class="mt-0 mx-0 px-1">
                    <v-icon color="green"> mdi-check-circle </v-icon>
                  </div>
                  <div
                    v-else-if="order.isConfirmed === false"
                    class="mt-0 mx-0 px-1"
                  >
                    <v-icon color="red"> mdi-close-circle </v-icon>
                  </div>
                  <div v-else class="mt-0 mx-0 px-1">
                    <v-icon color="blue"> mdi-help-circle</v-icon>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption grey--text">Submit Date</div>
                  <div class="mt-0 mx-0">
                    {{ order.submit_date.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="pt-3">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      {{ order.status }}
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" xs="4" sm="3" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Price</div>
                  <div
                    v-if="order.currency === 'Turkish lira'"
                    class="mt-0 mx-0 px-1"
                  >
                    ₺ {{ order.price }}
                  </div>
                  <div
                    v-else-if="order.currency === 'US Dollar'"
                    class="mt-0 mx-0 px-1"
                  >
                    $ {{ order.price }}
                  </div>
                  <div v-else class="mt-0 mx-0 px-1 font-weight-light">
                    Not Set
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" xs="3" sm="3" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Quntity</div>
                  <div class="mt-0 mx-0 px-1">{{ order.quantity }}</div>
                </v-card>
              </v-col>
              <v-col cols="4" xs="3" sm="2" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Paid</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.amountPaid }}</div>
                </v-card>
              </v-col>
              <v-col cols="4" xs="3" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Shipping</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.shippingCost }}</div>
                </v-card>
              </v-col>
              <v-col cols="4" xs="3" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Total Shipping</div>
                  <div class="mt-0 mx-0 px-1">
                    $ {{ order.totalShippingCost }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" xs="4" sm="3" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Remaining</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.remainingPaid }}</div>
                  <small class="caption px-1 grey--text">Deposit</small>
                  <small class="mt-0 mx-0 px-1 green--text" v-if="order.credit"
                    >$ {{ order.credit }}</small
                  >
                  <small class="mt-0 mx-0 px-1 red--text" v-else>$ 0 </small>
                </v-card>
              </v-col>
              <v-col
                cols="4"
                xs="4"
                sm="6"
                md="2"
                lg="2"
                align="center"
                justify="center"
              >
                <v-card tile flat color="transparent">
                  <div>
                    <v-btn
                      icon
                      :class="`${order.status}`"
                      @click="
                        editMode = !editMode;
                        indexToEdit = index;
                        newProductName = order.product_name;
                      "
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <router-link
                      class="routerLink"
                      :to="{
                        name: 'ordercheck',
                        params: { id: order.id, uid: order.userId },
                      }"
                    >
                      <v-btn icon :class="`${order.status}`"
                        ><v-icon>mdi-arrow-right-bold-circle</v-icon></v-btn
                      >
                    </router-link>
                  </div>
                </v-card>
              </v-col>
              <v-col
                v-if="order.message"
                cols="12"
                xs="12"
                sm="6"
                md="12"
                lg="12"
              >
                <v-alert
                  class="px-3 py-1 mt-2"
                  color="blue"
                  outlined
                  text
                  icon="mdi-message-text"
                  dense
                  border="left"
                  type="info"
                >
                  <div class="caption">Admin Message</div>
                  <div class="py-2">
                    {{ order.message }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <!-- Orders more details -->
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-row align="center">
              <v-col cols="4" xs="4" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.photoUrl
                        ? order.photoUrl
                        : 'https://via.placeholder.com/50/?text=zubene'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="4" xs="4" sm="6" md="1" lg="1">
                <v-card tile flat>
                  <a class="routerLink" :href="order.url" target="_blank">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      Link
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="4" xs="4" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Color</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productColor }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <a
                    class="routerLink"
                    :href="`tel:0${order.phoneNumber}`"
                    target="_blank"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      0{{ order.phoneNumber }}
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Size</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productSize }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="3" lg="3">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Weight</div>
                  <div v-if="order.weight" class="mt-0 mx-0 px-1">
                    {{ order.weight }} kg
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Quntity</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.quantity }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                v-if="order.description"
                cols="12"
                xs="12"
                sm="12"
                md="10"
                lg="10"
              >
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Description</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.description }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-center" v-show="pages">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="pageNumber"
                  class="my-4"
                  :length="pages"
                  @input="onPageChange"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import { auth, userRef } from "@/firebase";
import { EventBus } from "@/event-bus.js";

export default {
  components: {},

  data() {
    return {
      allOfPrice: 0,
      allOfShippingCost: 0,
      allOfAmountPaid: 0,
      allOfRemainingPaid: 0,
      numberOfAllOfTheOrders: 0,
      unsubUsers: null,
      unsubOrders: null,
      total: 0,
      pages: 0,
      perPage: 10,
      pageNumber: 1,
      orders: [],
      allOrders: [],
      name: "",
      credit: "",
      userSelected: "",
      snackbarInfo: false,
      snackbarText: "",
      snackbarColor: "success",
      unorderedList: [],
      userOrders: [],
      ordersCopy: [],
      products: true,
      userId: "",
      btnColor: "",
      snackbar: false,
      editMode: false,
      newProductName: "",
      indexToEdit: 0,
      linksSelected: "",
      country: "",
      progress: true,
      filterStatus: "",
      indexToConfirim: "",
      userIdConfirmed: "",
      userOrdersRefCollection: "",
      all: false,
      allSuccessOrders: [
        "pending",
        "processing",
        "bought",
        "OnTheWay",
        "InBorder",
        "TurkeyOffice",
        "USAOffice",
        "DeliveredToErbil",
        "OutForDelivery",
        "InDispatch",
        "shipped",
      ],
    };
  },

  props: {
    query: {
      type: String,
      default: "all",
    },
    page: {
      default: 1,
    },
  },

  mounted() {
    EventBus.$on("order-added", () => {
      this.saveOrder();
    });
  },

  computed: {},
  methods: {
    async readData() {
      this.userOrdersRefCollection = [];

      if (this.query === "all") {
        // Split allSuccessOrders into chunks of 10

        const chunks = [];
        for (let i = 0; i < this.allSuccessOrders.length; i += 10) {
          chunks.push(this.allSuccessOrders.slice(i, i + 10));
        }
        // console.log("chunks");
        // console.log(chunks);
        // Create a query for each chunk and combine the results
        chunks.forEach((chunk) => {
          const query = userRef
            .doc(this.userId)
            .collection("orders")
            .orderBy("submit_date", "asc")
            .where("status", "in", chunk);
          this.userOrdersRefCollection.push(query);
        });
        // console.log('userOrdersRefCollection')
        // console.log(this.userOrdersRefCollection)
        this.all = true;
      } else {
        const query = userRef
          .doc(this.userId)
          .collection("orders")
          .where("status", "==", this.query)
          .orderBy("submit_date", "asc");
        this.userOrdersRefCollection.push(query);
        this.all = false;
      }
      this.unsubOrders = this.userOrdersRefCollection.map((query) =>
        query.onSnapshot((docs) => {
          //           console.log('docs')
          // console.log(docs)
          const docsChanges = docs.docChanges();
          this.orders = [];
          this.ordersCopy = [];
          this.total = 0;
          docsChanges.forEach((res) => {
            const pureData = res.doc.data();
            const resType = res.type;
            let deleteItems = null;
            const accountingData = this.accountingModel(pureData);
            if (resType === "added") {
              this.accountingFunction(accountingData, deleteItems);
              this.allOrders.unshift({
                // UNSHIF FOR ADD AT THE BEGINNING
                ...pureData,
                id: res.doc.id,
              });
              this.ordersCopy.unshift({
                // UNSHIF FOR ADD AT THE BEGINNING
                ...pureData,
                id: res.doc.id,
              });
            } else if (resType === "modified") {
              let index = null;
              const list = this.allOrders;
              let modifiedItemPreviousData = null;
              const modifiedItem = { ...pureData, id: res.doc.id };
              this.allOrders = this.allOrders.filter(function(item) {
                if (item.id === res.doc.id) {
                  index = list.indexOf(item);
                  modifiedItemPreviousData = item;
                }
                return item.id !== res.doc.id;
              });
              this.ordersCopy = this.ordersCopy.filter(function(item) {
                return item.id !== res.doc.id;
              });
              this.allOrders.splice(index, 0, modifiedItem);
              this.ordersCopy.splice(index, 0, modifiedItem);
              deleteItems = this.accountingModel(modifiedItemPreviousData);
              this.accountingFunction(accountingData, deleteItems);
            } else if (resType === "removed") {
              this.accountingFunction(deleteItems, accountingData);
              this.allOrders = this.allOrders.filter(function(item) {
                return item.id !== res.doc.id;
              });
              this.ordersCopy = this.ordersCopy.filter(function(item) {
                return item.id !== res.doc.id;
              });
            } else {
              console.log("Unknown");
            }
          });
          // Get size of query
          this.pageNumber = Number(this.page);
          this.total = this.allOrders.length;
          // Round up result
          this.pages = Math.ceil(this.total / this.perPage);
          // Get size of query
          this.pageCheck();
          this.progress = false;
        })
      );
    },
    onPageChange() {
      if (String(this.page) !== String(this.pageNumber)) {
        this.$router.push({
          name: "dashboard",
          params: { query: this.query, page: this.pageNumber },
        });
      }
    },
    pageCheck() {
      const start = this.perPage * (this.pageNumber - 1);
      const end = start + this.perPage;
      this.orders = this.allOrders.slice(start, end);
    },

    accountingModel(data) {
      const dataModel = {
        price: data.price,
        qty: data.quantity,
        shipping: data.shippingCost,
        totalShippingCost: data.totalShippingCost,
        paid: data.amountPaid,
        remaining: data.remainingPaid,
        currency: data.currency,
        rate: data.tryRate,
      };
      return dataModel;
    },

    accountingFunction(data, deleted) {
      if (data) {
        this.numberOfAllOfTheOrders += 1;
        if (data.price && data.currency === "Turkish lira") {
          const calc = (parseFloat(data.price) / parseFloat(data.rate)) * 100;
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(calc.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        } else if (data.price && data.currency) {
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(data.price.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        }
        if (data.shipping) {
          this.allOfShippingCost = Number(
            (
              Number(this.allOfShippingCost.toFixed(2)) +
              Number(data.shipping.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.paid) {
          this.allOfAmountPaid = Number(
            (
              Number(this.allOfAmountPaid.toFixed(2)) +
              Number(data.paid.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.remaining) {
          this.allOfRemainingPaid = Number(
            (
              Number(this.allOfRemainingPaid.toFixed(2)) +
              Number(data.remaining.toFixed(2))
            ).toFixed(2)
          );
        }
      }
      if (deleted) {
        {
          this.numberOfAllOfTheOrders -= 1;
          if (deleted.price && deleted.currency === "Turkish lira") {
            const calc =
              (parseFloat(deleted.price) / parseFloat(deleted.rate)) * 100;
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(calc.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          } else if (deleted.price && deleted.currency) {
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(deleted.price.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          }
          if (deleted.shipping) {
            this.allOfShippingCost = Number(
              (
                Number(this.allOfShippingCost.toFixed(2)) -
                Number(deleted.shipping.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.paid) {
            this.allOfAmountPaid = Number(
              (
                Number(this.allOfAmountPaid.toFixed(2)) -
                Number(deleted.paid.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.remaining) {
            this.allOfRemainingPaid = Number(
              (
                Number(this.allOfRemainingPaid.toFixed(2)) -
                Number(deleted.remaining.toFixed(2))
              ).toFixed(2)
            );
          }
        }
      }
    },

    openDialog() {
      EventBus.$emit("dialog", true);
    },

    filterOrders(status) {
      this.orders = this.ordersCopy;
      this.progress = true;
      this.filterStatus = status;
      if (status === "completed") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "completed";
        });
      } else if (status === "pending") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "pending";
        });
      } else if (status === "failed") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "failed";
        });
      } else if (status === "InBorder") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "InBorder";
        });
      } else if (status === "OutForDelivery") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "OutForDelivery";
        });
      } else if (status === "processing") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "processing";
        });
      } else if (status === "bought") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "bought";
        });
      } else if (status === "canceled") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "canceled";
        });
      } else if (status === "onhold") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "onhold";
        });
      } else if (status === "OnTheWay") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "OnTheWay";
        });
      } else if (status === "TurkeyOffice") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "TurkeyOffice";
        });
      } else if (status === "InDispatch") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "InDispatch";
        });
      } else if (status === "shipped") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "shipped";
        });
      } else if (status === "USAOffice") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "USAOffice";
        });
      } else if (status === "DeliveredToErbil") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "DeliveredToErbil";
        });
      }
      this.progress = false;
    },

    confirimOrder(flag) {
      var index = this.indexToConfirim;
      const currentOrder = this.orders[index];
      const confirimOrderRefDoc = userRef
        .doc(this.userIdConfirmed)
        .collection("orders")
        .doc(currentOrder.id);
      const date = new Date(Date.now());
      if (flag === true) {
        // CONFIRIM STATUS ON DATABASE
        confirimOrderRefDoc
          .set(
            {
              isConfirmed: true,
              userUpdated: "Customer",
              statusUpdated: date,
            },
            { merge: true }
          )
          .then(() => {
            this.snackbarText = `Your order has been successfully confirmed`;
            this.snackbarColor = "success";
            this.snackbarInfo = true;
          })
          .catch((error) => {
            this.snackbarText = error.mesage;
            this.snackbarColor = "error";
            this.snackbarInfo = true;
          });
      } else if (flag === false) {
        // CONFIRIM STATUS ON DATABASE
        confirimOrderRefDoc
          .set(
            {
              status: "canceled",
              isConfirmed: false,
              userUpdated: "Customer",
              statusUpdated: date,
            },
            { merge: true }
          )
          .then(() => {
            this.snackbarText = `Your order has been canceled, you can find that in 'canceled'`;
            this.snackbarColor = "success";
            this.snackbarInfo = true;
          })
          .catch((error) => {
            this.snackbarText = error.mesage;
            this.snackbarColor = "error";
            this.snackbarInfo = true;
          });
      }
    },

    changeStatus(index, userId) {
      const currentOrder = this.orders[index];
      if (
        currentOrder.status === "pending" ||
        currentOrder.status === "canceled"
      ) {
        const changeOrderStatusDocRef = userRef
          .doc(userId)
          .collection("orders")
          .doc(currentOrder.id);

        // LOGIC FOR STATUS UPDATE
        switch (currentOrder.status) {
          case "pending":
            var newStatus = "canceled";
            var btnColor = "cancel";
            break;
          case "canceled":
            newStatus = "pending";
            btnColor = "info";
            break;
          default:
            break;
        }
        const date = new Date(Date.now());
        // UPDATE DATABASE
        changeOrderStatusDocRef.set(
          {
            status: newStatus,
            userUpdated: "Customer",
            statusUpdated: date,
          },
          { merge: true }
        );

        // UPDATE LOCAL DATA
        currentOrder.status = newStatus;
        this.btnColor = btnColor;
      } else {
        this.snackbarText =
          "Sorry, you can only change the status if it is 'pending'";
        this.snackbarColor = "error";
        this.snackbarInfo = true;
      }
    },

    updateProductName(index, userId) {
      const currentOrder = this.orders[index];
      const updateOrderRefDoc = userRef
        .doc(userId)
        .collection("orders")
        .doc(currentOrder.id);

      const date = new Date(Date.now());

      updateOrderRefDoc.set(
        {
          product_name: this.newProductName,
          userUpdated: "Customer",
          statusUpdated: date,
        },
        { merge: true }
      );

      // UPDATE LOCAL DATA
      currentOrder.product_name = this.newProductName;

      this.editMode = false;
    },
  },

  async created() {
    const userAuth = auth;
    await userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userId = user.uid;
        this.readData();
      }
    });
    // GET DATA
    this.progress = false;
  },

  beforeDestroy() {
    if (this.unsubUsers) {
      this.unsubUsers();
      this.unsubUsers = null;
    }
    if (this.unsubOrders) {
      // this.unsubOrders();
      this.unsubOrders.forEach((unsub) => unsub());
      this.unsubOrders = null;
    }
  },
  watch: {
    async $route(to, from) {
      // react to route changes...
      if (to.params.query === from.params.query) {
        this.pageCheck();
      } else {
        if (this.unsubUsers) {
          this.unsubUsers();
        }
        if (this.unsubOrders) {
          // this.unsubOrders();
          this.unsubOrders.forEach((unsub) => unsub());
        }
        this.allOrders = [];
        this.ordersCopy = [];
        this.orders = [];
        this.allOfPrice = 0;
        this.allOfShippingCost = 0;
        this.allOfAmountPaid = 0;
        this.allOfRemainingPaid = 0;
        this.numberOfAllOfTheOrders = 0;
        this.readData();
      }
    },
  },
};
</script>

<style>
.order.completed {
  border-left: 4px solid #00897b;
}
.order.failed {
  border-left: 4px solid #f83e70;
}
.order.processing {
  border-left: 4px solid #ab47bc;
}
.order.pending {
  border-left: 4px solid #ffa726;
}
.order.canceled {
  border-left: 4px solid #8d6e63;
}
.order.OnTheWay {
  border-left: 4px solid #006064;
}
.order.DeliveredToErbil {
  border-left: 4px solid #00c853;
}
.order.TurkeyOffice {
  border-left: 4px solid #e30a17;
}
.order.InDispatch {
  border-left: 4px solid #54618c;
}
.order.shipped {
  border-left: 4px solid #6be4ff;
}
.order.USAOffice {
  border-left: 4px solid #3c3b6e;
}
.order.onhold {
  border-left: 4px solid #bdbdbd;
}

.theme--light.v-btn:not(.v-btn--icon).completed {
  background-color: #00897b;
}

.theme--light.v-btn:not(.v-btn--icon).failed {
  background-color: #f83e70;
}

.theme--light.v-btn:not(.v-btn--icon).processing {
  background-color: #ab47bc;
}

.theme--light.v-btn:not(.v-btn--icon).pending {
  background-color: #ffaa2c;
}

.theme--light.v-btn:not(.v-btn--icon).InDispatch {
  background-color: #54618c;
}
.theme--light.v-btn:not(.v-btn--icon).shipped {
  background-color: #6be4ff;
}
.theme--light.v-btn:not(.v-btn--icon).canceled {
  background-color: #8d6e63;
}

.theme--light.v-btn:not(.v-btn--icon).DeliveredToErbil {
  background-color: #00c853;
}

.theme--light.v-btn:not(.v-btn--icon).OnTheWay {
  background-color: #006064;
}
.theme--light.v-btn:not(.v-btn--icon).OutForDelivery {
  background-color: #1f6637;
}

.theme--light.v-btn:not(.v-btn--icon).TurkeyOffice {
  background-color: #e30a17;
}
.theme--light.v-btn:not(.v-btn--icon).USAOffice {
  background-color: #3c3b6e;
}

.theme--light.v-btn:not(.v-btn--icon).onhold {
  background-color: #bdbdbd;
}

.theme--light.v-btn.completed {
  color: #00897b;
}

.theme--light.v-btn.failed {
  color: #f83e70;
}

.theme--light.v-btn.processing {
  color: #ab47bc;
}

.theme--light.v-btn.pending {
  color: #ffaa2c;
}
.theme--light.v-btn.InDispatch {
  color: #54618c;
}
.theme--light.v-btn.shipped {
  color: #6be4ff;
}

.theme--light.v-btn.canceled {
  color: #8d6e63;
}
.theme--light.v-btn.DeliveredToErbil {
  color: #00c853;
}
.theme--light.v-btn.OutForDelivery {
  color: #2f7c4f;
}

.theme--light.v-btn.OnTheWay {
  color: #006064;
}

.theme--light.v-btn.TurkeyOffice {
  color: #e30a17;
}
.theme--light.v-btn.USAOffice {
  color: #3c3b6e;
}

.theme--light.v-btn.onhold {
  color: #bdbdbd;
}

.handle {
  cursor: move;
}

.routerLink {
  text-decoration: none;
}
</style>
