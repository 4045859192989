<template>
  <div class="OrderCheck">
    <v-banner
      icon="mdi-order-bool-ascending-variant"
      single-line
      lass="subtitle-1 grey--text"
      >Order Check</v-banner
    >
    <div
      class="OrderCheck mt-5 mb-7"
      v-if="permission === 'Admin' || permission === country"
    >
      <v-row justify="center">
        <div class="text-center ma-2">
          <v-snackbar
            v-model="snackbarInfo"
            :timeout="4000"
            :color="snackbarColor"
          >
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbarInfo = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <div
          align="center"
          justify="center"
          id="printArea"
          class="d-none headline"
        >
          <!-- @hooshyar Where Qr code being generated, and there is a size i think for the height -->
          <qrcode-vue :value="value" size="200" level="H"></qrcode-vue>
          <p class="displayName">{{ displayName }}</p>
          <p class="displayName">0{{ phoneNumber }}</p>
          <p>zubene.co</p>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="mb-2">
            <v-card-title>
              <v-row align="center" justify="space-around">
                <router-link
                  class="routerLink"
                  :to="{
                    name: 'userorders',
                    params: {
                      id: uid,
                      query: 'all',
                      page: '1',
                    },
                  }"
                >
                  <v-btn
                    outlined
                    dark
                    rounded
                    color="primary"
                    class="headline font-weight-light ma-2"
                  >
                    {{ displayName }}<v-icon right>mdi-link</v-icon>
                  </v-btn>
                </router-link>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="4">
                    <v-row align="center" justify="center">
                      <qrcode-vue
                        :value="value"
                        :size="size"
                        level="H"
                      ></qrcode-vue>
                    </v-row>
                  </v-col>
                  <v-row>
                    <v-col class="no-print">
                      <pinch-zoom v-bind:autoZoomOut="true" double-tap>
                        <v-img
                          :src="
                            productImageURL
                              ? productImageURL
                              : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUploa-PlaceHolder.png?alt=media&token=fb193be8-59f6-47cc-b546-cfc951ba64a5'
                          "
                          height="200"
                          width="200"
                          contain
                          class="grey lighten-4"
                          @click="selectFile"
                        >
                          <template v-slot:placeholder>
                            <v-row class="ma-0" align="center" justify="center">
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template> </v-img
                      ></pinch-zoom>
                    </v-col>
                    <input
                      type="file"
                      ref="uploadInput"
                      id="files"
                      accept="image/*"
                      :multiple="false"
                      @change="detectFiles($event)"
                    />
                  </v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-row align="center" justify="center">
                      <v-avatar v-if="photoUrl" size="200" class="avatar">
                        <img :src="photoUrl" class="userAvatar" />
                      </v-avatar>
                    </v-row>
                  </v-col>

                  <v-col class="d-print-table-row" cols="12" sm="12" md="6">
                    <v-text-field
                      label="Address"
                      v-model="address"
                      readonly
                      background-color="grey lighten-5"
                      rounded
                      filled
                      prepend-icon="mdi-map-marker"
                    ></v-text-field>
                  </v-col>

                  <v-col class="no-print" cols="12" sm="12" md="6">
                    <v-select
                      prepend-icon="mdi-flag"
                      :items="countries"
                      v-model="country"
                      label="Product origin country"
                      rounded
                      filled
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Email"
                      v-model="email"
                      readonly
                      background-color="grey lighten-5"
                      rounded
                      filled
                      prepend-icon="mdi-email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Phone Number"
                      v-model="phoneNumber"
                      background-color="grey lighten-5"
                      rounded
                      filled
                      prepend-icon="mdi-phone"
                      append-outer-icon="mdi-whatsapp"
                      @click:append-outer="
                        openWhatsapp(`https://wa.me/964${phoneNumber}`)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-palette"
                      v-model="productColor"
                      label="Color"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-size-xs"
                      v-model="productSize"
                      label="Size"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="4" md="4">
                    <div class="caption px-6">Submit Date</div>
                    <v-icon left> mdi-calendar-clock </v-icon>
                    <v-chip color="grey lighten-5">
                      <span v-if="submit_date.seconds" class="overline px-8">
                        {{ submit_date.seconds | moment("from", "now") }}
                      </span>
                    </v-chip>
                  </v-col>
                  <v-col
                    v-if="statusUpdated"
                    class="no-print"
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <div class="caption px-6">Status Updated</div>
                    <v-icon left> mdi-calendar-refresh </v-icon>
                    <v-chip color="grey lighten-5">
                      <span v-if="statusUpdated.seconds" class="overline px-8">
                        {{ statusUpdated.seconds | moment("from", "now") }}
                      </span>
                    </v-chip>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      prepend-icon="mdi-account-clock"
                      v-model="userUpdated"
                      readonly
                      background-color="grey lighten-5"
                      rounded
                      filled
                      label="User Upated"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="12" md="12">
                    <v-text-field
                      label="Product Name"
                      v-model="product_name"
                      prepend-icon="mdi-rename-box"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="6" md="6">
                    <v-select
                      prepend-icon="mdi-list-status"
                      :items="statuses"
                      v-model="status"
                      label="Status"
                      rounded
                      filled
                    ></v-select>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-web"
                      v-model="website"
                      label="Website Name"
                      hint="The name of the website you want us to buy a product for you"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      prepend-icon="mdi-link-box"
                      label="URL"
                      hint="The product URL you want to buy for you"
                      persistent-hint
                      required
                      v-model="url"
                      background-color="grey lighten-5"
                      append-outer-icon="mdi-open-in-new"
                      @click:append-outer="openInANewTab(url)"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="6" sm="3" md="3" lg="3">
                    <v-text-field
                      prepend-icon="mdi-cash-usd"
                      v-model.number="price"
                      label="Price"
                      :rules="priceRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="6" sm="3" md="3" lg="3">
                    <v-select
                      :items="currencies"
                      v-model="currency"
                      label="Currency"
                      required
                      :rules="[rules.required]"
                      rounded
                      filled
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="permission == 'Admin'"
                    class="no-print"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      prepend-icon="mdi-cash-multiple"
                      v-model.number="tryRate"
                      label="Turkish Lira Rate 100$"
                      readonly
                      background-color="grey lighten-5"
                      rounded
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="permission == 'Admin'"
                    class="no-print"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      prepend-icon="mdi-truck"
                      v-model.number="shippingCost"
                      label="Shipping Cost"
                      append-icon="mdi-currency-usd"
                      :rules="[rules.required, rules.number]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="permission == 'Admin'"
                    class="no-print"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      prepend-icon="mdi-truck-check"
                      v-model.number="totalShippingCost"
                      label="Total Shipping Cost"
                      append-icon="mdi-currency-usd"
                      readonly
                      rounded
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="permission == 'Admin'"
                    class="no-print"
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      prepend-icon="mdi-account-cash"
                      v-model.number="amountPaid"
                      label="The amount paid"
                      append-icon="mdi-currency-usd"
                      :rules="priceRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="6" md="6">
                    <v-slider
                      v-model.number="quantity"
                      label="Qty"
                      prepend-icon="mdi-plus-minus-box"
                      thumb-label="always"
                      min="1"
                      max="100"
                    >
                      <template v-slot:append>
                        <v-text-field
                          v-model.number="quantity"
                          class="mt-0 pt-0"
                          single-line
                          style="width: 50px"
                          min="1"
                          max="100"
                        ></v-text-field> </template
                    ></v-slider>
                  </v-col>
                  <v-col
                    v-if="permission == 'Admin'"
                    class="no-print"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      prepend-icon="mdi-calculator"
                      v-model.number="remainingAmountCalc.remainingPaid"
                      label="The remaining amount"
                      append-icon="mdi-currency-usd"
                      readonly
                      background-color="grey lighten-5"
                      :hint="`$ (${price} ${currency} x ${quantity}) + (${shippingCost} x ${quantity}) - ${amountPaid} = $ ${remainingAmountCalc.remainingPaid}`"
                      persistent-hint
                      rounded
                      filled
                      :rules="priceRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="3">
                    <v-select
                      :prepend-icon="
                        isConfirmed ? 'mdi-check-circle' : 'mdi-close-circle'
                      "
                      :items="confirmation"
                      v-model="isConfirmed"
                      label="Confirmation"
                      rounded
                      filled
                    ></v-select>
                  </v-col>
                  <v-col class="no-print" cols="12" sm="3">
                    <v-text-field
                      prepend-icon="mdi-format-list-bulleted-type"
                      label="Type of product"
                      v-model="product"
                      multiple
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      prepend-icon="mdi-barcode"
                      v-model="productCode"
                      label="Product Code"
                    ></v-text-field>
                  </v-col>
                  <v-col class="no-print" cols="12">
                    <v-textarea
                      prepend-icon="mdi-information"
                      label="Description"
                      v-model="description"
                      auto-grow
                      rows="1"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col class="no-print" cols="12">
                    <v-textarea
                      prepend-icon="mdi-message-text"
                      label="Message"
                      v-model="message"
                      auto-grow
                      rows="1"
                      hint="This message is displayed to the customer"
                      persistent-hint
                    >
                    </v-textarea>
                  </v-col>
                  <v-col class="no-print" cols="12">
                    <v-textarea
                      prepend-icon="mdi-message-text-lock"
                      label="Notes"
                      v-model="notes"
                      auto-grow
                      rows="1"
                      hint="This message is for supervisors and co-workers only"
                      persistent-hint
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
              <small class="no-print">*indicates required field</small>
            </v-card-text>
            <v-card-actions class="mx-2 py-4">
              <v-btn color="error" @click="reset">
                Reset
                <v-icon right>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :loading="printingLoading"
                v-print="printObj"
                color="primary"
              >
                Print
                <v-icon right>mdi-printer</v-icon>
              </v-btn>
              <v-btn
                :disabled="!valid"
                color="primary "
                @click="edit"
                :loading="loading"
              >
                Save Edit
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-row>
    </div>
    <div v-else>
      <!-- Progress circular -->
      <div class="mt-10 pt-10" align="center" justify="center">
        <v-progress-circular
          :size="100"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <!-- End Progress circular -->
      <div align="center" justify="center">
        <div class="subtitle-1 grey--text font-weight-light">
          <a href="/">Click here to back 'home'</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import QrcodeVue from "qrcode.vue";
import { userRef, auth, storage } from "@/firebase";
export default {
  components: {
    QrcodeVue,
  },

  data: () => ({
    printObj: {
      id: "printArea",
      popTitle: "Zubene | skip the store",
    },
    permission: "",
    printingLoading: false,
    output: null,
    remainingPaid: 0,
    amountPaid: 0,
    paidStatus: "",
    shippingCost: 0,
    totalShippingCost: 0,
    tryRate: 0,
    value: "",
    size: 200,
    displayName: "",
    adminDisplayName: "",
    phoneNumber: "",
    email: "",
    photoUrl: "",
    address: "",
    submit_date: "",
    statusUpdated: "",
    userUpdated: "",
    status: "",
    message: "",
    notes: "",
    snackbarColor: "",
    snackbarText: "",
    snackbarInfo: false,
    user: "",
    userId: null,
    loading: false,
    dialog: false,
    valid: true,
    country: "",
    productColor: "",
    productSize: "",
    productImageURL: "",
    priceRules: [
      (v) =>
        Number.isInteger(v) || Number.isFinite(v) || !v || "Must be number",
    ],
    rules: {
      required: (value) => !!value || "This field cannot be empty.",
      number: (v) =>
        Number.isInteger(v) || Number.isFinite(v) || !v || "Must be number",
    },
    product_name: "",
    websites: "",
    statuses: [
      "pending",
      "processing",
      "bought",
      "OnTheWay",
      "TurkeyOffice",
      "InDispatch",
      "shipped",
      "InBorder", 
      "OutForDelivery", 
      "USAOffice",
      "DeliveredToErbil",
      "completed",
      "onhold",
      "failed",
      "canceled",
    ],
    website: "",
    product: "",
    url: "",
    price: "",
    currencies: ["Turkish lira", "US Dollar"],
    confirmation: [true, false, "NotSet"],
    countries: ["Turkey", "USA"],
    currency: "",
    productCode: "",
    quantity: "",
    products: "",
    description: "",
    isConfirmed: "",
  }),
  props: {
    id: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
  },
  methods: {
    openInANewTab(url) {
      window.open(url, "_blank");
    },

    openWhatsapp(link) {
      window.open(link, "_blank");
    },

    // Open choose file
    selectFile() {
      this.$refs.uploadInput.click();
    },

    // Configure to save
    detectFiles(e) {
      const file = e.target.files[0];
      if (!file) {
        e.preventDefault();
        this.loading = false;
        return;
      }
      if (file.size > 2048 * 1024) {
        e.preventDefault();
        alert("File too big (> 2MB)");
        this.loading = false;
        return;
      }
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.selectedFile = fileList[x];
        this.productImageURL = URL.createObjectURL(file);
      });
    },

    // Save file in Firebase Storage
    async upload(file) {
      // Create a root reference
      var storageRef = storage;

      // Create a reference to 'images/mountains.jpg'
      var productImagesRef = await storageRef.child(
        "images/" + this.uid + "/productImages/" + this.id
      );
      // Upload
      await productImagesRef.put(file).then(async () => {
        await productImagesRef
          .getDownloadURL()
          .then((url) => {
            this.productImageURL = url;
            return true;
          })
          .catch((error) => {
            this.error = error.message;
            return false;
          });
      });
    },

    // Edit button fuction
    async edit() {
      if (this.$refs.form.validate()) {
        const date = new Date(Date.now());
        this.loading = true;
        if (await this.selectedFile) {
          await this.upload(this.selectedFile);
        }
        const order = {
          product_name: this.product_name,
          website: this.website,
          price: this.price,
          currency: this.currency,
          displayName: this.displayName,
          products: this.product,
          description: this.description,
          status: this.status,
          quantity: this.quantity,
          productCode: this.productCode,
          message: this.message,
          notes: this.notes,
          amountPaid: this.amountPaid,
          shippingCost: this.shippingCost,
          totalShippingCost: this.totalShippingCost,
          remainingPaid: this.remainingPaid,
          statusUpdated: date,
          url: this.url,
          userUpdated: this.adminDisplayName,
          productImageURL: this.productImageURL,
          productColor: this.productColor,
          productSize: this.productSize,
          country: this.country,
          isConfirmed: this.isConfirmed,
        };
        const updateUserRefOrderDoc = userRef
          .doc(this.uid)
          .collection("orders")
          .doc(this.id);

        await updateUserRefOrderDoc
          .update(order)
          .then(() => {
            this.snackbarText = "Product edit was successfully";
            this.snackbarColor = "success";
            this.snackbarInfo = true;
          })
          .catch((error) => {
            this.snackbarText = error.message;
            this.snackbarColor = "error";
            this.snackbarInfo = true;
          });
        this.loading = false;
      }
    },
    reset() {
      this.$router.go(0);
    },
  },

  async created() {
    // Get user Information
    const userAuth = auth;
    userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userId = user.uid;
        this.adminDisplayName = user.displayName ? user.displayName : "";
        const userAuthClaims = auth;
        userAuthClaims.currentUser.getIdTokenResult().then(({ claims }) => {
          if (claims.admin) {
            this.permission = "Admin";
          } else if (claims.usa) {
            this.permission = "USA";
          } else if (claims.turkey) {
            this.permission = "Turkey";
          }
        });
      }
    });

    // Save date for QR Code
    this.value = "https://dash.zubene.co/" + this.$route.fullPath;

    // GET DATA
    const userRefOrdersDoc = userRef
      .doc(this.uid)
      .collection("orders")
      .doc(this.id);

    // Get date from firestore and set to var
    await userRefOrdersDoc
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          this.amountPaid = data.amountPaid ? data.amountPaid : 0;
          this.remainingPaid = data.remainingPaid ? data.remainingPaid : 0;
          this.shippingCost = data.shippingCost ? data.shippingCost : 0;
          this.totalShippingCost = data.totalShippingCost
            ? data.totalShippingCost
            : 0;
          this.tryRate = data.tryRate ? data.tryRate : 0;
          this.product_name = data.product_name;
          this.displayName = data.displayName;
          this.phoneNumber = data.phoneNumber;
          this.photoUrl = data.photoUrl;
          this.address = data.address;
          this.email = data.email;
          this.website = data.website;
          this.url = data.url;
          this.price = data.price;
          this.products = data.products;
          this.currency = data.currency;
          this.quantity = data.quantity;
          this.productCode = data.productCode;
          this.description = data.description;
          this.submit_date = data.submit_date;
          this.status = data.status;
          this.statusUpdated = data.statusUpdated
            ? data.statusUpdated
            : data.submit_date;
          this.message = data.message;
          this.notes = data.notes;
          this.country = data.country;
          this.productColor = data.productColor;
          this.productSize = data.productSize;
          this.productImageURL = data.productImageURL;
          this.isConfirmed = data.isConfirmed;
          this.userUpdated = data.userUpdated ? data.userUpdated : "Customer";
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  },
  computed: {
    // That function depened input calculate remaining
    remainingAmountCalc: {
      //getter
      get: function () {
        let exchange = this.price;
        if (this.currency === "Turkish lira") {
          exchange = (exchange / this.tryRate) * 100;
        }
        const totalShippingCost =
          parseFloat(this.shippingCost) * parseFloat(this.quantity);
        const calc = Number(
          parseFloat(exchange) * parseFloat(this.quantity) +
            parseFloat(totalShippingCost) -
            parseFloat(this.amountPaid)
        );
        const returnVal = {
          remainingPaid: Number(calc.toFixed(2)),
          totalShippingCost: Number(totalShippingCost.toFixed(2)),
        };
        return returnVal;
      },
    },
  },

  // Check remaining var and if had changAQed will update reamaingPaid var
  watch: {
    remainingAmountCalc: {
      handler: function (val) {
        this.totalShippingCost = val.totalShippingCost;
        this.remainingPaid = val.remainingPaid;
      },
    },
  },
};
</script>

<style>
.userAvatar {
  transition: opacity 0.2s ease-in-out;
}

.userAvatar:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}

@media print {
  @page {
    size: 5.5in 8.5in; /* auto is the initial value */
    margin-top: 20mm;
    background-color: red;
    margin-bottom: 0mm; /* this affects the margin in the printer settings */
  }
  p {
    padding: 5px;
  }
  html {
    background-color: #ffffff;
    margin: 0px; /* this affects the margin on the html before sending to printer */
  }
  .no-print {
    display: none !important;
  }
  body {
    font-family: verdana, arial, sans-serif;
    overflow: auto;
    height: auto;
    border: solid 1px;
    padding: 10px;
    margin: 10mm 15mm 10mm 15mm; /* margin you want for the content*/
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
  .displayName{
    font-size: 26px;
    font-weight: 600;
  }
}
@media screen {
  .no-screen {
    display: none !important;
  }
}
</style>