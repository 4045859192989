<!-- eslint-disable no-undef -->
<template>
  <div class="userOrders">
    <v-banner
      icon="mdi-table-account"
      single-line
      class="subtitle-1 text-capitalize grey--text"
      >Orders For : {{ displayNameUserOrder }}</v-banner
    >
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbarInfo" :timeout="4000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbarInfo = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </div>
    <v-snackbar v-model="snackbar" top color="warning" flat>
      <span>Are you sure?</span>
      <v-btn text color="white" @click="snackbar = false">Yes</v-btn>
      <v-btn text color="white" @click="snackbar = false">No</v-btn>
    </v-snackbar>
    <v-container fluid class="px-0">
      <v-row dense>
        <v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
                >mdi-cart</v-icon
              >
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >All orders</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >{{ numberOfAllOfTheOrders }}</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark>
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
                >mdi-tag-multiple</v-icon
              >
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Price</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >$ {{ allOfPrice }}</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
                >mdi-truck-fast</v-icon
              >
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Cargo</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $
              {{
                (
                  Number(allOfAmountPaid) +
                  Number(allOfRemainingPaid) -
                  Number(allOfPrice)
                ).toFixed(2)
              }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
                >mdi-wallet</v-icon
              >
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Cost</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
            >
              $
              {{
                (Number(allOfAmountPaid) + Number(allOfRemainingPaid)).toFixed(
                  2
                )
              }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
                >mdi-cash-register</v-icon
              >
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Paid</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >$ {{ allOfAmountPaid }}</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="4" xs="4" sm="4" md="2" lg="2">
          <v-card class="mx-auto" color="accounting" dark max-width="400">
            <v-card-title class="px-2">
              <v-icon
                :small="$vuetify.breakpoint.xs"
                :large="$vuetify.breakpoint.smAndUp"
                left
                >mdi-chart-arc</v-icon
              >
              <span
                class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                >Total Unpaid</span
              >
            </v-card-title>
            <v-card-text
              class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >$ {{ allOfRemainingPaid }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="center">
        <v-col class="no-print" cols="4" sm="3" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                rounded
                color="gray"
                @click="dialogAllOrderUser = true"
                class="pa-0 ma-0"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>

            <span>Print</span>
          </v-tooltip>
        </v-col>
        <v-col class="no-print" cols="8" sm="6" md="6">
          <v-select
            prepend-icon="mdi-list-status"
            :items="statuses"
            v-model="status"
            label="Status"
            rounded
            @change="readData"
            filled
          ></v-select>
        </v-col>
      </v-row>
      <!-- End Filter list -->

      <!-- Empty Oerders Complete -->
      <div align="center" justify="center" v-if="allOrders.length == 0">
        <p class="display-1 font-weight-light grey--text">Nothing here!</p>
        <p class="Caption font-weight-thin text--secondary grey--text">
          No Orders completed have been recorded for this country
        </p>
      </div>
      <!-- End Empty Orders Completed -->

      <!--Orders Complete-->
      <v-expansion-panels class="my-3 no-print">
        <v-expansion-panel
          text
          v-for="(order, index) in allOrders"
          :key="order.id"
          hide-actions
        >
          <v-expansion-panel-header :class="` mx-0 order ${order.status}`">
            <v-row justify="center" align="center" class="spacer" no-gutters>
              <v-col cols="6" xs="6" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.productImageURL
                        ? order.productImageURL
                        : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUpload-PlaceHolder-zubene.png?alt=media&token=bdef7f9d-471c-4e74-96f3-f53353369176'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="4" lg="4">
                <v-card tile flat>
                  <div class="caption grey--text">Product Name</div>
                  <div
                    class="mt-0 mb-0"
                    v-if="!editMode || index != indexToEdit"
                  >
                    {{ order.product_name }}
                  </div>

                  <v-form
                    @submit.prevent="updateProductName(index, order.userId)"
                  >
                    <v-text-field
                      autofocus
                      :color="`${order.status}s`"
                      v-model="newProductName"
                      v-if="editMode && indexToEdit == index"
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </v-form>
                </v-card>
              </v-col>

              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-2 grey--text">Country</div>
                  <v-btn
                    text
                    small
                    rounded
                    :class="`${order.status} px-2 py-0 white--text`"
                    >{{ order.country }}</v-btn
                  >
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="1" lg="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="
                        order.isDone != 'undefined' || order.isDone != null
                          ? order.isDone
                          : false
                      "
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="green"
                      @click="
                        dialogPrintSingleOrder = true;
                        printOrder(order);
                      "
                      class="pa-0 ma-0"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>

                  <span>Print</span>
                </v-tooltip>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption grey--text">Submit Date</div>
                  <div class="mt-0 mx-0">
                    {{ order.submit_date.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>

              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="pt-3">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                      >{{ order.status }}</v-btn
                    >
                  </div>
                </v-card>
              </v-col>

              <v-col cols="4" xs="4" sm="3" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Total Price $</div>
                  <div
                    v-if="order.currency === 'Turkish lira'"
                    class="mt-0 mx-0 px-1"
                  >
                    $
                    {{ order.price / order.tryRate + order.totalShippingCost }}
                  </div>
                  <div
                    v-else-if="order.currency === 'US Dollar'"
                    class="mt-0 mx-0 px-1"
                  >
                    $ {{ order.price + order.totalShippingCost }}
                  </div>
                  <div v-else class="mt-0 mx-0 px-1 font-weight-light">
                    Not Set
                  </div>
                </v-card>
              </v-col>

              <v-col cols="4" xs="3" sm="2" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Paid</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.amountPaid }}</div>
                </v-card>
              </v-col>

              <v-col cols="4" xs="3" sm="3" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Remaining</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.remainingPaid }}</div>
                </v-card>
              </v-col>

              <v-col
                v-if="order.message"
                cols="12"
                xs="12"
                sm="6"
                md="12"
                lg="12"
              >
                <v-alert
                  class="px-3 py-1 mt-2"
                  color="blue"
                  outlined
                  text
                  icon="mdi-message-text"
                  dense
                  border="left"
                  type="info"
                >
                  <div class="caption">Admin Message</div>
                  <div class="py-2">{{ order.message }}</div>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <!-- Orders more details -->
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-row align="center">
              <v-col cols="4" xs="4" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.photoUrl
                        ? order.photoUrl
                        : 'https://via.placeholder.com/50/?text=zubene'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="4" xs="4" sm="6" md="1" lg="1">
                <v-card tile flat>
                  <a class="routerLink" :href="order.url" target="_blank">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                      >Link</v-btn
                    >
                  </a>
                </v-card>
              </v-col>
              <v-col cols="4" xs="4" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Color</div>
                  <div class="mt-0 mx-0 px-1">{{ order.productColor }}</div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <a
                    class="routerLink"
                    :href="`tel:0${order.phoneNumber}`"
                    target="_blank"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                      >0{{ order.phoneNumber }}</v-btn
                    >
                  </a>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Size</div>
                  <div class="mt-0 mx-0 px-1">{{ order.productSize }}</div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="3" lg="3">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Weight</div>
                  <div v-if="order.weight" class="mt-0 mx-0 px-1">
                    {{ order.weight }} kg
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Quntity</div>
                  <div class="mt-0 mx-0 px-1">{{ order.quantity }}</div>
                </v-card>
              </v-col>
              <v-col
                v-if="order.description"
                cols="12"
                xs="12"
                sm="12"
                md="10"
                lg="10"
              >
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Description</div>
                  <div class="mt-0 mx-0 px-1">{{ order.description }}</div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template id="printArea" class="d-none headline">
        <v-container>
          <v-row justify="center">
            <v-dialog
              v-model="dialogPrintSingleOrder"
              persistent
              max-width="600px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Order For Print</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Display Name :"
                          v-model="displayNamePrint"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Phone Number :"
                          v-model="phoneNumber"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Product Code :"
                          v-model="productCode"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Total Price $ :"
                          v-model="totalPrice"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Total Paid $ :"
                          v-model="totalPaid"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Total Remaining $ :"
                          v-model="totalRemaining"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Status Order :"
                          v-model="statusOrder"
                          :disabled="disabledPrintItem"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*thanks for your shopping</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogPrintSingleOrder = false"
                    >Close</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    :loading="printingLoading"
                    v-print="printSingleOrderObj"
                    @click="dialog = false"
                    >Print</v-btn
                  >
                </v-card-actions>
                <!--For Print  Order-->
                <div class="for-print" id="printAreaSingleOrder">
                  <div
                    style="text-align: center!important; margin-bottom: 10px;"
                    class="orange"
                  >
                    <span class="green">
                      | Custome Name :
                      <small>
                        <b> {{ displayNameUserOrder }}</b>
                      </small>
                      | </span
                    >*
                    <span class="green">
                      | Phone Number :
                      <small>
                        <b> {{ phoneNumber }}</b>
                      </small>
                      |
                    </span>
                  </div>
                  <hr class="dotted" />
                  <div>
                    <span>
                      <center>
                        <img
                          style="
                           
                              margin-top:10px
                              border: 1px solid #ddd;
                              border-radius: 4px;
                              padding: 5px;
                              width: 150px;
                                "
                          alt="ZUBENE"
                          :src="
                            productImageURL
                              ? productImageURL
                              : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUpload-PlaceHolder-zubene.png?alt=media&token=bdef7f9d-471c-4e74-96f3-f53353369176'
                          "
                        />
                      </center>
                    </span>
                  </div>
                  <hr class="dotted" />
                  <div>
                    <span>
                      <b>Product Code :</b>
                      {{ productCode != "" ? productCode : "N/A" }}
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Total Price :</b>
                      {{ totalPrice != "" ? totalPrice : "0" }} $
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Total Paid :</b>
                      {{ totalPaid != "" ? totalPaid : "0" }} $
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Total Remaining :</b>
                      {{ totalRemaining != "" ? totalRemaining : "0" }} $
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Status Order :</b>
                      {{ statusOrder != "" ? statusOrder : "N/A" }}
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Status Update :</b>
                      {{ statusUpdated.seconds | moment("from", "now") }}
                    </span>
                  </div>
                </div>
                <!--End For Print All Orders-->
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </template>
      <!--All Order Reportt UI-->
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialogAllOrderUser" persistent width="800px">
            <div>
              <v-card>
                <v-card-title>
                  <span class="text-h5"
                    >{{ displayNameUserOrder }} -- {{ status }}</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-expansion-panels class="my-3 no-print">
                    <v-expansion-panel
                      text
                      v-for="(order, index) in allOrderItem"
                      :key="order.id"
                      hide-actions
                    >
                      <v-expansion-panel-header
                        :class="` mx-0 order ${order.status}`"
                        hide-actions
                      >
                        <v-row
                          justify="center"
                          align="center"
                          class="spacer"
                          no-gutters
                        >
                          <v-col cols="6" xs="6" sm="6" md="4" lg="1">
                            <v-avatar size="50px">
                              <img
                                alt="ZUBENE"
                                :src="
                                  order.productImageURL
                                    ? order.productImageURL
                                    : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUpload-PlaceHolder-zubene.png?alt=media&token=bdef7f9d-471c-4e74-96f3-f53353369176'
                                "
                              />
                            </v-avatar>
                          </v-col>

                          <v-col cols="6" xs="6" sm="6" md="4" lg="2">
                            <v-card tile flat>
                              <div class="caption grey--text row-item">
                                Product Name
                              </div>
                              <div
                                class="mt-0 mb-0 "
                                v-if="!editMode || index != indexToEdit"
                              >
                                {{ order.product_name }}
                              </div>
                            </v-card>
                          </v-col>

                          <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                            <v-card tile flat>
                              <div class="caption px-2 grey--text">Country</div>
                              <v-btn
                                text
                                small
                                rounded
                                :class="`${order.status} px-2 py-0 white--text`"
                                >{{ order.country }}</v-btn
                              >
                            </v-card>
                          </v-col>

                          <v-col cols="6" xs="6" sm="6" md="2" lg="2">
                            <v-card tile flat>
                              <div class="caption grey--text">Date</div>
                              <div class="mt-0 mx-0">
                                {{
                                  order.statusUpdated.seconds
                                    | moment("from", "now")
                                }}
                              </div>
                            </v-card>
                          </v-col>

                          <v-col cols="4" xs="4" sm="3" md="2" lg="2">
                            <v-card tile flat>
                              <div class="caption px-1 grey--text">
                                Total Price $
                              </div>
                              <div
                                v-if="order.currency === 'Turkish lira'"
                                class="mt-0 mx-0 px-1"
                              >
                                $
                                {{
                                  (
                                    order.price / order.tryRate +
                                    order.totalShippingCost
                                  ).toFixed(2)
                                }}
                              </div>
                              <div
                                v-else-if="order.currency === 'US Dollar'"
                                class="mt-0 mx-0 px-1"
                              >
                                $
                                {{
                                  (
                                    order.price + order.totalShippingCost
                                  ).toFixed(2)
                                }}
                              </div>
                              <div
                                v-else
                                class="mt-0 mx-0 px-1 font-weight-light"
                              >
                                Not Set
                              </div>
                            </v-card>
                          </v-col>

                          <v-col cols="4" xs="3" sm="2" md="2" lg="1">
                            <v-card tile flat>
                              <div class="caption px-1 grey--text">Paid</div>
                              <div class="mt-0 mx-0 px-1">
                                $ {{ order.amountPaid }}
                              </div>
                            </v-card>
                          </v-col>

                          <v-col cols="4" xs="3" sm="3" md="2" lg="2">
                            <v-card tile flat>
                              <div class="caption px-1 grey--text">
                                Remaining
                              </div>
                              <div class="mt-0 mx-0 px-1">
                                $ {{ order.remainingPaid }}
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>

                      <!-- Orders more details -->
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-spacer></v-spacer>
                  <v-divider></v-divider>
                  <v-row align="center" justify="center">
                    <v-col cols="4" xs="3" sm="2" md="2" lg="2">
                      <span>
                        Qty Orders
                        <p>
                          {{ numberOfAllOfTheOrders }}
                        </p>
                      </span>
                    </v-col>
                    <v-col cols="4" xs="3" sm="2" md="2" lg="2">
                      <span>
                        Total Price
                        <p>
                          $
                          {{
                            (
                              Number(allOfAmountPaid) +
                              Number(allOfRemainingPaid)
                            ).toFixed(2)
                          }}
                        </p>
                      </span>
                    </v-col>
                    <v-col cols="4" xs="3" sm="2" md="2" lg="2">
                      <span>
                        Total Paid
                        <p>$ {{ allOfAmountPaid }}</p>
                      </span>
                    </v-col>

                    <v-col cols="6" xs="6" sm="6" md="6" lg="4">
                      <span>
                        Total Remaining
                        <p>$ {{ allOfRemainingPaid }}</p>
                      </span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-spacer></v-spacer>
                </v-card-text>
                <v-card-actions class="no-print">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialogAllOrderUser = false"
                    >Close</v-btn
                  >
                  <v-btn
                    color="green darken-1"
                    text
                    v-print="printObj"
                    @click="dialogAllOrderUser = false"
                    >Print Invoice</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="
                      dialogAllOrderUser = false;
                      downloadAllOrderUser(allOrderItem);
                    "
                    >Download Pdf</v-btn
                  >
                </v-card-actions>
              </v-card>
              <!--For Print All Orders-->
              <div class="for-print" id="printArea">
                <div style="text-align: center!important; " class="orange">
                  <span class="green">
                    | Custome Name :
                    <small>
                      <b> {{ displayNameUserOrder }}</b>
                    </small>
                    | </span
                  >*
                  <span class="green">
                    | Phone Number :
                    <small>
                      <b> {{ phoneNumber }}</b>
                    </small>
                    |
                  </span>
                </div>
                <center>
                  <span class="green">
                    | Address:
                    <small>
                      <b> {{ addressUserOrder }}</b>
                    </small>
                    |
                  </span>
                </center>
                <hr class="dotted" />
                <br />
                <div v-for="(order, index) in allOrderItem" :key="order.id">
                  <p>
                    <span> {{ index + 1 }}</span> |
                    <span>
                      <v-avatar size="50px">
                        <img
                          alt="ZUBENE"
                          :src="
                            order.productImageURL
                              ? order.productImageURL
                              : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUpload-PlaceHolder-zubene.png?alt=media&token=bdef7f9d-471c-4e74-96f3-f53353369176'
                          "
                        />
                      </v-avatar> </span
                    >&nbsp;&nbsp;
                    <span>
                      <small
                        >|
                        {{
                          order.product_name != ""
                            ? order.product_name
                            : "Not Set"
                        }}
                        |</small
                      >
                      - <small>| {{ order.country }} |</small> -
                      <small>| {{ order.status }} |</small> -
                      <small
                        >|
                        {{
                          order.statusUpdated.seconds | moment("from", "now")
                        }}
                        |</small
                      >
                    </span>
                  </p>
                  <p style="text-align: center;">
                    <span
                      >|

                      <small
                        v-if="order.currency === 'Turkish lira'"
                        class="mt-0 mx-0 px-1 red"
                      >
                        Total Price $
                        {{
                          (
                            order.price / order.tryRate +
                            order.totalShippingCost
                          ).toFixed(2)
                        }}
                      </small>
                      <small
                        v-else-if="order.currency === 'US Dollar'"
                        class="mt-0 mx-0 px-1 red"
                      >
                        Total Price $
                        {{ (order.price + order.totalShippingCost).toFixed(2) }}
                      </small>
                      |</span
                    >
                    -
                    <span>
                      |
                      <small class="mt-0 mx-0 px-1 green">
                        Paid $ {{ order.amountPaid }}
                      </small>
                      |
                    </span>
                    -
                    <span>
                      |

                      <small class="mt-0 mx-0 px-1 orange">
                        Remaining $ {{ order.remainingPaid }}
                      </small>
                      |
                    </span>
                  </p>
                  <hr class="solid" />
                </div>
                <div style="text-align: center!important;" class="orange">
                  <span class="red">
                    | Qty
                    <small>
                      <b> {{ numberOfAllOfTheOrders }}</b>
                    </small>
                    | </span
                  >*
                  <span class="red">
                    | Price
                    <small>
                      <b>
                        $
                        {{
                          (
                            Number(allOfAmountPaid) + Number(allOfRemainingPaid)
                          ).toFixed(2)
                        }}
                      </b>
                    </small>
                    | </span
                  >*
                  <span class="red">
                    | Paid
                    <small
                      ><b>$ {{ allOfAmountPaid }}</b></small
                    >
                    | </span
                  >*
                  <span class="red">
                    Remaining
                    <small>
                      <b>$ {{ allOfRemainingPaid }}</b></small
                    >
                  </span>
                </div>

                <hr class="dotted" />
              </div>
              <!--End For Print All Orders-->
            </div>
          </v-dialog>
        </v-row>
      </template>
      <!--End Orders Complete-->

      <div class="text-center no-print" v-show="pages">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="pageNumber"
                  class="my-4"
                  :length="pages"
                  @input="onPageChange"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import { auth, allOrders, userRef } from "@/firebase";
import { EventBus } from "@/event-bus.js";
import { jsPDF } from "jspdf";
// import Popup from "@/components/Popup";
export default {
  components: {
    // Popup,
  },
  props: {
    // id: {
    //   type: String,
    //   required: true,
    // },
    orderId: {
      type: String,
    },
    uid: {
      type: String,
    },

    page: {
      default: "1",
    },
  },

  data() {
    return {
      printObj: {
        id: "printArea",
        preview: true,
        popTitle: "Zubene Shopping | skip the store",
      },
      printSingleOrderObj: {
        id: "printAreaSingleOrder",
        preview: true,
        popTitle: "Zubene Shopping | skip the store",
      },
      printingLoading: false,
      printStatus: false,
      disabledPrintItem: true,
      dialogAllOrderUser: false,
      dialogPrintSingleOrder: false,
      allOfPrice: 0,
      status: "DeliveredToErbil",
      statuses: [
        "pending",
        "processing",
        "bought",
        "OnTheWay",
        "TurkeyOffice",
        "InDispatch",
        "shipped",
        "InBorder",
        "OutForDelivery",
        "USAOffice",
        "DeliveredToErbil",
        "completed",
        "onhold",
        "failed",
        "canceled",
      ],

      allOfShippingCost: 0,
      displayNameUserOrder: null,
      addressUserOrder: null,
      allExpensesItems: [], //Keep All Expenses
      allExpensesItemsCopy: [], // Keep Copy All Expense
      allOrderItem: [],
      allOrderItemCopy: [],
      allOfAmountPaid: 0,
      allOfRemainingPaid: 0,
      numberOfAllOfTheOrders: 0,
      snackbarInfo: false,
      snackbarText: "",
      snackbarColor: "success",
      total: 0,
      pages: 0,
      perPage: 5,
      pageNumber: 1,
      flag: false,

      orderStatus: [
        "pending",
        "processing",
        "bought",
        "OnTheWay",
        "TurkeyOffice",
        "InDispatch",
        "shipped",
        "USAOffice",
        "DeliveredToErbil",
        "completed",
        "InBorder",
        "OutForDelivery",
        "onhold",
        "failed",
        "canceled",
      ],
      statusUpdated: "",
      products: true,
      orderIdForPrint: "",
      userIdForPrint: "",
      allOrders: [],
      btnColor: "",
      snackbar: false,
      editMode: false,
      newProductName: "",
      indexToEdit: 0,
      userIdDelete: "",
      drag: true,
      adminDisplayName: "",
      userData: null,
      displayName: "",
      ordersUnsub: null,
      totalRemaining: 0,
      totalPaid: 0,
      totalPrice: 0,
      statusOrder: "",
      displayNamePrint: "",
      productCode: "",
      phoneNumber: "",
      productName: "",
      productImageURL: "",
    };
  },
  mounted() {
    if (this.orderId) {
      this.orderIdForPrint = this.orderId;
    }

    if (this.uid) {
      this.userIdForPrint = this.uid;
    }
    if (this.page) {
      this.pageNumber = Number(this.page);
    }
  },
  methods: {
    createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: "center",
          padding: 0,
        });
      }
      return result;
    },

    downloadAllOrderUser(orders) {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // console.log("Start Create PDF");
      // console.log(orders.length);

      var headers = this.createHeaders(
        [
          "id",
          "ProductCode",
          "Country",
          "ProductStatus",
          "PriceProduct",
          "Quantity",
          "TotalPrice",
          "Paid",
          "Remaining",
        ],
        "center"
      );
      var getData = function(orders) {
        var result = [];
        // var data = {
        //   ProductCode: orders.productCode.toString(),
        //   Country: orders.Country.toString(),
        //   DateUpdate: orders.statusUpdated.toString(),
        //   TotalPrice: orders.currency === 'Turkish lira' ? (( orders.price / orders.tryRate +
        //                                   orders.totalShippingCost).toFixed(2)).toString() : (( orders.price + orders.totalShippingCost).toFixed(2)).toString(),
        //   Paid: orders.amountPaid.toString(),
        //   Remaining: orders.remainingPaid.toString()
        // };
        for (var i = 0; i < orders.length; i += 1) {
          if (orders[i].quantity == 2) {
            // console.log("tryRate:", orders[i].tryRate);
            // console.log("shipping:", orders[i].totalShippingCost);
            // console.log("price:", orders[i].price);
          }
          const priceProduct =
            orders[i].currency === "Turkish lira"
              ? (
                  orders[i].price / orders[i].tryRate +
                  orders[i].totalShippingCost
                ).toFixed(2)
              : (orders[i].price + orders[i].totalShippingCost).toFixed(2);
          const totalPrice =
            orders[i].currency === "Turkish lira"
              ? (
                  (orders[i].price * orders[i].quantity) / orders[i].tryRate +
                  orders[i].totalShippingCost
                ).toFixed(2)
              : (
                  orders[i].price * orders[i].quantity +
                  orders[i].totalShippingCost
                ).toFixed(2);
          result.push(
            Object.assign(
              {},
              {
                id: (i + 1).toString(),
                ProductCode:
                  orders[i].productCode != ""
                    ? orders[i].productCode.toString()
                    : "Not Set",
                Country: orders[i].country.toString(),
                // eslint-disable-next-line no-undef
                ProductStatus: orders[i].status,
                PriceProduct: `${priceProduct} $`,
                Quantity: orders[i].quantity.toString(),
                // new Date(orders[i].statusUpdated).toDateString(),
                TotalPrice: `${totalPrice} $`,
                Paid: `${orders[i].amountPaid.toString()} $`,
                Remaining: `${orders[i].remainingPaid.toString()} $`,
              }
            )
          );
        }
        return result;
      };
      const calcTotalPrice =
        Number(this.allOfAmountPaid) + Number(this.allOfRemainingPaid);

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
      doc.setFontSize(8);
      doc.text(20, 15, `${year}-${month}-${day}`);
      doc.setFontSize(20);
      doc.text(20, 30, "Zubene co");
      doc.setFontSize(8);

      doc.text(20, 35, "Customer Invoice");
      doc.setFontSize(10);
      doc.text(70, 30, "skip the store");

      doc.setFontSize(10);
      doc.text(20, 40, "Customer Name: " + this.displayNameUserOrder);
      doc.text(70, 40, "Phone Number : +964" + this.phoneNumber);
      doc.text(130, 40, "Address  : " + this.addressUserOrder);
      doc.text(
        5,
        45,
        "----------------------------------------------------------------------------------------------------"
      );
      doc.text(
        120,
        45,
        "-----------------------------------------------------------------------------------------------------------------------------------"
      );
      doc.setFontSize(10);
      doc.text(230, 15, "Total Price : " + calcTotalPrice.toFixed(2) + " $");
      doc.text(
        230,
        20,
        "Total Paid : " + this.allOfAmountPaid.toFixed(2) + " $"
      );
      doc.text(
        230,
        25,
        "Total Remaining : " + this.allOfRemainingPaid.toFixed(2) + " $"
      );
      // doc.text(20,70 ,"-----------------------------------");
      doc.table(30, 60, getData(orders), headers, { autoSize: true });
      doc.save(`${this.displayNameUserOrder}-${year}-${month}-${day}` + ".pdf");
      // console.log("completePDF");
    },
    printOrder(order) {
      this.productImageURL = order.productImageURL;
      this.productName = order.productName;
      this.disabledPrintItem = true;
      this.displayNamePrint = order.displayName;
      this.productCode = order.productCode;
      this.phoneNumber = order.phoneNumber;
      this.statusOrder = order.status;
      this.statusUpdated = order.statusUpdated;
      if (order.currency === "Turkish lira") {
        this.totalPrice = order.price / order.tryRate + order.totalShippingCost;
      } else if (order.currency === "US Dollar") {
        this.totalPrice = order.price + order.totalShippingCost;
      }
      this.totalPaid = order.amountPaid;
      this.totalRemaining = order.remainingPaid;
      //(order.price / order.tryRate) + order.totalShippingCost
      // console.log(order);
    },

    // filterOrders(status) {

    // },
    async readData() {
      this.expenses = [];

      this.allOrders = [];
      this.expenses = [];
      this.allExpensesItems = [];
      this.allExpensesItemsCopy = [];
      this.allOrderItem = [];
      this.allOrderItemCopy = [];

      this.allOfPrice = 0;
      this.allOfShippingCost = 0;
      this.totalOfShippingCost = 0;
      this.allOfAmountPaid = 0;
      this.allOfRemainingPaid = 0;
      this.numberOfAllOfTheOrders = 0;
      //todo check Type

      // console.log("Run Order --------------");
      //todo get order with country and query
      const allOrdersCompletedRef = await allOrders
        .where("userId", "==", this.userIdForPrint)
        .where("status", "==", this.status);
      await allOrdersCompletedRef.onSnapshot((docs) => {
        const docsChanges = docs.docChanges();
        docsChanges.forEach(async (res) => {
          const pureData = res.doc.data();
          const resType = res.type;
          let deleteItems = null;
          // console.log('docs-----------');
          // console.log(pureData);
          const accountingData = this.accountingModel(pureData);

          if (resType == "added") {
            this.accountingFunction(accountingData, deleteItems);
            this.allOrderItem.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
            this.allOrderItemCopy.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
          } else if (resType === "modified") {
            let index = null;
            let modifiedItemPreviousData = null;
            const list = this.allOrderItem;
            const modifiedItem = { ...pureData, id: res.doc.id };
            this.allOrderItem = this.allOrderItem.filter(function(item) {
              if (item.id === res.doc.id) {
                index = list.indexOf(item);
                modifiedItemPreviousData = item;
              }
              return item.id !== res.doc.id;
            });
            this.allOrderItemCopy = this.allOrderItemCopy.filter(function(
              item
            ) {
              return item.id !== res.doc.id;
            });
            this.allOrderItem.splice(index, 0, modifiedItem);
            this.allOrderItemCopy.splice(index, 0, modifiedItem);
            deleteItems = this.accountingModel(modifiedItemPreviousData);
            this.accountingFunction(accountingData, deleteItems);
          } else if (resType === "removed") {
            this.accountingFunction(deleteItems, accountingData);
            this.allOrderItem = this.allOrderItem.filter(function(item) {
              return item.id !== res.doc.id;
            });
            this.allOrderItemCopy = this.allOrderItemCopy.filter(function(
              item
            ) {
              return item.id !== res.doc.id;
            });
          } else {
            console.log("Unknown");
          }
          console.log("pageCheck");
          this.pageCheck();
          this.progress = false;
        });
      });
    },

    onPageChange() {
      // console.log(this.filterReportTurkeyStatus,this.query);
      // console.log(this.page,this.pageNumber);
      if (
        String(this.page) !== String(this.pageNumber) ||
        this.query !== this.filterQuery
      ) {
        // console.log(`USer ID:${this.userIdForPrint}`)
        this.$router.push({
          name: "print",
          params: {
            uid: this.userIdForPrint,
            // orderId: this.orderIdForPrint,
            page: this.pageNumber,
          },
        //   params: { query: this.query, page: this.pageNumber },
        });
      }
    },
    pageCheck() {
      // Get size of query
      this.pageNumber = Number(this.page);

      // console.log('this ----order', this.page);
      
      this.total = this.allOrderItem.length;
      // console.log('Total ----order', this.total);
      this.pages = Math.ceil(this.total / this.perPage);
      // console.log('pages ----', this.pages);
    
      // Get size of query
      const start = this.perPage * (this.pageNumber - 1);
      // console.log('start ----', start);

      const end = start + this.perPage;
      // console.log('end ----', end);

      this.allOrders = this.allOrderItem.slice(start, end); //  this.ordersCompleted
    },

    accountingModel(data) {
      const dataModel = {
        price: data.price,
        qty: data.quantity,
        shipping: data.shippingCost,
        totalShippingCost: data.totalShippingCost,
        paid: data.amountPaid,
        remaining: data.remainingPaid,
        currency: data.currency,
        rate: data.tryRate,
      };
      return dataModel;
    },

    accountingFunction(data, deleted) {
      if (data) {
        this.numberOfAllOfTheOrders += 1;
        if (data.price && data.currency === "Turkish lira") {
          const calc = (parseFloat(data.price) / parseFloat(data.rate)) * 100;
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(calc.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        } else if (data.currency) {
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(data.price.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        }
        if (data.shipping) {
          this.allOfShippingCost = Number(
            (
              Number(this.allOfShippingCost.toFixed(2)) +
              Number(data.shipping.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.totalShippingCost) {
          this.totalOfShippingCost = Number(
            (
              Number(this.totalOfShippingCost.toFixed(2)) +
              Number(data.totalShippingCost.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.paid) {
          this.allOfAmountPaid = Number(
            (
              Number(this.allOfAmountPaid.toFixed(2)) +
              Number(data.paid.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.remaining) {
          this.allOfRemainingPaid = Number(
            (
              Number(this.allOfRemainingPaid.toFixed(2)) +
              Number(data.remaining.toFixed(2))
            ).toFixed(2)
          );
        }
      }
      if (deleted) {
        {
          this.numberOfAllOfTheOrders -= 1;
          if (deleted.price && deleted.currency === "Turkish lira") {
            const calc =
              (parseFloat(deleted.price) / parseFloat(deleted.rate)) * 100;
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(calc.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          } else if (deleted.currency) {
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(deleted.price.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          }
          if (deleted.shipping) {
            this.allOfShippingCost = Number(
              (
                Number(this.allOfShippingCost.toFixed(2)) -
                Number(deleted.shipping.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.totalShippingCost) {
            this.totalOfShippingCost = Number(
              (
                Number(this.totalOfShippingCost.toFixed(2)) -
                Number(deleted.totalShippingCost.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.paid) {
            this.allOfAmountPaid = Number(
              (
                Number(this.allOfAmountPaid.toFixed(2)) -
                Number(deleted.paid.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.remaining) {
            this.allOfRemainingPaid = Number(
              (
                Number(this.allOfRemainingPaid.toFixed(2)) -
                Number(deleted.remaining.toFixed(2))
              ).toFixed(2)
            );
          }
        }
      }
    },

    snackbarTextMessage: function(message) {
      this.text = message.snackbarText;
      this.snackbarColor = message.snackbarColor;
      this.snackbar = message.snackbarInfo;
    },

    openDialog() {
      EventBus.$emit("dialog", true);
    },
  },

  async created() {
    this.filterReportTurkeyStatus = this.query;
    const userAuth = auth;
    await userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.userId = user.uid;
        this.adminDisplayName = user.displayName;
      }
    });
    //get User Full Name
    const getUserOrder = await userRef.doc(this.userIdForPrint).get();
    if (getUserOrder) {
      this.displayNameUserOrder = getUserOrder.data().displayName;
      this.phoneNumber = getUserOrder.data().phoneNumber;
      this.addressUserOrder = getUserOrder.data().address;
    } else {
      this.displayNameUserOrder = "Not found User";
      this.addressUserOrder = "Not found User address";
      this.phoneNumber = "Not found Phone";
    }

    this.readData();
  },
  watch: {
    async $route(to, from) {
      // react to route changes...
      if (
        to.params.query !== from.params.query ||
        to.params.id !== from.params.id ||
        to.params.page !== from.params.page
      ) {
        if (to.params.query === from.params.query) {
          this.pageCheck();
        }
      }
      this.allOrders = [];
      this.allExpensesItems = []; //Keep All Expenses
      this.allExpensesItemsCopy = []; // Keep Copy All Expense
      this.allOrderItem = [];
      this.allOrderItemCopy = [];
      this.allOfPrice = 0;
      this.allOfShippingCost = 0;
      this.allOfAmountPaid = 0;
      this.allOfRemainingPaid = 0;
      this.numberOfAllOfTheOrders = 0;
      this.readData();
    },
  },
  beforeDestroy() {
    //
  },
};
</script>

<style>
.for-print {
  display: none;
}
.green {
  color: green !important;
}
.red {
  color: red !important;
}
.orange {
  color: rgb(190, 114, 0) !important;
}
hr.dotted {
  border-top: 3px dotted #bbb;
}
hr.solid {
  border-top: 3px solid #bbb;
}
.order.completed {
  border-left: 4px solid #3cd1c2;
}
.order.failed {
  border-left: 4px solid #f83e70;
}
.order.processing {
  border-left: 4px solid #ab47bc;
}
.order.pending {
  border-left: 4px solid #ffa726;
}
.order.canceled {
  border-left: 4px solid #8d6e63;
}
.order.OnTheWay {
  border-left: 4px solid #006064;
}
.order.refunded {
  border-left: 4px solid #cddc39;
}
.order.onhold {
  border-left: 4px solid #bdbdbd;
}

.theme--light.v-btn:not(.v-btn--icon).completed {
  background-color: #3cd1c2;
}

.theme--light.v-btn:not(.v-btn--icon).failed {
  background-color: #f83e70;
}

.theme--light.v-btn:not(.v-btn--icon).processing {
  background-color: #ab47bc;
}

.theme--light.v-btn:not(.v-btn--icon).pending {
  background-color: #ffaa2c;
}

.theme--light.v-btn:not(.v-btn--icon).canceled {
  background-color: #8d6e63;
}

.theme--light.v-btn:not(.v-btn--icon).OnTheWay {
  background-color: #006064;
}

.theme--light.v-btn:not(.v-btn--icon).refunded {
  background-color: #cddc39;
}

.theme--light.v-btn:not(.v-btn--icon).onhold {
  background-color: #bdbdbd;
}

.theme--light.v-btn.completed {
  color: #3cd1c2;
}

.theme--light.v-btn.failed {
  color: #f83e70;
}

.theme--light.v-btn.processing {
  color: #ab47bc;
}

.theme--light.v-btn.pending {
  color: #ffaa2c;
}

.theme--light.v-btn.canceled {
  color: #8d6e63;
}

.theme--light.v-btn.OnTheWay {
  color: #006064;
}

.theme--light.v-btn.refunded {
  color: #cddc39;
}

.theme--light.v-btn.onhold {
  color: #bdbdbd;
}

.handle {
  cursor: move;
}

.routerLink {
  text-decoration: none;
}
@media print {
  @page {
    /*A4*/
    size: 210mm 297mm; /* auto is the initial value */
    margin-top: 20mm;
    background-color: red;
    margin-bottom: 0mm; /* this affects the margin in the printer settings */
  }
  * {
    margin: 0;
    padding: 0;
  }
  .for-print {
    display: block !important;
  }
  p {
    padding: 5px;
  }
  html {
    background-color: #ffffff;
    margin: 0px; /* this affects the margin on the html before sending to printer */
  }
  .no-print {
    display: none !important;
  }
  .row-item {
    display: block !important;
  }
  body {
    font-family: verdana, arial, sans-serif;
    overflow: auto;
    height: auto;
    /*border: solid 1px;*/
    /* padding: 10px; */
    margin: 10mm 10mm 10mm 10mm; /* margin you want for the content*/
  }
  .scroll-y {
    height: auto;
    overflow: visible;
  }
  .displayName {
    font-size: 26px;
    font-weight: 600;
  }
}
@media screen {
  .no-screen {
    display: none !important;
  }
}
</style>
