<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      close-delay
      open-delay
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-icon class="mx-3">
            <v-icon>mdi-currency-usd-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mx-5"> Currency Rate </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Turkish Liara Rate</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-currency-usd-circle"
                    label="100$ = Turkish Lira *"
                    hint="Please enter Turkish lira exchange rate"
                    persistent-hint
                    required
                    clearable
                    clear-icon="mdi-close-circle"
                    v-model.number="tryRate"
                    :rules="exchangeRateRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="exchangeRate"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { rateRef } from "@/firebase";

export default {
  components: {},
  data: () => ({
    loading: false,
    dialog: false,
    valid: true,
    tryRate: 0,
    closeRate: 0,
    reateRefUnsub: null,
    exchangeRateRules: [
      (v) => !!v || "Exchange rate is required",
      (v) =>
        Number.isInteger(v) || Number.isFinite(v) || !v || "Must be number",
    ],
  }),
  methods: {
    close() {
      this.tryRate = this.closeRate;
      this.dialog = false;
    },
    async exchangeRate() {
      if (this.$refs.form.validate() && this.tryRate > 0) {
        this.loading = true;
        var message = "";
        var snackbarColor = "";
        const ref = rateRef;
        const date = new Date(Date.now());
        var tryRate = {
          rate: this.tryRate,
          statusUpdated: date,
          userUpdated: "",
        };
        await ref
          .set(tryRate)
          .then(function () {
            // Email sent.
            message = "Exchange rate updated";
            snackbarColor = "success";
          })
          .catch(function (error) {
            // An error happened.
            message = error.message;
            snackbarColor = "error";
          });
        await this.$emit("exchangeRatePopup", {
          snackbarText: message,
          snackbarInfo: true,
          snackbarColor: snackbarColor,
        });
        this.loading = false;
        this.dialog = false;
        return true;
      }
    },
  },
  created() {
    // Get turkish lira exchange rate
    const rateRefCollection = rateRef;
    this.reateRefUnsub = rateRefCollection.onSnapshot((res) => {
      if (res.exists) {
        this.tryRate = res.data().rate;
        this.closeRate = this.tryRate;
      }
    });
  },
  beforeDestroy() {
    if (this.reateRefUnsub) {
      this.reateRefUnsub();
      this.reateRefUnsub = null;
    }
  },
};
</script>

<style>
</style>