<template>
  <div class="ShippingCalculator">
    <v-banner
      icon="mdi-calculator-variant"
      single-line
      class="subtitle-1 grey--text"
      >ShippingCalculator</v-banner
    >
    <v-container class="my-5"> </v-container>

    <v-row align="center" justify="center">
      <span class="my-3 headline text-center font-weight-light grey--text"
        >Contact us if you have any questions about shipping costs</span
      >
    </v-row>
    <v-row align="center" justify="center">
      <span class="title text-center font-weight-thin grey--text"
        >We'll add the calculator asap :)</span
      >
    </v-row>
  </div>
</template>

<script>
export default {};
</script>