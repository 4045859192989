<template>
  <div class="adminDashboard">
    <v-banner
      icon="mdi-account-tie"
      single-line
      class="subtitle-1 text-capitalize grey--text"
      >Admin Dashboard | {{ query }}
    </v-banner>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbarInfo" :timeout="4000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-snackbar v-model="snackbar" top color="warning" flat>
      <span v-if="orders[indexToEdit]"
        >Are you sure to delete order
        <span class="grey--text font-italic"
          >' {{ orders[indexToEdit].product_name }} '</span
        >
        for
        <span class="grey--text font-italic"
          >' {{ orders[indexToEdit].displayName }} '</span
        >?</span
      >
      <v-row class="mt-3 mb-2" align="center" justify="center">
        <v-btn
          class="mx-10"
          dark
          color="red"
          @click="
            snackbar = false;
            deleteOrder();
          "
          >Yes</v-btn
        >
        <v-btn class="mx-10" dark color="blue" @click="snackbar = false"
          >No</v-btn
        >
      </v-row>
    </v-snackbar>
    <v-alert v-if="query === 'all'" dense color="blue" type="info">
      Includes only fulfilling orders
      <strong
        >[Processing, Bought, On The Way, in Border, Turkey Office, USA office
        and Erbil Office ]</strong
      >
    </v-alert>
    <v-container fluid class="px-0">
      <v-container fluid class="px-0">
        <v-row dense
          ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
            <v-card class="mx-auto" color="accounting" dark>
              <v-card-title class="px-2">
                <v-icon
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.smAndUp"
                  left
                >
                  mdi-cart
                </v-icon>
                <span
                  class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                  >All orders</span
                >
              </v-card-title>
              <v-card-text
                class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >
                {{ numberOfAllOfTheOrders }}
              </v-card-text>
            </v-card></v-col
          ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
            <v-card class="mx-auto" color="accounting" dark>
              <v-card-title class="px-2">
                <v-icon
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.smAndUp"
                  left
                >
                  mdi-tag-multiple
                </v-icon>
                <span
                  class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                  >Total Price</span
                >
              </v-card-title>
              <v-card-text
                class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >
                $ {{ allOfPrice }}
              </v-card-text>
            </v-card></v-col
          ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
            <v-card class="mx-auto" color="accounting" dark max-width="400">
              <v-card-title class="px-2">
                <v-icon
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.smAndUp"
                  left
                >
                  mdi-truck-fast
                </v-icon>
                <span
                  class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                  >Total Cargo</span
                >
              </v-card-title>
              <v-card-text
                class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >
                $
                {{
                  (
                    Number(allOfAmountPaid) +
                    Number(allOfRemainingPaid) -
                    Number(allOfPrice)
                  ).toFixed(2)
                }}
              </v-card-text>
            </v-card></v-col
          ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
            <v-card class="mx-auto" color="accounting" dark max-width="400">
              <v-card-title class="px-2">
                <v-icon
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.smAndUp"
                  left
                >
                  mdi-wallet
                </v-icon>
                <span
                  class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                  >Total Cost</span
                >
              </v-card-title>
              <v-card-text
                class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >
                $
                {{
                  (
                    Number(allOfAmountPaid) + Number(allOfRemainingPaid)
                  ).toFixed(2)
                }}
              </v-card-text>
            </v-card></v-col
          ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
            <v-card class="mx-auto" color="accounting" dark max-width="400">
              <v-card-title class="px-2">
                <v-icon
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.smAndUp"
                  left
                >
                  mdi-cash-register
                </v-icon>
                <span
                  class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                  >Total Paid</span
                >
              </v-card-title>
              <v-card-text
                class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >
                $ {{ allOfAmountPaid }}
              </v-card-text>
            </v-card></v-col
          ><v-col cols="4" xs="4" sm="4" md="2" lg="2">
            <v-card class="mx-auto" color="accounting" dark max-width="400">
              <v-card-title class="px-2">
                <v-icon
                  :small="$vuetify.breakpoint.xs"
                  :large="$vuetify.breakpoint.smAndUp"
                  left
                >
                  mdi-chart-arc
                </v-icon>
                <span
                  class="text-xl-h4 text-lg-subtitle-1 text-md-subtitle-1 text-sm-h6 text-caption font-weight-normal"
                  >Total Unpaid</span
                >
              </v-card-title>
              <v-card-text
                class="text-xl-h4 text-lg-h5 text-md-h6 text-sm-h5 text-body-1 font-weight-bold"
              >
                $ {{ allOfRemainingPaid }}
              </v-card-text>
            </v-card></v-col
          ></v-row
        ></v-container
      >
      <!-- Search user by phone number or Name-->
      <v-row align="center" justify="center" no-gutters>
        <div max-width="800px">
          <v-row>
            <v-col cols="8">
              <v-autocomplete
                prepend-icon="mdi-account-search"
                :items="users"
                return-object
                solo
                rounded
                clearable
                @click:clear="filteredUsers = []"
                :loading="userDataLoading"
                :disabled="!userFiledSearchSelected"
                :item-text="userFiledSearchSelected"
                v-model="userSelected"
                @change="
                  userSelected
                    ? productUserSearch(userSelected, userFiledSearchSelected)
                    : ''
                "
                @click="!users.length ? userData() : null"
                label="Users"
                hint="Select the user whose profile you want to see"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :items="userFiledSearch"
                solo
                rounded
                select
                v-model="userFiledSearchSelected"
                label="Search by"
                hint="Select the field you want to search with"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <v-expansion-panels
          popout
          v-for="filteredUser in filteredUsers"
          :key="filteredUser.id"
        >
          <v-expansion-panel text hide-actions>
            <v-expansion-panel-header class="mx-0">
              <v-row justify="start" align="center" class="spacer" no-gutters>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-avatar size="50px">
                    <img
                      alt="ZUBENE"
                      :src="
                        filteredUser.photoURL
                          ? filteredUser.photoURL
                          : 'https://imgur.com/dLB4u3s.png'
                      "
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-card tile flat>
                    <router-link
                      class="routerLink"
                      :to="{
                        name: 'userorders',
                        params: {
                          id: filteredUser.id,
                          query: 'all',
                          page: '1',
                        },
                      }"
                    >
                      <v-btn
                        text
                        small
                        rounded
                        class="green px-2 py-0 white--text"
                      >
                        {{ filteredUser.displayName }}
                      </v-btn>
                    </router-link>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-card tile flat>
                    <a
                      class="routerLink"
                      :href="`mailto:0${filteredUser.email}`"
                      target="_blank"
                    >
                      <v-btn text small rounded class="green white--text">
                        {{ filteredUser.email }}
                      </v-btn>
                    </a>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-card tile flat>
                    <a
                      class="routerLink"
                      :href="`tel:0${filteredUser.phoneNumber}`"
                      target="_blank"
                    >
                      <v-btn text small rounded class="green white--text">
                        0{{ filteredUser.phoneNumber }}
                      </v-btn>
                    </a>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2"></v-col>
                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="green"
                        @click="ReportUser(filteredUser.id)"
                        class="pa-0 ma-0"
                      >
                        <v-icon>mdi-view-module</v-icon>
                      </v-btn>
                    </template>

                    <span>Report</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-divider></v-divider>
              <v-row align="center">
                <v-col
                  v-if="filteredUser.address"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                >
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Credit</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ filteredUser.credit }}
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  v-if="filteredUser.birthdate"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                >
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Birthdate</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ filteredUser.birthdate }}
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  v-if="filteredUser.address"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                >
                  <v-card tile flat>
                    <div class="caption px-1 grey--text">Address</div>
                    <div class="mt-0 mx-0 px-1">
                      {{ filteredUser.address }}
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <v-divider class="mb-3"></v-divider>
      <!-- End Search user by phone number -->

      <!-- Filter list -->
      <v-row class="mb-2 px-4" justify="center" v-if="false">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('all');
                products = true;
              "
            >
              <v-icon color="primary">mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>Show all orders</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('pending');
                products = false;
              "
            >
              <v-icon color="warning">mdi-timer-sand</v-icon>
            </v-btn>
          </template>
          <span>Pending</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('processing');
                products = false;
              "
            >
              <v-icon color="process">mdi-autorenew</v-icon>
            </v-btn>
          </template>
          <span>Processing</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('bought');
                products = false;
              "
            >
              <v-icon color="buy">mdi-credit-card-check</v-icon>
            </v-btn>
          </template>
          <span>Bought</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OnTheWay');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>On The Way</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OnTheWay');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>On The Way</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InBorder');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>in Border</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OutForDelivery');
                products = false;
              "
            >
              <v-icon color="way">mdi-truck-fast</v-icon>
            </v-btn>
          </template>
          <span>in Border</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('TurkeyOffice');
                products = false;
              "
            >
              <v-icon color="turkey">mdi-city-variant</v-icon>
            </v-btn>
          </template>
          <span>Turkey Office</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InDispatch');
                products = false;
              "
            >
              <v-icon color="InDispatch">mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span>In Dispatch</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('shipped');
                products = false;
              "
            >
              <v-icon color="shipped">mdi-cube-send</v-icon>
            </v-btn>
          </template>
          <span>Shipped</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('USAOffice');
                products = false;
              "
            >
              <v-icon color="usa">mdi-star-circle</v-icon>
            </v-btn>
          </template>
          <span>USA Office</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('DeliveredToErbil');
                products = false;
              "
            >
              <v-icon color="erbil">mdi-office-building-marker</v-icon>
            </v-btn>
          </template>
          <span>Delivered to Erbil</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('completed');
                products = false;
              "
            >
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-btn>
          </template>
          <span>Completed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('InBorder');
                products = false;
              "
            >
              <v-icon color="hold">mdi-DragHorizontalVariant-circle</v-icon>
            </v-btn>
          </template>
          <span>In Border</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('OutForDelivery');
                products = false;
              "
            >
              <v-icon color="hold">mdi-DragHorizontalVariant-circle</v-icon>
            </v-btn>
          </template>
          <span>Out For Delivery</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('failed');
                products = false;
              "
            >
              <v-icon color="error">mdi-information</v-icon>
            </v-btn>
          </template>
          <span>Failed</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('canceled');
                products = false;
              "
            >
              <v-icon color="cancel">mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Canceled</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                filterOrders('onhold');
                products = false;
              "
            >
              <v-icon color="hold">mdi-pause-circle</v-icon>
            </v-btn>
          </template>
          <span>Out Of Stock</span>
        </v-tooltip>
      </v-row>
      <!-- End Filter list -->

      <!-- Search by product code -->
      <v-row justify="center" align="center">
        <v-card outlined tile color="transparent" max-width="800px">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                prepend-icon="mdi-barcode"
                :items="allOrders"
                clearable
                solo
                rounded
                @click:clear="orderSearch ? pageCheck() : ''"
                item-text="productCode"
                v-model="orderSearch"
                @change="orderSearch ? productCodeSearch(orderSearch) : ''"
                label="Product Code Filter"
                hint="You can filter by Product code"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <!-- End Search by product code -->

      <!-- Progress circular -->
      <div
        align="center"
        justify="center"
        v-if="this.orders.length === 0 && this.progress"
      >
        <v-progress-circular
          :size="100"
          :width="10"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else-if="this.orders.length === 0 && this.progress === false"
        align="center"
        justify="center"
      >
        <div v-if="all" class="display-1 grey--text font-weight-light">
          No orders in '{{ filterStatus }}'
        </div>
        <div v-else class="display-1 grey--text font-weight-light">
          No orders in '{{ query }}'
        </div>
      </div>

      <!-- End Progress circular -->

      <!-- Order list -->

      <v-expansion-panels>
        <v-expansion-panel
          text
          v-for="(order, index) in orders"
          :key="order.id"
          hide-actions
        >
          <v-expansion-panel-header :class="` mx-0 order ${order.status}`">
            <v-row justify="center" align="center" class="spacer" no-gutters>
              <v-col cols="12" xs="12" sm="1" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.productImageURL
                        ? order.productImageURL
                        : 'https://firebasestorage.googleapis.com/v0/b/tracking-management-syst-fb456.appspot.com/o/images%2FUploa-PlaceHolder.png?alt=media&token=fb193be8-59f6-47cc-b546-cfc951ba64a5'
                    "
                  />
                </v-avatar>
              </v-col>

              <v-col cols="12" xs="12" sm="7" md="5" lg="5">
                <v-card tile flat>
                  <div class="caption grey--text">Product Name</div>
                  <div
                    class="mt-0 mb-0"
                    v-if="!editMode || index != indexToEdit"
                  >
                    {{ order.product_name }}
                  </div>

                  <v-form
                    @submit.prevent="updateProductName(index, order.userId)"
                  >
                    <v-text-field
                      autofocus
                      :color="`${order.status}s`"
                      v-model="newProductName"
                      v-if="editMode && indexToEdit == index"
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </v-form>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="1" lg="1">
                <v-card tile flat align="center">
                  <div class="caption px-1 grey--text">Confirmation</div>
                  <div v-if="order.isConfirmed === true" class="mt-0 mx-0 px-1">
                    <v-icon color="green"> mdi-check-circle </v-icon>
                  </div>
                  <div
                    v-else-if="order.isConfirmed === false"
                    class="mt-0 mx-0 px-1"
                  >
                    <v-icon color="red"> mdi-close-circle </v-icon>
                  </div>
                  <div v-else class="mt-0 mx-0 px-1">
                    <v-icon color="blue"> mdi-help-circle</v-icon>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="4" md="5" lg="5">
                <v-card tile flat>
                  <div class="caption px-2 grey--text">Customer Name</div>
                  <router-link
                    class="routerLink"
                    :to="{
                      name: 'userorders',
                      params: { id: order.userId, query: 'all', page: '1' },
                    }"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} px-2 py-0 white--text`"
                    >
                      {{ order.displayName }}
                    </v-btn>
                  </router-link>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Price</div>
                  <div
                    v-if="order.currency === 'Turkish lira'"
                    class="mt-0 mx-0 px-1"
                  >
                    ₺ {{ order.price }}
                  </div>
                  <div
                    v-else-if="order.currency === 'US Dollar'"
                    class="mt-0 mx-0 px-1"
                  >
                    $ {{ order.price }}
                  </div>
                  <div v-else class="mt-0 mx-0 px-1 font-weight-light">
                    Not Set
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Paid</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.amountPaid }}</div>
                </v-card>
              </v-col>

              <v-col cols="12" xs="12" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Shipping</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.shippingCost }}</div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="1" lg="1">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Total Shipping</div>
                  <div class="mt-0 mx-0 px-1">
                    $ {{ order.totalShippingCost }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="2" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Remaining</div>
                  <div class="mt-0 mx-0 px-1">$ {{ order.remainingPaid }}</div>
                  <small class="caption px-1 grey--text">Deposit</small>
                  <small class="mt-0 mx-0 px-1 green--text" v-if="order.credit"
                    >$ {{ order.credit }}</small
                  >
                  <small class="mt-0 mx-0 px-1 red--text" v-else>$ 0 </small>
                </v-card>
              </v-col>

              <v-col cols="12" xs="12" sm="4" md="2" lg="2">
                <v-card tile flat>
                  <div class="pt-3">
                    <v-menu rounded="b-xl" offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          small
                          rounded
                          :class="`${order.status} white--text`"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ order.status }}
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="orderStatusItem in orderStatus"
                          :key="orderStatusItem"
                          link
                          @click="
                            changeStatus(index, order.userId, orderStatusItem)
                          "
                        >
                          <v-list-item-title
                            v-text="orderStatusItem"
                          ></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="3"
                lg="3"
                align="center"
                justify="center"
              >
                <v-card tile flat color="transparent">
                  <div class="mt-0 pt-0">
                    <v-btn
                      icon
                      :class="`${order.status}`"
                      @click="
                        editMode = !editMode;
                        indexToEdit = index;
                        newProductName = order.product_name;
                      "
                      class="pa-0 ma-0"
                      ><v-icon>mdi-pencil</v-icon></v-btn
                    >
                    <!-- <v-btn
                      icon
               
                      :class="`${order.status}`"
                      @click="
                        snackbar = true;
                        indexToEdit = index;
                        userIdDelete = order.userId;
                      "
                      class="pa-0 ma-0"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    > -->

                    <router-link
                      class="routerLink"
                      :to="{
                        name: 'ordercheck',
                        params: { id: order.id, uid: order.userId },
                      }"
                    >
                      <v-btn icon :class="`${order.status}`" class="pa-0 ma-0"
                        ><v-icon>mdi-arrow-right-bold-circle</v-icon></v-btn
                      >
                    </router-link>
                    <v-btn
                      icon
                      :class="`${order.status}`"
                      @click="sendSMS('Test SMS')"
                      class="pa-0 ma-0"
                      ><v-icon>mdi-email</v-icon></v-btn
                    >
                    <v-btn
                      :href="`https://wa.me/964${order.phoneNumber}`"
                      target="_blank"
                      icon
                      :class="`${order.status}`"
                      class="pa-0 ma-0"
                      ><v-icon>mdi-whatsapp</v-icon></v-btn
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <!-- Orders more details -->
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-row align="center">
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-avatar size="50px">
                  <img
                    alt="ZUBENE"
                    :src="
                      order.photoUrl
                        ? order.photoUrl
                        : 'https://imgur.com/dLB4u3s.png'
                    "
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="1" lg="1">
                <v-card tile flat>
                  <a class="routerLink" :href="order.url" target="_blank">
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      Link
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <a
                    class="routerLink"
                    :href="`tel:0${order.phoneNumber}`"
                    target="_blank"
                  >
                    <v-btn
                      text
                      small
                      rounded
                      :class="`${order.status} white--text`"
                    >
                      0{{ order.phoneNumber }}
                    </v-btn>
                  </a>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Color</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productColor }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Size</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.productSize }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Quntity</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.quantity }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Country</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.country }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="5" lg="5">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Email</div>
                  <div class="mt-0 mx-0 px-1">
                    <a
                      class="routerLink black--text"
                      :href="`mailto:0${order.email}`"
                      target="_blank"
                    >
                      {{ order.email }}
                    </a>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="3" lg="3">
                <v-card tile flat>
                  <div class="caption px-1 grey--text">User Updated</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.userUpdated }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="2"
                lg="2"
                v-if="order.statusUpdated"
              >
                <v-card tile flat>
                  <div class="caption grey--text">Status Updated</div>
                  <div class="mt-0 mx-0">
                    {{ order.statusUpdated.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                <v-card tile flat>
                  <div class="caption grey--text">Submit Date</div>
                  <div class="mt-0 mx-0">
                    {{ order.submit_date.seconds | moment("from", "now") }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                v-if="order.description"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <v-card tile flat>
                  <div class="caption px-1 grey--text">Description</div>
                  <div class="mt-0 mx-0 px-1">
                    {{ order.description }}
                  </div>
                </v-card>
              </v-col>
              <v-col
                v-if="order.message"
                cols="12"
                xs="12"
                sm="6"
                md="12"
                lg="12"
              >
                <v-alert
                  class="px-3 py-1 mt-2"
                  color="blue"
                  outlined
                  text
                  icon="mdi-message-text"
                  dense
                  border="left"
                  type="info"
                >
                  <div class="caption">Admin Message</div>
                  <div class="py-2">
                    {{ order.message }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-center" v-show="pages">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="pageNumber"
                  :length="pages"
                  @input="onPageChange"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { auth, allOrders, userRef } from "@/firebase";
import { EventBus } from "@/event-bus.js";
import { jsPDF } from "jspdf";

export default {
  components: {},

  data() {
    return {
      printObj: {
        id: "printArea",
        preview: true,
        popTitle: "Zubene Shopping | skip the store",
      },
      printObjAdmin: {
        id: "printAreaAdmin",
        preview: true,
        popTitle: "Zubene Shopping | skip the store - Admin Report",
      },
      allOfPrice: 0,
      allOfShippingCost: 0,
      allOfAmountPaid: 0,
      allOfRemainingPaid: 0,
      numberOfAllOfTheOrders: 0,
      unsubUsers: null,
      unsubOrders: null,
      total: 0,
      pages: 0,
      perPage: 10,
      pageNumber: 1,
      orders: [],
      allOrders: [],
      userDataLoading: false,
      orderStatus: [
        "pending",
        "processing",
        "bought",
        "OnTheWay",
        "TurkeyOffice",
        "InDispatch",
        "shipped",
        "USAOffice",
        "DeliveredToErbil",
        "InBorder",
        "OutForDelivery",
        "completed",
        "onhold",
        "failed",
        "canceled",
      ],
      allSuccessOrders: [
        "processing",
        "bought",
        "OnTheWay",
        "InBorder",
        "OutForDelivery",
        "TurkeyOffice",
        "InDispatch",
        "shipped",
        "USAOffice",
        "DeliveredToErbil",
      ],
      userFiledSearch: [
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Name", value: "displayName" },
        { text: "Email", value: "email" },
      ],
      userFiledSearchSelected: "displayName",
      name: "",
      userSelected: "",
      snackbarInfo: false,
      snackbarText: "",
      snackbarColor: "success",
      unorderedList: [],
      userOrders: [],
      ordersCopy: [],
      products: true,
      userId: "",
      btnColor: "",
      snackbar: false,
      editMode: false,
      newProductName: "",
      indexToEdit: -1,
      userIdDelete: "",
      adminDisplayName: "",
      users: [],
      filteredUsers: [],
      progress: true,
      filterStatus: "",
      orderSearch: "",
      all: false,
    };
  },

  props: {
    query: {
      type: String,
      default: "pending",
    },
    page: {
      default: 1,
    },
  },

  mounted() {
    EventBus.$on("order-added", () => {
      this.saveOrder();
    });
  },

  computed: {
    catLength() {
      return this.$store.getters.length;
    },
    ...mapState(["userid"]),
  },
  methods: {
    createHeaders(keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: "center",
          padding: 0,
        });
      }
      return result;
    },

    downloadPdfOrdersForAdmin(orders) {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // console.log('Start Create PDF')
      // console.log(orders.length)

      var headers = this.createHeaders(
        [
          "id",
          "Customer",
          "Country",
          "ProductStatus",
          "TotalShipping",
          "PriceProduct",
          "Quantity",
          "TotalPrice",
          "AmountPaid",
          "Remaining",
        ],
        "center"
      );
      var getData = function(orders) {
        var result = [];

        for (var i = 0; i < orders.length; i += 1) {
          if (orders[i].quantity == 2) {
            // console.log('tryRate:',orders[i].tryRate );
            // console.log('shipping:',orders[i].totalShippingCost );
            // console.log('price:',orders[i].price );
          }
          const priceProduct =
            orders[i].currency === "Turkish lira"
              ? orders[i].price / orders[i].tryRate
              : orders[i].price.toFixed(2);
          const totalPrice =
            orders[i].currency === "Turkish lira"
              ? (
                  (orders[i].price * orders[i].quantity) / orders[i].tryRate +
                  orders[i].totalShippingCost
                ).toFixed(2)
              : (
                  orders[i].price * orders[i].quantity +
                  orders[i].totalShippingCost
                ).toFixed(2);
          result.push(
            Object.assign(
              {},
              {
                id: (i + 1).toString(),
                Customer:
                  orders[i].displayName != ""
                    ? orders[i].displayName.toString()
                    : "Not Set",
                Country: orders[i].country.toString(),
                // eslint-disable-next-line no-undef
                ProductStatus: orders[i].status,
                TotalShipping: orders[i].totalShippingCost.toString(),
                PriceProduct: `${priceProduct} $`,
                Quantity: orders[i].quantity.toString(),
                // new Date(orders[i].statusUpdated).toDateString(),
                TotalPrice: `${totalPrice} $`,
                AmountPaid: `${orders[i].amountPaid.toString()} $`,
                Remaining: `${orders[i].remainingPaid.toString()} $`,
              }
            )
          );
        }
        return result;
      };
      const calcTotalPrice = Number(this.allOfPrice);
      const totalCargo = (
        Number(this.allOfAmountPaid) +
        Number(this.allOfRemainingPaid) -
        Number(this.allOfPrice)
      ).toFixed(2);

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape" });
      doc.setFontSize(8);
      doc.text(20, 15, `${year}-${month}-${day}`);
      doc.setFontSize(20);
      doc.text(20, 30, "Zubene Shopping");
      doc.setFontSize(8);

      doc.text(20, 35, "Admin Invoice");
      doc.setFontSize(10);
      doc.text(70, 30, "skip the store");

      doc.setFontSize(10);
      // doc.text(20, 40, "Customer Name: " + this.displayNameUserOrder);
      // doc.text(70, 40, "Phone Number : +964" + this.phoneNumber);
      // doc.text(130, 40, "Address  : " + this.addressUserOrder);
      doc.text(
        5,
        45,
        "----------------------------------------------------------------------------------------------------"
      );
      doc.text(
        120,
        45,
        "-----------------------------------------------------------------------------------------------------------------------------------"
      );
      doc.setFontSize(10);
      doc.text(230, 15, "Total Price : " + calcTotalPrice.toFixed(2) + " $");
      doc.text(230, 20, "Total Cargo : " + totalCargo + " $");
      doc.text(
        230,
        25,
        "Total Paid : " + this.allOfAmountPaid.toFixed(2) + " $"
      );
      doc.text(
        230,
        30,
        "Total Remaining : " + this.allOfRemainingPaid.toFixed(2) + " $"
      );
      // doc.text(20,70 ,"-----------------------------------");
      doc.table(7, 60, getData(orders), headers, { autoSize: true });
      doc.save(`${year}-${month}-${day}` + ".pdf");
      // console.log('completePDF');
    },
    ReportUser(userId) {
      this.$router.push({
        name: "reportUserOrder",
        params: { uid: userId, page: "1" },
      });
    },
    printOrder(userId, orderId) {
      this.$router.push({
        name: "print",
        params: { uid: userId, orderId: orderId, page: "1" },
      });
      window.open(`/print/${userId}/${orderId}`);
      // console.log(userId);
      // console.log(orderId);
    },
    async userData() {
      // GET USER DATA
      this.userDataLoading = true;
      var userOrderRef = userRef.orderBy("displayName", "desc");
      this.unsubUsers = userOrderRef.onSnapshot(async (res) => {
        const userChanges = res.docChanges();
        userChanges.forEach((userChange) => {
          if (userChange.type === "added") {
            this.users.unshift({
              ...userChange.doc.data(),
              id: userChange.doc.id,
            });
          }
        });
      });
      this.userDataLoading = false;
    },

    async readData() {
      if (this.query === "deleted") {
        this.allOrdersCollection = allOrders.where("isDeleted", "==", true);
        this.all = false;
      } else if (this.query === "all") {
        this.allOrdersCollection = allOrders.where(
          "status",
          "in",
          this.allSuccessOrders
        );
        this.all = true;
      } else {
        this.allOrdersCollection = allOrders.where("status", "==", this.query);
        this.all = false;
      }
      this.unsubOrders = this.allOrdersCollection.onSnapshot((docs) => {
        const docsChanges = docs.docChanges();
        this.orders = [];
        this.ordersCopy = [];
        this.total = 0;
        docsChanges.forEach((res) => {
          const pureData = res.doc.data();
          const resType = res.type;
          let deleteItems = null;
          const accountingData = this.accountingModel(pureData);
          if (resType === "added") {
            this.accountingFunction(accountingData, deleteItems);
            this.allOrders.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });

            this.ordersCopy.unshift({
              // UNSHIF FOR ADD AT THE BEGINNING
              ...pureData,
              id: res.doc.id,
            });
            // console.log('------------------PURE DATA ');
            // console.log( this.ordersCopy);
          } else if (resType === "modified") {
            let index = null;
            let modifiedItemPreviousData = null;
            const list = this.allOrders;
            const modifiedItem = { ...pureData, id: res.doc.id };
            this.allOrders = this.allOrders.filter(function(item) {
              if (item.id === res.doc.id) {
                index = list.indexOf(item);
                modifiedItemPreviousData = item;
              }
              return item.id !== res.doc.id;
            });
            this.ordersCopy = this.ordersCopy.filter(function(item) {
              return item.id !== res.doc.id;
            });
            this.allOrders.splice(index, 0, modifiedItem);
            this.ordersCopy.splice(index, 0, modifiedItem);
            deleteItems = this.accountingModel(modifiedItemPreviousData);
            this.accountingFunction(accountingData, deleteItems);
          } else if (resType === "removed") {
            this.accountingFunction(deleteItems, accountingData);
            this.allOrders = this.allOrders.filter(function(item) {
              return item.id !== res.doc.id;
            });
            this.ordersCopy = this.ordersCopy.filter(function(item) {
              return item.id !== res.doc.id;
            });
          } else {
            console.log("Unknown");
          }
        });
        this.pageCheck();
        this.progress = false;
      });
    },
    onPageChange() {
      if (String(this.page) !== String(this.pageNumber)) {
        this.$router.push({
          name: "admindashboard",
          params: { query: this.query, page: this.pageNumber },
        });
      }
    },
    pageCheck() {
      // Get size of query
      this.pageNumber = Number(this.page);
      this.total = this.allOrders.length;
      // Round up result
      this.pages = Math.ceil(this.total / this.perPage);
      // Get size of query
      const start = this.perPage * (this.pageNumber - 1);
      const end = start + this.perPage;
      this.orders = this.allOrders.slice(start, end);
    },

    sendSMS(data) {
      console.log("message sent", data);
    },

    accountingModel(data) {
      const dataModel = {
        price: data.price,
        qty: data.quantity,
        shipping: data.shippingCost,
        totalShippingCost: data.totalShippingCost,
        paid: data.amountPaid,
        remaining: data.remainingPaid,
        currency: data.currency,
        rate: data.tryRate,
      };
      return dataModel;
    },

    accountingFunction(data, deleted) {
      if (data) {
        this.numberOfAllOfTheOrders += 1;
        if (data.price && data.currency === "Turkish lira") {
          const calc = (parseFloat(data.price) / parseFloat(data.rate)) * 100;
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(calc.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        } else if (data.price && data.currency) {
          this.allOfPrice = Number(
            (
              Number(this.allOfPrice.toFixed(2)) +
              Number(data.price.toFixed(2) * data.qty)
            ).toFixed(2)
          );
        }
        if (data.shipping) {
          this.allOfShippingCost = Number(
            (
              Number(this.allOfShippingCost.toFixed(2)) +
              Number(data.shipping.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.paid) {
          this.allOfAmountPaid = Number(
            (
              Number(this.allOfAmountPaid.toFixed(2)) +
              Number(data.paid.toFixed(2))
            ).toFixed(2)
          );
        }
        if (data.remaining) {
          this.allOfRemainingPaid = Number(
            (
              Number(this.allOfRemainingPaid.toFixed(2)) +
              Number(data.remaining.toFixed(2))
            ).toFixed(2)
          );
        }
      }
      if (deleted) {
        {
          this.numberOfAllOfTheOrders -= 1;
          if (deleted.price && deleted.currency === "Turkish lira") {
            const calc =
              (parseFloat(deleted.price) / parseFloat(deleted.rate)) * 100;
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(calc.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          } else if (deleted.price && deleted.currency) {
            this.allOfPrice = Number(
              (
                Number(this.allOfPrice.toFixed(2)) -
                Number(deleted.price.toFixed(2) * deleted.qty)
              ).toFixed(2)
            );
          }
          if (deleted.shipping) {
            this.allOfShippingCost = Number(
              (
                Number(this.allOfShippingCost.toFixed(2)) -
                Number(deleted.shipping.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.paid) {
            this.allOfAmountPaid = Number(
              (
                Number(this.allOfAmountPaid.toFixed(2)) -
                Number(deleted.paid.toFixed(2))
              ).toFixed(2)
            );
          }
          if (deleted.remaining) {
            this.allOfRemainingPaid = Number(
              (
                Number(this.allOfRemainingPaid.toFixed(2)) -
                Number(deleted.remaining.toFixed(2))
              ).toFixed(2)
            );
          }
        }
      }
    },

    openDialog() {
      EventBus.$emit("dialog", true);
    },

    async filterOrders(status) {
      this.progress = true;
      this.orders = this.ordersCopy;
      this.filterStatus = status;
      if (status === "completed") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "completed";
        });
      } else if (status === "pending") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "pending";
        });
      } else if (status === "InBorder") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "InBorder";
        });
      } else if (status === "OutForDelivery") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "OutForDelivery";
        });
      } else if (status === "failed") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "failed";
        });
      } else if (status === "processing") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "processing";
        });
      } else if (status === "bought") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "bought";
        });
      } else if (status === "canceled") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "canceled";
        });
      } else if (status === "onhold") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "onhold";
        });
      } else if (status === "OnTheWay") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "OnTheWay";
        });
      } else if (status === "TurkeyOffice") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "TurkeyOffice";
        });
      } else if (status === "shipped") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "shipped";
        });
      } else if (status === "InDispatch") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "InDispatch";
        });
      } else if (status === "USAOffice") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "USAOffice";
        });
      } else if (status === "DeliveredToErbil") {
        this.orders = this.orders.filter(function(item) {
          return item.status === "DeliveredToErbil";
        });
      }
      this.progress = false;
      await this.onPageChange();
      await this.pageCheck();
    },

    // Filter orders item by parameter (that used for filter products code)
    productCodeSearch(SearchItem) {
      this.orders = this.allOrders.filter((item) => {
        return item.productCode === SearchItem;
      });
    },

    // Filter orders item by users (that used for filter user by field)
    productUserSearch(SearchItem, byField) {
      this.filteredUsers = this.users.filter((item) => {
        return item[byField] === SearchItem[byField];
      });
    },

    changeStatus(index, userId, status) {
      if (this.query !== status) {
        const newStatus = status;
        const currentOrder = this.orders[index];
        const changeOrderStatusDocRef = userRef
          .doc(userId)
          .collection("orders")
          .doc(currentOrder.id);

        // LOGIC FOR STATUS UPDATE
        switch (newStatus) {
          case "pending":
            var btnColor = "info";
            break;

          case "processing":
            btnColor = "process";
            break;

          case "bought":
            btnColor = "buy";
            break;

          case "OnTheWay":
            btnColor = "way";
            break;

          case "InBorder":
            btnColor = "way";
            break;

          case "OutForDelivery":
            btnColor = "way";
            break;

          case "TurkeyOffice":
            btnColor = "turkey";
            break;

          case "InDispatch":
            btnColor = "InDispatch";
            break;
          case "shipped":
            btnColor = "shipped";
            break;

          case "USAOffice":
            btnColor = "usa";
            break;

          case "DeliveredToErbil":
            btnColor = "erbil";
            break;

          case "completed":
            btnColor = "success";
            break;

          case "failed":
            btnColor = "error";
            break;

          case "canceled":
            btnColor = "cancel";
            break;

          case "onhold":
            btnColor = "hold";
            break;

          default:
            break;
        }
        const date = new Date(Date.now());
        // UPDATE DATABASE
        changeOrderStatusDocRef.set(
          {
            status: newStatus,
            userUpdated: this.adminDisplayName,
            statusUpdated: date,
          },
          { merge: true }
        );
        if (index > -1) {
          this.allOrders.splice(index, 1);
          this.ordersCopy.splice(index, 1);
          this.orders.splice(index, 1);
        }
        // UPDATE LOCAL DATA
        currentOrder.status = newStatus;
        this.btnColor = btnColor;
      }
    },

    deleteOrder() {
      var index = this.indexToEdit;
      const currentOrder = this.orders[index];
      const deleteOrderRefDoc = userRef
        .doc(this.userIdDelete)
        .collection("orders")
        .doc(currentOrder.id);

      // DELETE ON DATABASE
      deleteOrderRefDoc
        .delete()
        .then(() => {
          this.snackbarText = `${currentOrder.product_name} has been successfully deleted`;
          this.snackbarColor = "success";
          this.snackbarInfo = true;
        })
        .catch((error) => {
          this.snackbarText = error.mesage;
          this.snackbarColor = "error";
          this.snackbarInfo = true;
        });

      // UPDATE ORDER ON LOCAL LIST
      if (index > -1) {
        this.orders.splice(index, 1);
      }
    },

    updateProductName(index, userId) {
      const currentOrder = this.orders[index];
      const updateOrderRefDoc = userRef
        .doc(userId)
        .collection("orders")
        .doc(currentOrder.id);

      const date = new Date(Date.now());

      updateOrderRefDoc.set(
        {
          product_name: this.newProductName,
          userUpdated: this.adminDisplayName,
          statusUpdated: date,
        },
        { merge: true }
      );

      // UPDATE LOCAL DATA
      currentOrder.product_name = this.newProductName;

      this.editMode = false;
    },
  },

  async created() {
    const userAuth = auth;
    await userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.adminDisplayName = user.displayName;
        this.userId = user.uid;
      }
    });
    // GET DATA
    this.readData();
    this.progress = false;
  },
  beforeDestroy() {
    if (this.unsubUsers) {
      this.unsubUsers();
      this.unsubUsers = null;
    }
    if (this.unsubOrders) {
      this.unsubOrders();
      this.unsubOrders = null;
    }
  },
  watch: {
    async $route(to, from) {
      // react to route changes...
      if (to.params.query === from.params.query) {
        this.pageCheck();
      } else {
        if (this.unsubUsers) {
          this.unsubUsers();
        }
        if (this.unsubOrders) {
          this.unsubOrders();
        }
        this.allOrders = [];
        this.ordersCopy = [];
        this.orders = [];
        this.allOfPrice = 0;
        this.allOfShippingCost = 0;
        this.allOfAmountPaid = 0;
        this.allOfRemainingPaid = 0;
        this.numberOfAllOfTheOrders = 0;
        this.readData();
      }
    },
  },
};
</script>

<style>
.for-print {
  display: none;
}
.order.completed {
  border-left: 4px solid #00897b;
}
.order.failed {
  border-left: 4px solid #f83e70;
}
.order.processing {
  border-left: 4px solid #ab47bc;
}
.order.pending {
  border-left: 4px solid #ffa726;
}
.order.canceled {
  border-left: 4px solid #8d6e63;
}
.order.OnTheWay {
  border-left: 4px solid #006064;
}
.order.InBorder {
  border-left: 4px solid #006064;
}
.order.OutForDelivery {
  border-left: 4px solid #006064;
}
.order.DeliveredToErbil {
  border-left: 4px solid #00c853;
}
.order.TurkeyOffice {
  border-left: 4px solid #e30a17;
}
.order.InDispatch {
  border-left: 4px solid #54618c;
}
.order.shipped {
  border-left: 4px solid #6be4ff;
}

.order.USAOffice {
  border-left: 4px solid #3c3b6e;
}
.order.onhold {
  border-left: 4px solid #bdbdbd;
}

.theme--light.v-btn:not(.v-btn--icon).completed {
  background-color: #00897b;
}

.theme--light.v-btn:not(.v-btn--icon).failed {
  background-color: #f83e70;
}

.theme--light.v-btn:not(.v-btn--icon).processing {
  background-color: #ab47bc;
}

.theme--light.v-btn:not(.v-btn--icon).pending {
  background-color: #ffaa2c;
}

.theme--light.v-btn:not(.v-btn--icon).InBorder {
  background-color: #0d0669;
}

.theme--light.v-btn:not(.v-btn--icon).order.OutForDelivery {
  background-color: #383386;
}

.theme--light.v-btn:not(.v-btn--icon).canceled {
  background-color: #8d6e63;
}

.theme--light.v-btn:not(.v-btn--icon).OnTheWay {
  background-color: #006064;
}

.theme--light.v-btn:not(.v-btn--icon).DeliveredToErbil {
  background-color: #00c853;
}

.theme--light.v-btn:not(.v-btn--icon).TurkeyOffice {
  background-color: #e30a17;
}

.theme--light.v-btn:not(.v-btn--icon).InDispatch {
  background-color: #54618c;
}
.theme--light.v-btn:not(.v-btn--icon).shipped {
  background-color: #6be4ff;
}
.theme--light.v-btn:not(.v-btn--icon).USAOffice {
  background-color: #3c3b6e;
}

.theme--light.v-btn:not(.v-btn--icon).onhold {
  background-color: #bdbdbd;
}

.theme--light.v-btn.completed {
  color: #00897b;
}

.theme--light.v-btn.failed {
  color: #f83e70;
}

.theme--light.v-btn.processing {
  color: #ab47bc;
}

.theme--light.v-btn.pending {
  color: #ffaa2c;
}

.theme--light.v-btn.InBorder {
  color: #0d0669;
}

.theme--light.v-btn.order.OutForDelivery {
  color: #1506e4;
}

.theme--light.v-btn.canceled {
  color: #8d6e63;
}

.theme--light.v-btn.OnTheWay {
  color: #006064;
}

.theme--light.v-btn.DeliveredToErbil {
  color: #00c853;
}

.theme--light.v-btn.TurkeyOffice {
  color: #e30a17;
}

.theme--light.v-btn.InDispatch {
  color: #54618c;
}
.theme--light.v-btn.shipped {
  color: #6be4ff;
}

.theme--light.v-btn.USAOffice {
  color: #3c3b6e;
}

.theme--light.v-btn.onhold {
  color: #bdbdbd;
}

.handle {
  cursor: move;
}

.routerLink {
  text-decoration: none;
}

.v-progress-circular {
  margin: 1rem;
}
</style>
