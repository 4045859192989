<template>
  <div class="Profile">
    <v-banner
      icon="mdi-account-cog"
      single-line
      class="subtitle-1 text-capitalize grey--text"
      >Profile</v-banner
    >
    <v-container class="pb-10">
      <v-snackbar v-model="snackbar" dark :timeout="3000" :color="color" text>
        <span>{{ snackbarMessage }}</span>
        <v-btn text color="white" @click="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-row align="center" justify="center">
        <v-card max-width="550" class="elevation-5">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>Profile</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              @submit.prevent="updateProfile()"
              ref="updateProfileForm"
              lazy-validation
              v-model="valid"
            >
              <v-row align="center" justify="center">
                <v-avatar size="128" class="avatar mb-6">
                  <v-img :src="photoURL" class="userAvatar" @click="selectFile">
                    <template v-slot:placeholder>
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular> </template
                  ></v-img>
                </v-avatar>
              </v-row>

              <input
                type="file"
                ref="uploadInput"
                id="files"
                accept="image/*"
                :multiple="false"
                @change="detectFiles($event)"
              />
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  prepend-icon="mdi-account-circle"
                  label="Name"
                  v-model="userName"
                  color="primary"
                  :rules="[rules.required, rules.name]"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  prepend-icon="mdi-cellphone"
                  prefix="+964 (0)"
                  name="phoneNumber"
                  v-model="phoneNumber"
                  label="Mobile number"
                  clearable
                  type="text"
                  hint="Mobile number must be 10 digits 712 345 67 89"
                  persistent-hint
                  :rules="[rules.required, rules.min, rules.number]"
                  clear-icon="mdi-close-circle"
                  required
                  max="11"
                  counter
                  @click:clear="clearPhone"
                  disabled
                ></v-text-field>
              </v-col>
                   <v-col cols="12" sm="12" md="12">
                <v-text-field
                  prepend-icon="mdi-credit-card"
                  prefix="$"
                  name="credit"
                  v-model="credit"
                  label="Credit User"
                  clearable
                  type="text"
                 
                  persistent-hint
                
                  clear-icon="mdi-close-circle"
         
               
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  prepend-icon="mdi-lock"
                  autocomplete
                  type="password"
                  label="New password"
                  v-model="newPassword"
                  color="primary"
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  prepend-icon="mdi-lock"
                  type="password"
                  label="Confirm new password"
                  autocomplete
                  v-model="newPassword2"
                  color="primary"
                  :rules="confirmPasswordRules"
                ></v-text-field>
              </v-col>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="primary"
              @click="updateProfile()"
              :loading="loading"
              >Save <v-icon right>mdi-content-save</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import { auth, userRef, storage } from "@/firebase";

export default {
  data() {
    return {
      user: "",
      userId: "",
      userName: "",
      photoURL: "",
      newPassword: "",
      newPassword2: "",
      credit:"",
      snackbar: false,
      phoneNumber: "",
      valid: "",
      rules: {
        number: (value) => Number.isInteger(Number(value)) || "Must be number",
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length == 10) || "Mobile number is 10 digits",
        name: (v) => (v && v.length > 3) || "Must be more than 3 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      snackbarMessage: "",
      loading: false,
      color: "",
      passwordRules: [
        (value) =>
          (value && value.length >= 6) ||
          value.length == 0 ||
          "Password should be at least 6 characters",
      ],
      confirmPasswordRules: [
        (value) =>
          value === this.newPassword ||
          "The password confirmation does not match.",
      ],
    };
  },

  methods: {
    clearPhone() {
      this.phoneNumber = "";
    },
    async updateProfile() {
      if (this.$refs.updateProfileForm.validate()) {
        this.loading = true;
        if (this.userName && this.photoURL && this.phoneNumber) {
          this.user
            .updateProfile({
              displayName: this.userName,
              photoURL: this.photoURL,
              phoneNumber: this.phoneNumber,
            })
            .then(async () => {
              const userRefCollection = userRef;

              this.information = {
                displayName: this.userName,
                phoneNumber: this.phoneNumber,
                photoURL: this.photoURL,
              };
              await userRefCollection
                .doc(this.userId)
                .set(this.information, { merge: true });
              if (this.newPassword.length > 6 && this.newPassword2.length > 6) {
                if (this.newPassword !== this.newPassword2) {
                  this.loading = false;
                  this.snackbarMessage = "Passwords do not match";
                  this.snackbar = true;
                } else {
                  this.user
                    .updatePassword(this.newPassword)
                    .then(() => {
                      // IF OK -> UPDATE PASSWORD AND PROFILE
                      this.user.updateProfile(this.information).then(() => {
                        this.snackbarMessage = "Password updated successfully";
                        this.color = "success";
                        this.snackbar = true;
                      });
                    })
                    .catch((error) => {
                      this.loading = false;
                      var errorMessage = error.message;
                      this.snackbarMessage = errorMessage;
                      this.color = "error";
                      this.snackbar = true;
                    });
                }
              }
              this.snackbarMessage = "Profile updated successfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.$router.go();
            });
        } else {
          this.snackbarMessage = "Please check your information";
          this.color = "error";
          this.snackbar = true;
          this.loading = false;
        }
      }
    },

    // open dialog box to select file
    selectFile() {
      this.$refs.uploadInput.click();
    },

    detectFiles(e) {
      // Put selected file in a array
      this.loading = true;
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },

    upload(file) {
      // Create a root reference
      var storageRef = storage;

      // Create a reference to 'images/mountains.jpg'
      var avatarImagesRef = storageRef.child("images/profiles/" + this.userId);

      // Upload
      avatarImagesRef.put(file).then(() => {
        avatarImagesRef
          .getDownloadURL()
          .then((url) => {
            this.loading = false;
            this.photoURL = url;
          })
          .catch((error) => {
            alert(error.message);
          });
      });
    },
  },

  created() {
    const userAuth = auth;
    userAuth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.userId = user.uid;
        const userRefCollection = userRef.doc(this.userId).get();
        await userRefCollection.then((doc) => {
          this.phoneNumber = doc.data().phoneNumber;
            this.credit=doc.data().credit;
        });
        this.userName = user.displayName;
        this.email = user.email;
        if (user.photoURL) {
          this.photoURL = user.photoURL;
        } else {
          this.photoURL = "https://imgur.com/dLB4u3s.png";
        }
      } else {
        //
      }
    });
  },
};
</script>
<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.userAvatar {
  transition: opacity 0.2s ease-in-out;
}

.userAvatar:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}
</style>

