<template>
  <v-footer fixed padless color="primary lighten">
    <v-row justify="center" no-gutters>
      <div align="center">
        <v-btn
          v-for="link in links"
          :key="link.title"
          color="white"
          text
          rounded
          class="caption"
          :href="link.link"
        >
          <v-icon left> {{ link.icon }} </v-icon>
          {{ link.title }}
        </v-btn>
      </div>
      <v-col
        class="primary lighten-1 text-center white--text caption"
        cols="12"
      >
        &copy;{{ new Date().getFullYear() }} —
        <strong
          ><v-btn color="white" text small href="https://datacode.app"
            >DATACODE
          </v-btn></strong
        >
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { title: "Home", icon: "mdi-home", link: "https://zubene.co/" },
      { title: "Links", icon: "mdi-link", link: "https://zubene.co/#stores" },
      {
        title: "About Us",
        icon: "mdi-information-variant",
        link: "https://zubene.co/#services",
      },
      {
        title: "Contact Us",
        icon: "mdi-map-marker",
        link: "https://zubene.co/#contact",
      },
    ],
  }),
};
</script>