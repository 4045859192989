<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" dark>
          <span v-if="userdata" class="font-weight-black caption text-capitalize"
            >Add New Order To {{ userdata.displayName }}</span
          >
          <span v-else>New Order</span>
          <v-icon right>mdi-cart-plus</v-icon>
        </v-btn>
      </template>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="pb-4 px-4">
          <v-card-title>
            <span class="headline">New Orders Are Stopped</span>
          </v-card-title>
          <v-card-text>
            <small>Please use the new Zubene App (Web & Mobile), this system will be dicountinued</small>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-card flat tile class="mx-2">
                  <v-btn rounded color="error darken-1" @click="dialog = false">
                    <v-icon left> mdi-close-box </v-icon> Close
                  </v-btn>
                </v-card>
                <v-card flat tile class="mx-2">
                  <v-btn rounded color="green white--text" @click="redirectUser">
                    <v-icon left> mdi-eraser </v-icon> New Zubene App
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>


<script>
import { rateRef, userRef, storage, auth } from "@/firebase";
import moment from "moment";

export default {
  components: {},
  props: { userdata: { type: Object, required: false } },
  data: () => ({
    snackbarInfo: false,
    snackbarText: "",
    snackbarColor: "",
    user: "",
    userId: null,
    displayName: null,
    loading: false,
    dialog: false,
    valid: true,
    tryRate: 0,
    tryRateBU: 0,
    defaultButtonText: "Select Product Image",
    selectedFile: "",
    priceRules: [
      (v) =>
        Number.isInteger(v) || Number.isFinite(v) || !v || "Must be number",
    ],
    urlRules: [
      (v) => !!v || "Url is required",
      (v) => (v && v.length >= 5) || "Please input valid url",
      (v) => (v && v.length <= 2048) || "URL must be less than 2048 characters",
      /* eslint-disable-next-line no-useless-escape*/ (v) =>
        /(h|H)(t|T)(t|T)(p|P)(s|S)?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/.test(
          v
        ) || "URL must be valid",
    ],
    countryRules: [
      (v) => !!v || "Country name is required",
      (v) =>
        (v && v == "Turkey") || v == "USA" || "Please input valid country name",
    ],
    url: "",
    flag: false,
    price: 0,
    currencies: ["Turkish lira", "US Dollar"],
    currency: "",
    productCode: "",
    quantity: "",
    description: "",
    email: "",
    phoneNumber: "",
    photoUrl: "",
    address: "",
    credit:0,
    productImageURL: "",
    productImage: "",
    productSize: "",
    productColor: "",
    country: "",
    imageId: "",
    countries: ["Turkey", "USA"],
    userInfo: "",
    sentSMS: "",
    receivedSMS: "",
  }),
  methods: {
    redirectUser() {
      window.location.href = 'https://app.zubene.co/';
      // Additional logic can be added here later if needed
    },
    async add_multi() {
      if (this.$refs.form.validate()) {
        this.flag = true;
        const date = new Date(Date.now());
        moment(date).format("dddd, MMMM Do YYYY");
        this.loading = true;
        const orderRef = userRef.doc(this.userId).collection("orders").doc();
        const ref = orderRef.id;
        if (await this.selectedFile) {
          await this.upload(this.selectedFile, ref);
        }
        const order = {
          product_name: "",
          website: "",
          url: this.url,
          currency: this.currency,
          userId: this.userId,
          displayName: this.displayName,
          credit:this.credit,
          products: "",
          description: this.description,
          status: "pending",
          quantity: this.quantity,
          productCode: this.productCode,
          submit_date: date,
          statusUpdated: date,
          email: this.email,
          phoneNumber: this.phoneNumber,
          photoUrl: this.photoUrl,
          productImageURL: this.productImageURL,
          productSize: this.productSize,
          productColor: this.productColor,
          country: this.country,
          address: this.address,
          birthdate: this.birthdate,
          store: "zubene",
          message: "",
          notes: "",
          price: 0,
          amountPaid: 0,
          shippingCost: 0,
          totalShippingCost: 0,
          remainingPaid: 0,
          categories: "",
          tags: "",
          weight: 0,
          length: 0,
          height: 0,
          width: 0,
          coupon: "",
          tax: 0,
          insurance: 0,
          discountCode: "",
          userUpdated: "Customer",
          tryRate: this.tryRate,
          isDeleted: false,
          isConfirmed: "NotSet",
          newMessage: false,
          sentSMS: "",
          receivedSMS: "",
          rateUnsub: null,
        };
        await orderRef
          .set(order)
          .then(() => {
            this.snackbarText = "Awesome! You added a new product";
            this.snackbarColor = "success";
            this.snackbarInfo = true;
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = error.message;
            this.snackbarColor = "error";
            this.snackbarInfo = true;
          });
        await this.$emit("orderAdded", {
          snackbarText: this.snackbarText,
          snackbarColor: this.snackbarColor,
          snackbarInfo: this.snackbarInfo,
        });
        this.loading = false;
        this.reset();
        return true;
      }
    },
    async add() {
      await this.add_multi();
      if (this.flag) {
        this.flag = true;
        this.dialog = false;
      }
    },
    reset() {
      this.$refs.form.reset();
      this.product_name = "";
      this.website = "";
      this.url = "";
      this.currency = "";
      this.description = "";
      this.snackbarInfo = false;
      this.snackbarText = "";
      this.snackbarColor = "";
      this.quantity = 1;
      this.productCode = "";
      this.productSize = "";
      this.productColor = "";
      this.productImageURL = "";
      this.productImage = "";
      this.country = "";
      this.selectedFile = "";
      this.imageId = "";
      this.tryRate = this.tryRateBU;
    },

    selectFile() {
      this.$refs.uploadInput.click();
    },

    detectFiles(e) {
      this.loading = true;
      const file = e.target.files[0];
      if (!file) {
        e.preventDefault();
        this.loading = false;
        return;
      }
      if (file.size > 2048 * 1024) {
        e.preventDefault();
        alert("File too big (> 2MB)");
        this.loading = false;
        return;
      }
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.selectedFile = fileList[x];
        this.productImage = URL.createObjectURL(file);
        this.loading = false;
      });
    },

    async upload(file, refDoc) {
      // Create a root reference
      var storageRef = storage;

      // Create a reference to 'images/mountains.jpg'
      var productImagesRef = await storageRef.child(
        "images/" + this.userId + "/productImages/" + refDoc
      );
      // Upload
      await productImagesRef.put(file).then(async () => {
        await productImagesRef
          .getDownloadURL()
          .then((url) => {
            this.productImageURL = url;
            return true;
          })
          .catch((error) => {
            this.snackbarText = error.message;
            this.snackbarColor = "error";
            this.snackbarInfo = true;
            return false;
          });
      });
    },
  },
  created() {
    const userAuth = auth;
    userAuth.onAuthStateChanged(async (user) => {
      if (user) {
        // Get turkish lira exchange rate
        const reteRefDoc = rateRef;
        this.rateUnsub = await reteRefDoc.onSnapshot((res) => {
          if (res.exists) {
            this.tryRate = res.data().rate;
            this.tryRateBU = this.tryRate;
          }
        });
        this.userInfo = await this.userdata;
        const userInfo = this.userInfo;
        if (userInfo) {
          this.address = userInfo.address;
          this.address = userInfo.address ? userInfo.address : "";
          this.birthdate = userInfo.birthdate ? userInfo.birthdate : "";
          this.phoneNumber = userInfo.phoneNumber ? userInfo.phoneNumber : "";
          this.user = userInfo;
          this.displayName = userInfo.displayName ? userInfo.displayName : "";
          this.email = userInfo.email ? userInfo.email : null;
          this.credit=userInfo.credit ? userInfo.credit : 0;
          this.photoUrl = userInfo.photoURL
            ? userInfo.photoURL
            : "https://imgur.com/dLB4u3s.png";
          this.userId = userInfo.uid;
        } else {
          // get user information
          const userRefDoc = userRef.doc(user.uid);
          userRefDoc
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                this.address = data.address ? data.address : "";
                this.birthdate = data.birthdate ? data.birthdate : "";
                this.phoneNumber = data.phoneNumber ? data.phoneNumber : "";
                this.user = user;
                this.displayName = data.displayName ? data.displayName : "";
                this.credit= data.credit ? data.credit : 0;
                this.email = data.email ? data.email : null;
                this.photoUrl = data.photoURL
                  ? data.photoURL
                  : "https://imgur.com/dLB4u3s.png";
                this.userId = user.uid;
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        }
      }
    });
  },
  beforeDestroy() {
    if (this.rateUnsub) {
      this.rateUnsub();
      this.rateUnsub = null;
    }
  },
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },
};
</script>

<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.userAvatar {
  transition: opacity 0.2s ease-in-out;
}

.userAvatar:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: pointer;
}
</style>