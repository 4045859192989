import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#e6691f",
        secondary: "#58595b",
        accent: "#ff9800",
        warning: "#ffc107",
        background: "#F5F5F5",
        success: '#00897B',
        info: '#ffaa2c',
        error: '#f83e70',
        completes: '#00897B',
        ongoings: '#ffaa2c',
        overdues: '#f83e70',
        process: '#ab47bc',
        cancel: '#8d6e63',
        erbil: '#00C853',
        way: '#006064',
        turkey: '#E30A17',
        hold: '#bdbdbd',
        usa: '#3C3B6E',
        buy: '#1E88E5',
        accounting: '#26c6da',
        grey: '#9E9E9E',
        disabled: '#FAFAFA'
      },
      dark: {
        primary: '#58595b',
        secondary: "#37474F",
        accent: "#ff9800",
        warning: "#ffc107",
        background: "#424242",
        success: '#00897B',
        info: '#ffaa2c',
        error: '#f83e70',
        completes: '#00897B',
        ongoings: '#ffaa2c',
        overdues: '#f83e70',
        process: '#ab47bc',
        cancel: '#8d6e63',
        erbil: '#00C853',
        turkey: '#E30A17',
        hold: '#bdbdbd',
        usa: '#3C3B6E',
        buy: '#1E88E5',
        grey: '#BDBDBD',
        disabled: '#424242',
        accounting: '#006064',
      }
    },
  },
});
