<template>
  <nav>
    <div align="center" justify="end" v-show="checkbox">
      <v-card outlined class="d-inline-flex align-center">
        <v-alert
          dense
          class="px-5 mb-0"
          v-model="checkbox"
          dismissible
          color="cyan"
          border="left"
          colored-border
          icon="mdi-information"
          transition="scroll-y-transition"
        >
          {{ message }}
        </v-alert>
      </v-card>
    </div>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbarInfo" :timeout="4000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarInfo = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-app-bar class="mb-2" dense flat color="transparent">
      <v-app-bar-nav-icon
        large
        color="primary"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-img
        class="ml-2"
        width="90"
        height="100"
        min-width="100"
        max-width="120"
        :src="require('@/assets/logo_nav.svg')"
      />
      <!-- Add new order -->
      <v-col><Popup @orderAdded="snackbarTextPop($event)" /></v-col>
    </v-app-bar>
    <v-divider class="mb-2"></v-divider>
    <!-- Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      color="primary"
      :mini="drawer"
      :permanent="$vuetify.breakpoint.smAndUp"
      app
      :expand-on-hover="$vuetify.breakpoint.smAndUp"
      dark
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img :src="photoURL"></v-img>
          </v-list-item-avatar>

          <v-list-item-title>{{ displayName }}</v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-group
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            :no-action="item.subMenu"
            color="text--white"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              :to="{
                name: pathName,
                params: { query: child.route.params.query, page: 1 },
              }"
              v-show="child.roles.includes(role)"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-list dense>
          <v-list-item
            v-for="item in listItems"
            :key="item.title"
            link
            :to="item.route"
            v-show="item.roles.includes(role)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <CurrencyRete
            v-if="role === 'admin'"
            @exchangeRatePopup="snackbarTextPop($event)"
          />
          <AnnouncementBar
            v-if="role === 'admin'"
            @announacmentBarPopup="snackbarTextPop($event)"
          />
        </v-list>
      </v-list>

      <v-list>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon v-if="!$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
            <v-icon v-if="$vuetify.theme.dark">mdi-moon-waxing-crescent</v-icon>
          </v-list-item-icon>

          <v-switch
            color="red"
            dense
            :label="$vuetify.theme.dark ? 'Dark' : 'Light'"
            v-model="$vuetify.theme.dark"
            inset
          ></v-switch>
        </v-list-item>
      </v-list>
      <!-- Sign out -->
      <template v-slot:append>
        <v-list dense>
          <v-divider></v-divider>
          <v-list-item @click="signout">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Sign Out </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {
  auth,
  announcementBarUSA,
  announcementBarTurkey,
  announcementBarCustomer,
} from "@/firebase";
import CurrencyRete from "@/components/CurrencyRete";
import AnnouncementBar from "@/components/AnnouncementBar";
import Popup from "@/components/Popup";

export default {
  components: {
    CurrencyRete,
    AnnouncementBar,
    Popup,
  },
  data: () => ({
    message: "",
    checkbox: false,
    pathName: "",
    snackbarInfo: "",
    snackbarText: "",
    snackbarColor: "",
    show: true,
    user: "",
    userId: "",
    photoURL: "",
    email: "",
    displayName: "",
    address: "",
    birthdate: "",
    drawer: false,
    role: "",
    items: [
      {
        action: "mdi-list-status",
        active: true,
        subMenu: true,
        title: "Orders",
        roles: ["admin", "usa", "turkey", "customer"],
        route: { name: "dashboard", params: { query: "pending" } },
        items: [
          {
            title: "Pending",
            icon: "mdi-timer-sand",
            roles: ["admin", "customer"],
            route: { name: "dashboard", params: { query: "pending" } },
          },
          {
            title: "Processing",
            icon: "mdi-autorenew",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "processing" } },
          },
          {
            title: "Bought",
            icon: "mdi-credit-card-check",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "bought" } },
          },
          {
            title: "On The Way",
            icon: "mdi-truck-fast",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "OnTheWay" } },
          },
          {
            title: "In Border",
            icon: "mdi-passport-biometric",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "InBorder" } },
          },
           {
            title: "Out for Delivery",
            icon: "mdi-truck-delivery",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "OutForDelivery" } },
          },
          {
            title: "Turkey Office",
            icon: "mdi-city-variant",
            roles: ["admin", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "TurkeyOffice" } },
          },
          {
            title: "In Dispatch",
            icon: "mdi-check-all",
            roles: ["admin", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "InDispatch" } },
          },
          {
            title: "Shipped",
            icon: "mdi-cube-send",
            roles: ["admin", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "shipped" } },
          },
         

          {
            title: "USA Office",
            icon: "mdi-star-circle",
            roles: ["admin", "usa", "customer"],
            route: { name: "dashboard", params: { query: "USAOffice" } },
          },
          {
            title: "Delivered to Erbil",
            icon: "mdi-office-building-marker",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "DeliveredToErbil" } },
          },
          {
            title: "Completed",
            icon: "mdi-check-circle",
            roles: ["admin", "customer"],
            route: { name: "dashboard", params: { query: "completed" } },
          },
          {
            title: "Failed",
            icon: "mdi-information",
            roles: ["admin", "customer"],
            route: { name: "dashboard", params: { query: "failed" } },
          },
          {
            title: "Canceled",
            icon: "mdi-close-circle",
            roles: ["admin", "customer"],
            route: { name: "dashboard", params: { query: "canceled" } },
          },
          {
            title: "Out Of Stock",
            icon: "mdi-pause-circle",
            roles: ["admin", "usa", "turkey", "customer"],
            route: { name: "dashboard", params: { query: "onhold" } },
          },
          {
            title: "All",
            icon: "mdi-all-inclusive",
            roles: ["admin", "customer"],
            route: { name: "dashboard", params: { query: "all" } },
          },
          {
            title: "Deleted",
            icon: "mdi-delete",
            roles: ["admin"],
            route: { name: "dashboard", params: { query: "deleted" } },
          },
        ],
      },
    ],
    listItems: [
      {
        title: "Shipping Calculator",
        action: "mdi-calculator-variant",
        subMenu: false,
        route: { name: "shippingcalculator" },
        roles: ["admin", "usa", "turkey", "customer"],
      },
      {
        title: "Profile",
        action: "mdi-account-cog",
        subMenu: false,
        route: { name: "profile" },
        roles: ["admin", "usa", "turkey", "customer"],
      },
      {
        title: "Roles",
        action: "mdi-account-lock",
        subMenu: false,
        route: { name: "adminroles" },
        roles: ["admin"],
      },
    ],
    mini: true,
  }),
  async created() {
    await this.getUserStatus();
    await this.getAnnouncementBar();
  },
  computed: {
    listMenuRoles() {
      return this.listItems.filter((item) => item.roles.includes(this.role));
    },
  },
  methods: {
    redirectUser() {
      window.location.href = 'https://app.zubene.co/';
      // Additional logic can be added here later if needed
    },
    async getUserStatus() {
      const userAuth = auth;
      await userAuth.onAuthStateChanged(async (user) => {
        if (user) {
          await userAuth.currentUser.getIdTokenResult().then((tokenResult) => {
            if (tokenResult.claims.admin) {
              this.role = "admin";
              this.pathName = "admindashboard";
            } else if (tokenResult.claims.usa) {
              this.role = "usa";
              this.pathName = "colleaguedashboard";
            } else if (tokenResult.claims.turkey) {
              this.role = "turkey";
              this.pathName = "colleaguedashboard";
            } else if (tokenResult.claims.customer) {
              this.role = "customer";
              this.pathName = "dashboard";
            }
            return true;
          });
          this.user = user;
          this.userId = user.uid;
          this.displayName = user.displayName;
          this.email = user.email;
          this.birthdate = user.birthdate;
          this.address = user.address;

          if (user.photoURL) {
            this.photoURL = user.photoURL;
          } else {
            this.photoURL = "https://imgur.com/dLB4u3s.png";
          }
          return true;
        } else {
          //
        }
      });
    },

    async getAnnouncementBar() {
      // Get previous data
      let customerRefCollection = null;
      let turkeyRefCollection = null;
      let usaRefCollection = null;
      if (this.role === "customer") {
        customerRefCollection = await announcementBarCustomer.get();
        if (customerRefCollection.exists) {
          this.message = customerRefCollection.data().announacmentMessage;
          this.checkbox = customerRefCollection.data().announacmentStatus;
        }
      } else if (this.role === "turkey") {
        turkeyRefCollection = await announcementBarTurkey.get();
        if (turkeyRefCollection.exists) {
          this.message = turkeyRefCollection.data().announacmentMessage;
          this.checkbox = turkeyRefCollection.data().announacmentStatus;
        }
      } else if (this.role === "usa") {
        usaRefCollection = await announcementBarUSA.get();
        if (usaRefCollection.exists) {
          this.message = usaRefCollection.data().announacmentMessage;
          this.checkbox = usaRefCollection.data().announacmentStatus;
        }
      }
      return true;
    },

    signout(e) {
      e.stopPropagation();
      const userAuth = auth;
      userAuth
        .signOut()
        .then(() => {
          this.$router.push({ name: "userauth" });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    snackbarTextPop(val) {
      this.snackbarText = val.snackbarText;
      this.snackbarColor = val.snackbarColor;
      this.snackbarInfo = val.snackbarInfo;
    },
  },
};
</script>