<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          small
          color="primary"
          class="white--text ml-1"
          v-bind="attrs"
          v-on="on"
        >
          <span class="caption">Reset Password</span>
          <v-icon small right>mdi-lock-question</v-icon>
        </v-btn>
      </template>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    prepend-icon="mdi-email"
                    label="Email*"
                    hint="Please enter your email address to reset your password"
                    persistent-hint
                    required
                    clearable
                    clear-icon="mdi-close-circle"
                    v-model="email"
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="resetPassword"
              :loading="loading"
            >
              Reset password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>


<script>

import { auth } from "@/firebase";

export default {
  components: {},
  data: () => ({
    loading: false,
    dialog: false,
    valid: true,
    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email.";
      },
    ],
  }),
  methods: {
    async resetPassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        var message = "";
        var success = false;
        var userAuth = auth;
        var emailAddress = this.email;
        await userAuth
          .sendPasswordResetEmail(emailAddress)
          .then(function () {
            // Email sent.
            message =
              "We have successfully sent to your email the link to reset your password";
            success = true;
          })
          .catch(function (error) {
            // An error happened.
            message = error.message;
          });
        await this.$emit("resetPasswordPopup", {
          snackbarText: message,
          snackbarSuccess: success,
        });
        this.loading = false;
        this.dialog = false;
        this.email = "";
        return true;
      }
    },
  },
};
</script>

<style>
</style>