<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
      close-delay
      open-delay
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-icon class="mx-3">
            <v-icon>mdi-bullhorn</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="mx-5">
              Announcment Bar
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="grey lighten-4 theme--light"
      >
        <v-card color="transparent" class="px-5 py-5">
          <v-card-title>
            <span class="headline">Announcment Bar</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-card flat color="transparent">
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="CustomerMessage"
                          counter
                          clearable
                          solo
                          maxlength="80"
                          hint="This message will be displayed in the Customers panel"
                          persistent-hint
                          label="Customer"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="TurkeyMessage"
                          counter
                          solo
                          clearable
                          maxlength="80"
                          hint="This message will be displayed in the Turkey Colleagues panel"
                          persistent-hint
                          label="Turkey Colleagues"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="USAMessage"
                          counter
                          clearable
                          solo
                          maxlength="80"
                          hint="This message will be displayed in the USA Colleagues panel"
                          persistent-hint
                          label="USA Colleagues"
                        ></v-text-field>
                      </v-col>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="4" md="4">
                          <v-switch
                            inset
                            v-model="CustomerCheckbox"
                            label="Customers"
                            color="green"
                          ></v-switch>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-switch
                            inset
                            v-model="TurkeyCheckbox"
                            label="Turkey Colleagues"
                            color="green"
                          ></v-switch>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-switch
                            inset
                            v-model="USACheckbox"
                            label="USA Colleagues"
                            color="green"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red white--text" @click="close"> Close </v-btn>
            <v-spacer></v-spacer
            ><v-btn
              :disabled="!valid"
              color="green white--text"
              @click="announcementBarUpadate"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  announcementBarCustomer,
  announcementBarUSA,
  announcementBarTurkey,
  auth,
} from "@/firebase";

export default {
  components: {},
  data: () => ({
    loading: false,
    dialog: false,
    valid: true,
    USAMessage: "",
    TurkeyMessage: "",
    CustomerMessage: "",
    USACheckbox: false,
    TurkeyCheckbox: false,
    CustomerCheckbox: false,
    user: "",
    adminDisplayName: "",
    userId: "",
  }),
  methods: {
    close() {
      this.getData();
      this.dialog = false;
    },
    async getData() {
      // Get previous data
      const customerRefCollection = await announcementBarCustomer.get();
      const turkeyRefCollection = await announcementBarTurkey.get();
      const usaRefCollection = await announcementBarUSA.get();

      if (customerRefCollection.exists) {
        this.CustomerMessage = customerRefCollection.data().announacmentMessage;
        this.CustomerCheckbox = customerRefCollection.data().announacmentStatus;
      }
      if (turkeyRefCollection.exists) {
        this.TurkeyMessage = turkeyRefCollection.data().announacmentMessage;
        this.TurkeyCheckbox = turkeyRefCollection.data().announacmentStatus;
      }
      if (usaRefCollection.exists) {
        this.USAMessage = usaRefCollection.data().announacmentMessage;
        this.USACheckbox = usaRefCollection.data().announacmentStatus;
      }
    },
    async announcementBarUpadate() {
      this.loading = true;
      var message = "";
      var snackbarColor = "";
      const refCustomer = announcementBarCustomer;
      const refUSA = announcementBarUSA;
      const refTurkey = announcementBarTurkey;
      const date = new Date(Date.now());
      const dataCustomer = {
        announacmentMessage: this.CustomerMessage,
        announacmentStatus: this.CustomerCheckbox,
        uid: this.userId,
        displayName: this.adminDisplayName,
        date: date,
      };
      const dataUSA = {
        announacmentMessage: this.USAMessage,
        announacmentStatus: this.USACheckbox,
        uid: this.userId,
        displayName: this.adminDisplayName,
        date: date,
      };
      const dataTurkey = {
        announacmentMessage: this.TurkeyMessage,
        announacmentStatus: this.TurkeyCheckbox,
        uid: this.userId,
        displayName: this.adminDisplayName,
        date: date,
      };
      await refCustomer.update(dataCustomer);
      await refUSA.update(dataUSA);
      await refTurkey
        .update(dataTurkey)
        .then(function () {
          // Email sent.
          message = "Announacment bar updated";
          snackbarColor = "success";
        })
        .catch(function (error) {
          // An error happened.
          message = error.message;
          snackbarColor = "error";
        });
      await this.$emit("announacmentBarPopup", {
        snackbarText: message,
        snackbarInfo: true,
        snackbarColor: snackbarColor,
      });
      this.loading = false;
      this.dialog = false;
      return true;
    },
  },
  async created() {
    // Get user data
    const userAuth = auth;
    await userAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.adminDisplayName = user.displayName;
        this.userId = user.uid;
      }
    });
    this.getData();
  },
  beforeDestroy() {},
};
</script>

<style>
</style>