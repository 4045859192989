import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import { auth } from '@/firebase';
import PinchZoom from 'vue-pinch-zoom';
import Print from 'vue-print-nb'
import './registerServiceWorker'
import i18n from './i18n'
import store from './store'




// Global instruction 
Vue.component('pinch-zoom', PinchZoom);
Vue.use(Print);

Vue.use(VueMoment, {
  moment,
})

let app = '';

const userAuth = auth;
userAuth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});
