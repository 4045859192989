import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';
import "@firebase/storage";
import '@firebase/analytics';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


//**** main Firebase project **** ///
var firebaseConfig = {
  apiKey: "AIzaSyDOwI-ehuVLSK8cm3TdigO45ybvV-TQ6NI",
  authDomain: "tracking-management-syst-fb456.firebaseapp.com",
  databaseURL: "https://tracking-management-syst-fb456.firebaseio.com",
  projectId: "tracking-management-syst-fb456",
  storageBucket: "tracking-management-syst-fb456.appspot.com",
  messagingSenderId: "399016369980",
  appId: "1:399016369980:web:65a065c6a8fc90b6424839",
  measurementId: "G-VN0CK2P9WM"
};



// const firebaseConfig = {
//   apiKey: "AIzaSyAvL98E7C2Hhh3iZscEoumXr_3-Ug1PynE",
//   authDomain: "fire-func-gcs.firebaseapp.com",
//   databaseURL: "https://fire-func-gcs-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "fire-func-gcs",
//   storageBucket: "fire-func-gcs.appspot.com",
//   messagingSenderId: "755669678715",
//   appId: "1:755669678715:web:f0c67c35df686ac467c0de",
//   measurementId: "G-EJKF9KSQ24"
// };


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// firestore connection
const db = firebase.firestore();

// auth connection
const auth = firebase.auth();

// auth connection
const authUI = firebase.auth;

// functions connection
const functions = firebase.functions()

// storage connection
const storage = firebase.storage().ref();

// analytics connection
const analytics = firebase.analytics();

// Reference to the Set User Properties
const setUserProperties = analytics.setUserProperties({ store: "zubene" });


// Reference to the TRY rate collection
const rateRef = db.collection("exchangeRates").doc("try");

// Reference to the Announcement Bar for Customer
const announcementBarCustomer = db.collection("announcementBars").doc("customer");

// Reference to the Announcement Bar for usa
const announcementBarUSA = db.collection("announcementBars").doc("usa");

// Reference to the Announcement Bar for turkey
const announcementBarTurkey = db.collection("announcementBars").doc("turkey");

// Reference to the user collection
const userRef = db.collection("users");

// Reference to the all orders and order
const allOrders = db
  .collectionGroup("orders")
  .orderBy("submit_date", "asc");

// Reference to the all orders and order
const allOrdersUnordered = db
  .collectionGroup("orders")

// Reference to the set user role function
const setUserRole = functions.httpsCallable("setUserRole");

export {
  db,
  auth,
  authUI,
  functions,
  setUserProperties,
  rateRef,
  userRef,
  storage,
  allOrders,
  setUserRole,
  allOrdersUnordered,
  announcementBarCustomer,
  announcementBarUSA,
  announcementBarTurkey,
};